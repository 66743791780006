import { library } from '@fortawesome/fontawesome-svg-core';
import { faSave, faUser } from '@fortawesome/free-regular-svg-icons';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faBars,
  faChartPie,
  faCheck,
  faChevronDown,
  faChevronRight,
  faDownload,
  faFileCsv,
  faFlask,
  faReply,
  faSpinner,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';

export const allowedIcons = {
  user: ['far', faUser],
  save: ['far', faSave],
  reply: ['fas', faReply],
  download: ['fas', faDownload],
  spinner: ['fas', faSpinner],
  check: ['fas', faCheck],
  bars: ['fas', faBars],
  flask: ['fas', faFlask],
  'chart-pie': ['fas', faChartPie],
  'file-csv': ['fas', faFileCsv],
  'chevron-down': ['fas', faChevronDown],
  'chevron-right': ['fas', faChevronRight],
  'angle-double-left': ['fas', faAngleDoubleLeft],
  'angle-double-right': ['fas', faAngleDoubleRight],
  trash: ['fas', faTrash],
};

export const initIcons = () => {
  library.add(
    ...Object.keys(allowedIcons).map((key) => {
      return allowedIcons[key][1];
    })
  );
};

export function iconize(string = '') {
  let icon = allowedIcons[string];

  if (!icon) return null;

  return [icon[0], string];
}
