import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { withQueryProps } from '../../hocs/with-query-props';
import {
  Button,
  Col,
  Container as ReactStrapContainer,
  Input,
  Row,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { iconize } from '../../icon-util';
import { Icon } from '../../components/icon';
import { UniversePicker } from '../../components/universe-picker';
import { AssetPickerV2 } from '../../components/asset-picker-v2';
import { fetchExportESGLabRankingsData } from '../../api';
import { AccountContext } from '../../hocs/account-context';
import { currentDatetimeAsFilename } from '../../formatters';
import { saveAs } from 'file-saver';
import { PercentileSlider } from '../../components/percentile-slider';
import { BenchmarkPicker } from '../../components/benchmark-picker';

export const defaultProps = {
  universe: 'us',
  asset: 'IWB',
  selectedMetrics:
    'carbonAlphaESG,performanceESG,priceMoSectorLT,priceMoSectorST,priceMoSector,priceMoLT,priceMoST,pSoc,pGov,pCarbon,cMktCap,s3MktCap,priceMo,pDiv,percentESG,perfESG,pEnv',
  sliderOne: '50,100',
  sliderTwo: '0,50',
  benchmark: 'iwv',
};

const ContainerBase = ({
  selectedMetrics,
  universe,
  asset,
  sliderOne,
  sliderTwo,
  benchmark,
  changeQuery,
}) => {
  const { token } = useContext(AccountContext);
  const [isLoading, setIsLoading] = useState(false);

  selectedMetrics = selectedMetrics.split(',').filter((m) => !!m);
  const updateSelectedMetrics = (include, type) => {
    const metrics = selectedMetrics;
    if (include) {
      metrics.push(type);
    } else {
      const index = metrics.indexOf(type);
      if (index > -1) {
        metrics.splice(index, 1);
      }
    }
    changeQuery({ selectedMetrics: metrics.join(',') });
  };

  const onClick = async () => {
    setIsLoading(true);
    const res = await fetchExportESGLabRankingsData(
      {
        selectedMetrics,
        universe,
        sliderOne,
        sliderTwo,
        benchmark,
        asset,
      },
      token
    );
    const date = currentDatetimeAsFilename();
    if (res) {
      saveAs(res, `esg_lab_${asset}_ranking_and_metrics_${date}.csv`);
      setIsLoading(false);
    }
  };

  const min = (str) => str.split(',')[0];
  const max = (str) => str.split(',')[1];

  const formCheckClassName = 'form-check mb-1';
  const formDivClassName = 'form-group form-group--column my-3';
  return (
    <ReactStrapContainer fluid>
      <Row>
        <Col
          className="bg-light text-center"
          md={{
            offset: 3,
            size: 6,
          }}
          sm="12"
        >
          {isLoading ? (
            <div className="my-3">
              <FontAwesomeIcon icon={iconize('spinner')} pulse size="4x" />
            </div>
          ) : (
            <div className="mt-3">
              <h3>Select Universe and Metrics</h3>
              <p className="small">
                Each metrics will generate two filter variations in the universe
                selected. The output is a CSV file that includes all metrics and
                rankings.
              </p>
            </div>
          )}
          <hr className="col-md-8" />
        </Col>
      </Row>
      <Row>
        <Col
          className="bg-light text-center"
          md={{
            offset: 3,
            size: 6,
          }}
          sm="6"
        >
          <div className="mx-auto w-75">
            <label className="fw-bold small">Universe</label>
            <UniversePicker
              selected={universe}
              onChange={(e) => changeQuery({ universe: e.target.value })}
            />
          </div>

          <div className="mx-auto w-75 mt-2 mb-3">
            <label className="small">Asset</label>
            <AssetPickerV2
              target="etf-constituents"
              type="ETF"
              universe={universe}
              selected={asset}
              onSelect={(asset) => changeQuery({ asset: asset.id })}
            />
          </div>
          <div className="mx-auto w-75 mt-2 mb-3">
            <label className="small">Benchmark</label>
            <BenchmarkPicker
              selected={benchmark}
              onChange={(e) => changeQuery({ benchmark: e.target.value })}
            />
          </div>
          <div className="mx-auto mt-2 mb-3">
            <label className="small">Filter Variation One</label>
            <PercentileSlider
              min={min(sliderOne)}
              max={max(sliderOne)}
              onChange={(v) => {
                const [vMin, vMax] = v;
                changeQuery({ sliderOne: `${vMin},${vMax}` });
              }}
            />
          </div>
          <div className="mx-auto mt-2 mb-4">
            <label className="small">Filter Variation Two</label>
            <PercentileSlider
              min={min(sliderTwo)}
              max={max(sliderTwo)}
              onChange={(v) => {
                const [vMin, vMax] = v;
                changeQuery({ sliderTwo: `${vMin},${vMax}` });
              }}
            />
          </div>
        </Col>
      </Row>
      <Row className="small">
        <Col
          className="bg-light"
          md={{
            offset: 3,
            size: 3,
          }}
          sm="6"
        >
          <div className={formCheckClassName}>
            <Input
              type="checkbox"
              name="tickers"
              className="mt-2"
              checked={selectedMetrics.includes('pEnv')}
              onChange={(e) => updateSelectedMetrics(e.target.checked, 'pEnv')}
            />
            <label className="form-check-label">E Percentile</label>
          </div>
          <div className={formCheckClassName}>
            <Input
              type="checkbox"
              name="tickers"
              className="mt-2"
              checked={selectedMetrics.includes('pSoc')}
              onChange={(e) => updateSelectedMetrics(e.target.checked, 'pSoc')}
            />
            <label className="form-check-label">S Percentile</label>
          </div>
          <div className={formCheckClassName}>
            <Input
              type="checkbox"
              name="tickers"
              className="mt-2"
              checked={selectedMetrics.includes('pGov')}
              onChange={(e) => updateSelectedMetrics(e.target.checked, 'pGov')}
            />
            <label className="form-check-label">G Percentile</label>
          </div>
          <div className={formCheckClassName}>
            <Input
              type="checkbox"
              name="tickers"
              className="mt-2"
              checked={selectedMetrics.includes('pDiv')}
              onChange={(e) => updateSelectedMetrics(e.target.checked, 'pDiv')}
            />
            <label className="form-check-label">Diversity Percentile</label>
          </div>
          <div className={formCheckClassName}>
            <Input
              type="checkbox"
              name="tickers"
              className="mt-2"
              checked={selectedMetrics.includes('percentESG')}
              onChange={(e) =>
                updateSelectedMetrics(e.target.checked, 'percentESG')
              }
            />
            <label className="form-check-label">ESG Percentile</label>
          </div>
          <div className={formCheckClassName}>
            <Input
              type="checkbox"
              name="tickers"
              className="mt-2"
              checked={selectedMetrics.includes('perfESG')}
              onChange={(e) =>
                updateSelectedMetrics(e.target.checked, 'perfESG')
              }
            />
            <label className="form-check-label">ESG Signal 1 Filter</label>
          </div>
          <div className={formCheckClassName}>
            <Input
              type="checkbox"
              name="tickers"
              className="mt-2"
              checked={selectedMetrics.includes('performanceESG')}
              onChange={(e) =>
                updateSelectedMetrics(e.target.checked, 'performanceESG')
              }
            />
            <label className="form-check-label">ESG Signal 2 Filter</label>
          </div>
          <div className={formCheckClassName}>
            <Input
              type="checkbox"
              name="tickers"
              className="mt-2"
              checked={selectedMetrics.includes('carbonAlphaESG')}
              onChange={(e) =>
                updateSelectedMetrics(e.target.checked, 'carbonAlphaESG')
              }
            />
            <label className="form-check-label">Carbon Signal 3 Filter</label>
          </div>
        </Col>
        <Col
          className="bg-light"
          md={{
            size: 3,
          }}
          sm="6"
        >
          <div className={formCheckClassName}>
            <Input
              type="checkbox"
              name="tickers"
              className="mt-2"
              checked={selectedMetrics.includes('pCarbon')}
              onChange={(e) =>
                updateSelectedMetrics(e.target.checked, 'pCarbon')
              }
            />
            <label className="form-check-label">Percent Carbon</label>
          </div>
          <div className={formCheckClassName}>
            <Input
              type="checkbox"
              name="tickers"
              className="mt-2"
              checked={selectedMetrics.includes('cMktCap')}
              onChange={(e) =>
                updateSelectedMetrics(e.target.checked, 'cMktCap')
              }
            />
            <label className="form-check-label">
              Carbon Market Cap Percentile
            </label>
          </div>
          <div className={formCheckClassName}>
            <Input
              type="checkbox"
              name="tickers"
              className="mt-2"
              checked={selectedMetrics.includes('s3MktCap')}
              onChange={(e) =>
                updateSelectedMetrics(e.target.checked, 's3MktCap')
              }
            />
            <label className="form-check-label">% Scope 3 Market Cap</label>
          </div>
          <div className={formCheckClassName}>
            <Input
              type="checkbox"
              name="tickers"
              className="mt-2"
              checked={selectedMetrics.includes('priceMo')}
              onChange={(e) =>
                updateSelectedMetrics(e.target.checked, 'priceMo')
              }
            />
            <label className="form-check-label">Price Momentum</label>
          </div>
          <div className={formCheckClassName}>
            <Input
              type="checkbox"
              name="tickers"
              className="mt-2"
              checked={selectedMetrics.includes('priceMoST')}
              onChange={(e) =>
                updateSelectedMetrics(e.target.checked, 'priceMoST')
              }
            />
            <label className="form-check-label">Price Momentum ST</label>
          </div>
          <div className={formCheckClassName}>
            <Input
              type="checkbox"
              name="tickers"
              className="mt-2"
              checked={selectedMetrics.includes('priceMoLT')}
              onChange={(e) =>
                updateSelectedMetrics(e.target.checked, 'priceMoLT')
              }
            />
            <label className="form-check-label">Price Momentum LT</label>
          </div>
          <div className={formCheckClassName}>
            <Input
              type="checkbox"
              name="tickers"
              className="mt-2"
              checked={selectedMetrics.includes('priceMoSector')}
              onChange={(e) =>
                updateSelectedMetrics(e.target.checked, 'priceMoSector')
              }
            />
            <label className="form-check-label">Sector Price Momentum</label>
          </div>
          <div className={formCheckClassName}>
            <Input
              type="checkbox"
              name="tickers"
              className="mt-2"
              checked={selectedMetrics.includes('priceMoSectorST')}
              onChange={(e) =>
                updateSelectedMetrics(e.target.checked, 'priceMoSectorST')
              }
            />
            <label className="form-check-label">Sector Price Momentum ST</label>
          </div>
          <div className={formCheckClassName}>
            <Input
              type="checkbox"
              name="tickers"
              className="mt-2"
              checked={selectedMetrics.includes('priceMoSectorLT')}
              onChange={(e) =>
                updateSelectedMetrics(e.target.checked, 'priceMoSectorLT')
              }
            />
            <label className="form-check-label">Sector Price Momentum LT</label>
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          className="bg-light text-center"
          md={{
            offset: 3,
            size: 6,
          }}
          sm="6"
        >
          <div className={formDivClassName}>
            <Button
              onClick={onClick}
              color="success"
              className="rounded"
              disabled={isLoading || selectedMetrics.length === 0}
            >
              Generate&nbsp;&nbsp;
              <Icon name="file-csv" color="#FFFFFF" />
            </Button>
          </div>
        </Col>
      </Row>
    </ReactStrapContainer>
  );
};

ContainerBase.propTypes = {
  universe: PropTypes.string.isRequired,
  asset: PropTypes.string.isRequired,
  changeQuery: PropTypes.func.isRequired,
  selectedMetrics: PropTypes.string.isRequired,
  sliderOne: PropTypes.string.isRequired,
  sliderTwo: PropTypes.string.isRequired,
  benchmark: PropTypes.string.isRequired,
};

export const Container = withQueryProps(ContainerBase, defaultProps);
