import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  fetchESGLabsAnalysisData,
  fetchExportESGLabsAnalysisData,
  fetchMedian,
} from '../../api';
import { Col, Container as ReactStrapContainer, Row } from 'reactstrap';

import { AccountContext } from '../../hocs/account-context';
import { filterData } from '../../filter';
import { median as generateMedian } from '../../formatters';
import { saveAs } from 'file-saver';

import { Chart } from '../labs-constituents/chart';
import { Form } from './form';
import { setDynamicConstituentMinMax } from '../../../../server/metrics';
import { ETFConstituentsContext } from '../etf-constituents/container';
import { withQueryProps } from '../../hocs/with-query-props';

export const defaultProps = {
  universe: 'global',
  metric: 'Percentile ESG',
  yMetric: 'ESG Signal 2',
  colorMetric: 'Percentile ESG',
  colorBy: 'color_universe',
  medianMethod: 'filtered asset',
  sector: '',
  industry_group: '',
  industry: '',
  subindustry: '',
  leftAsset: 'etf|IVV',
  rightAsset: 'etf|SPYX',
  joinType: 'leftOnly',
  asOfDate: '',
};

export const ContainerBase = (props) => {
  const {
    universe,
    sector,
    industry,
    subindustry,
    industry_group,
    metric,
    yMetric,
    colorMetric,
    colorBy,
    medianMethod,
    leftAsset,
    rightAsset,
    asOfDate,
    joinType,
  } = props;
  let [chartData, setChartData] = useState({
    universe,
    metric,
    yMetric,
    data: undefined,
    rawData: undefined,
    colorMetric,
  });
  const { token } = useContext(AccountContext);
  const [isLoading, setIsLoading] = useState(true);
  const [chartTitle, setChartTitle] = useState('Analysis');
  const [median, setMedian] = useState({ x: 0.5, y: 0.5 });
  const [hasData, setHasData] = useState(false);
  const [classifications, setClassifications] = useState({
    sectors: [],
    industries: [],
    subindustries: [],
    industryGroups: [],
  });

  let xMin = 0;
  let xMax = 1;
  let yMin = 0;
  let yMax = 1;

  if (chartData.data && chartData.data.length > 0) {
    if (setDynamicConstituentMinMax.has(metric)) {
      xMin = Math.min(...chartData.data.map((p) => p.x).filter(Boolean));
      xMax = Math.max(...chartData.data.map((p) => p.x).filter(Boolean));
    } else {
      xMin = chartData.data[0].xmin;
      xMax = chartData.data[0].xmax;
    }

    if (setDynamicConstituentMinMax.has(yMetric)) {
      yMin = Math.min(...chartData.data.map((p) => p.y).filter(Boolean));
      yMax = Math.max(...chartData.data.map((p) => p.y).filter(Boolean));
    } else {
      yMin = chartData.data[0].ymin;
      yMax = chartData.data[0].ymax;
    }
  }

  const calcMedianAsset = useCallback(
    (filtered = false) => {
      const allMetricValues = chartData[filtered ? 'data' : 'rawData']
        .map((r) => r.x)
        .filter((r) => !isNaN(r))
        .sort((a, b) => a - b);

      const allAlphaValues = chartData[filtered ? 'data' : 'rawData']
        .map((r) => r.y)
        .filter((r) => !isNaN(r))
        .sort((a, b) => a - b);

      const metricMedian = generateMedian(allMetricValues);
      const alphaMedian = generateMedian(allAlphaValues);
      return { metricMedian, alphaMedian };
    },
    [chartData]
  );

  // Calculate / set median values
  useEffect(() => {
    switch (medianMethod) {
      case 'universe':
        fetchMedian({ universe, metric }, token).then((res) => setMedian(res));
        break;
      case 'asset': {
        if (!hasData) break;
        const { metricMedian, alphaMedian } = calcMedianAsset();
        setMedian({
          x: metricMedian,
          y: alphaMedian,
        });

        break;
      }
      case 'filtered asset': {
        if (!hasData) break;
        const { metricMedian, alphaMedian } = calcMedianAsset(true);
        setMedian({
          x: metricMedian,
          y: alphaMedian,
        });
        break;
      }
      case 'absolute': {
        if (!chartData.rawData) {
          setMedian({ x: 0.5, y: 0.5 });
          break;
        }
        setMedian({ x: (xMax + xMin) / 2, y: (yMax + yMin) / 2 });
        break;
      }
    }
  }, [
    medianMethod,
    yMetric,
    universe,
    metric,
    hasData,
    chartData.rawData,
    chartData.data,
  ]);

  const updateClassifications = (objs, sector, industry_group) => {
    let total = objs.length;
    const getUniqueItems = (arr, key) => {
      const valuesArray = arr.map((o) => o[key]);
      const uniqSorted = [...new Set(valuesArray)].sort();
      return uniqSorted.map((value) => {
        const t = valuesArray.filter((i) => i === value).length;
        // we don't need to show 100% of no values just display "No Sector" etc
        const title =
          uniqSorted.length === 1 &&
          value.match(new RegExp(`no ${key.replace('_', ' ')}`, 'i'))
            ? value
            : `${value} - ${t} -  ${((t / total) * 100).toFixed(2)}%`;
        return {
          [key]: {
            value,
            title,
          },
        };
      });
    };
    // Never filter out sectors
    const sectors = getUniqueItems(objs, 'sector');

    // Constrain all groups below if sector is selected
    if (sector) objs = objs.filter((p) => p.sector === sector);
    total = objs.length;
    const industryGroups = getUniqueItems(objs, 'industry_group');

    // Constrain all groups below if industry_group is selected
    if (industry_group)
      objs = objs.filter((p) => p.industry_group === industry_group);
    total = objs.length;
    const industries = getUniqueItems(objs, 'industry');
    const subindustries = getUniqueItems(objs, 'subindustry');

    setClassifications({
      sectors,
      industryGroups,
      industries,
      subindustries,
    });
  };

  const filterChartData = useCallback(
    (objs) => {
      if (objs) {
        updateClassifications(objs, sector, industry_group, industry);
        let data = filterData(objs, 0, 100);
        if (sector) data = data.filter((p) => p.sector === sector);
        if (industry_group)
          data = data.filter((p) => p.industry_group === industry_group);
        if (industry) data = data.filter((p) => p.industry === industry);
        if (subindustry)
          data = data.filter((p) => p.subindustry === subindustry);

        // Set Color Value
        data = data.map((d) => ({
          ...d,
          color: d[colorBy],
        }));

        return data;
      }
    },
    [industry_group, sector, industry, subindustry]
  );

  useEffect(() => {
    setIsLoading(true);
    let data = filterChartData(chartData.rawData);
    setChartData(Object.assign({}, chartData, { data }));
    setIsLoading(false);
  }, [filterChartData]);

  // Set Color Value
  useEffect(() => {
    if (chartData.data) {
      const data = chartData.data.map((d) => ({
        ...d,
        color: d[colorBy],
      }));
      setChartData({
        ...chartData,
        data,
      });
    }
  }, [colorBy]);

  const fetchData = async () => {
    setIsLoading(true);
    const rawData = await fetchESGLabsAnalysisData({ ...props }, token);
    const data = filterChartData(rawData);
    setChartData({
      universe,
      metric,
      yMetric,
      data,
      rawData,
      colorMetric,
    });
    setHasData(!!rawData.length);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [metric, yMetric, colorMetric, leftAsset, rightAsset, joinType, asOfDate]);

  const requestCSV = async () => {
    setIsLoading(true);
    const res = await fetchExportESGLabsAnalysisData({ ...props }, token);
    setIsLoading(false);
    saveAs(
      res,
      `${chartTitle.toLowerCase().replaceAll(' ', '_')}_${asOfDate.replaceAll(
        '/',
        '-'
      )}.csv`
    );
  };

  return (
    <ReactStrapContainer fluid>
      <ETFConstituentsContext.Provider value={classifications}>
        <Row>
          <Col xs="3">
            <Form
              {...props}
              isLoading={isLoading}
              totalConstituents={chartData.rawData?.length}
              setChartTitle={setChartTitle}
            />
          </Col>

          <Col xs={'8'}>
            <Chart
              requestCSV={requestCSV}
              data={chartData}
              median={median}
              medianMethod={medianMethod}
              xMinMax={[xMin, xMax]}
              yMinMax={[yMin, yMax]}
              chartTitle={chartTitle}
            />
          </Col>
        </Row>
      </ETFConstituentsContext.Provider>
    </ReactStrapContainer>
  );
};

ContainerBase.propTypes = {
  universe: PropTypes.string.isRequired,
  sector: PropTypes.string.isRequired,
  industry_group: PropTypes.string.isRequired,
  industry: PropTypes.string.isRequired,
  subindustry: PropTypes.string.isRequired,
  metric: PropTypes.string.isRequired,
  yMetric: PropTypes.string.isRequired,
  changeQuery: PropTypes.func.isRequired,
  colorMetric: PropTypes.any,
  colorBy: PropTypes.string,
  medianMethod: PropTypes.any,
  leftAsset: PropTypes.string.isRequired,
  rightAsset: PropTypes.string.isRequired,
  asOfDate: PropTypes.string.isRequired,
  joinType: PropTypes.string.isRequired,
};

export const Container = withQueryProps(ContainerBase, defaultProps);
