import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';

HighchartsMore(Highcharts);

export const BarChart = ({ metric, barChart, requestPDF }) => {
  const dataLabels = {
    enabled: true,
    allowOverlap: true,
    color: '#000',
    style: { fontWeight: 'bolder' },
    formatter: function () {
      return this.y + '%';
    },
  };

  let chartOptions = {
    title: {
      text: `Returns by ${metric}`,
    },
    chart: {
      type: 'column',
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          symbol: 'download',
          menuItems: [
            {
              text: 'Download PDF document',
              onclick: () => {
                requestPDF();
              },
            },
          ],
        },
      },
    },
    tooltip: {
      shared: true,
      crosshairs: true,
    },
    xAxis: {
      categories: ['1 Month', '3 Months', '6 Months', 'Year to Date', '1 Year'],
      labels: {
        align: 'bottom',
      },
      minorTickColor: '#ffffff',
      minorTickWidth: 0,
    },
    yAxis: {
      minorTickColor: '#ffffff',
      title: {
        text: '',
      },
    },
    series: [
      {
        name: `Top 25% (${barChart.assetCount[3]} assets)`,
        color: '#4BAE56',
        dataLabels,
        data: barChart.bars[3],
      },
      {
        name: `50-75% (${barChart.assetCount[2]} assets)`,
        color: '#B9CE4B',
        dataLabels,
        data: barChart.bars[2],
      },
      {
        name: `25-50% (${barChart.assetCount[1]} assets)`,
        color: '#F1A552',
        dataLabels,
        data: barChart.bars[1],
      },
      {
        name: `Bottom 25% (${barChart.assetCount[0]} assets)`,
        color: '#EB6969',
        dataLabels,
        data: barChart.bars[0],
      },
    ],
  };

  return (
    <div style={{ height: '500px' }}>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

BarChart.propTypes = {
  barChart: PropTypes.array.isRequired,
  metric: PropTypes.string.isRequired,
  requestPDF: PropTypes.func.isRequired,
};
