import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Loading } from '../../components/loading';

Highcharts.setOptions({
  colors: Highcharts.map(Highcharts.getOptions().colors, function (color) {
    return {
      radialGradient: {
        cx: 0.5,
        cy: 0.3,
        r: 0.7,
      },
      stops: [
        [0, color],
        [1, Highcharts.color(color).brighten(-0.3).get('rgb')], // darken
      ],
    };
  }),
});

export const PieChart = ({ data, chartTitle, yAxisTitle, yAxisKey }) => {
  const formatDataForChart = (objs) =>
    objs.map((point) => {
      const {
        sector,
        num_constituents: numOfConstituents,
        total_weight: totalWeight,
        total_weight_per_sector: totalWeightPerSector,
      } = point;
      return {
        name: sector,
        title: sector,
        numOfConstituents,
        sum: parseFloat(point[yAxisKey + '_sum']),
        nulls: parseFloat(point[yAxisKey + '_null_count']),
        y: parseFloat(point[yAxisKey + '_sum_percent']),
        totalWeight,
        totalWeightPerSector,
      };
    });

  if (data?.length === 0) {
    return <div className="field-required">No data available</div>;
  } else if (!data || !data?.length) {
    return (
      <div className="row h-100 justify-content-center align-items-center">
        <Loading />
      </div>
    );
  }

  let chartOptions = {
    title: {
      text: chartTitle,
    },
    chart: {
      type: 'pie',
      plotShadow: false,
      plotBackgroundColor: null,
      plotBorderWidth: null,
    },
    credits: {
      text: 'Confluence Analytics',
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
    tooltip: {
      shared: true,
      crosshairs: true,
      formatter: function () {
        const point = this.point;
        let tooltip = `
          ${point.title}
          <br />
          Percent ${yAxisTitle}: ${point.y}%
          <br />
          Total ${yAxisTitle}: ${point.sum}
        `; // prettier-ignore
        if (point.nulls > 0) {
          tooltip = tooltip + `<br />Missing Data for ${point.nulls} asset(s)`;
        }
        if (point.totalWeight && point.totalWeightPerSector) {
          tooltip =
            tooltip +
            `<br />
              Weights Sum; ${point.title} ${point.totalWeightPerSector}, Total ${point.totalWeight}`;
        }
        return tooltip;
      },
    },
    series: [
      {
        data: formatDataForChart(data),
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

PieChart.propTypes = {
  data: PropTypes.any,
  chartTitle: PropTypes.string.isRequired,
  yAxisTitle: PropTypes.string.isRequired,
  yAxisKey: PropTypes.string.isRequired,
};
