import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { HalfWidth } from '../chart';

const CompaniesRow = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  white-space: nowrap;
  height: 500px;
`;
const CompanyItem = styled.div`
  padding: 0.5rem 0;
  margin-right: 3rem;
`;
const ColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const KeyValueRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Key = styled.span`
  font-weight: bold;
`;
const Value = styled.span``;

const Company = ({ item, metric }) => {
  return (
    <CompanyItem>
      <KeyValueRow>
        <span>{item.company_name}</span>
        <span>({item.ticker})</span>
      </KeyValueRow>
      <KeyValueRow>
        <Key>{metric}:</Key> <Value>{getLocaleString(item.x)}</Value>
      </KeyValueRow>
      <KeyValueRow>
        <Key>Market Cap:</Key> <Value>{getLocaleString(item.market_cap)}</Value>
      </KeyValueRow>
    </CompanyItem>
  );
};

const getLocaleString = (x) => {
  x = +x;
  if (x > 1000) x = Math.floor(x);
  return x.toLocaleString();
};

export const Companies = ({ companies, metric }) => {
  return (
    <HalfWidth>
      <ColumnContainer>
        <HalfWidth>
          <h3>Top Constituents</h3>
          <CompaniesRow>
            {companies[0].map((item) => (
              <Company metric={metric} item={item} />
            ))}
          </CompaniesRow>
        </HalfWidth>
        <HalfWidth>
          <h3>Bottom Constituents</h3>
          <CompaniesRow>
            {companies[1].map((item) => (
              <Company metric={metric} item={item} />
            ))}
          </CompaniesRow>
        </HalfWidth>
      </ColumnContainer>
    </HalfWidth>
  );
};

Companies.propTypes = {
  companies: PropTypes.array.isRequired,
  metric: PropTypes.string.isRequired,
};
