import React from 'react';
import PropTypes from 'prop-types';

export const TypePicker = ({ selected, onChange }) => {
  return (
    <select
      className="form-control form-control-sm"
      value={selected}
      onChange={onChange}
    >
      <option value="stock">Stock</option>
      <option value="etf">ETF</option>
    </select>
  );
};

TypePicker.propTypes = {
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
