import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Row } from 'reactstrap';
import { Icon } from '../../../components/icon';

export const Accounts = ({ accounts }) => {
  return (
    <div className="container mt-5">
      <div className="row d-flex flex-row-reverse">
        <div className="p-2">
          <Link to="/settings/account/create/">
            <Button>Create account</Button>
          </Link>
        </div>
      </div>
      <Row className="mb-3">
        <Table striped>
          <thead>
            <th>Admin</th>
            <th>Email</th>
            <th>Permissions</th>
            <th></th>
          </thead>
          <tbody>
            {accounts.map(a => {
              return (
                <tr key={a.accountId}>
                  <td>
                    {a.permissions.includes('administrator') ? (
                      <Icon name="check" color="#00457f" />
                    ) : null}
                  </td>
                  <td>{a.email}</td>
                  <td>{a.permissions.sort().join(', ')}</td>
                  <Link to={`/settings/account/edit/${a.accountId}`}>
                    <Button>Edit</Button>
                  </Link>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Row>
    </div>
  );
};
