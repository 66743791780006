import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Collapse,
  Container as ReactStrapContainer,
  Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { fetchESGLabsData, fetchExportESGLabData } from '../../api';
import { AccountContext } from '../../hocs/account-context';

import { withQueryProps } from '../../hocs/with-query-props';

import { Chart } from './chart';
import { Form } from './form';
import { Sidebar } from '../labs-plus/constituents-sidebar';
import { currentDatetimeAsFilename } from '../../formatters';
import { saveAs } from 'file-saver';
import { MiniChart } from './mini-chart';
import { Icon } from '../../components/icon';

export const defaultProps = {
  universe: 'us_all',
  useEtf: 'false',
  asset: '',
  sectorCore: '',
  pEnv: '0,100',
  pSoc: '0,100',
  pGov: '0,100',
  pDiv: '0,100',
  percentESG: '0,100',
  perfESG: '0,100',
  performanceESG: '0,100',
  carbonAlphaESG: '0,100',
  pCarbon: '0,100',
  cMktCap: '0,100',
  s3MktCap: '0,100',
  priceMo: '0,100',
  priceMoST: '0,100',
  priceMoLT: '0,100',
  priceMoSector: '0,100',
  priceMoSectorST: '0,100',
  priceMoSectorLT: '0,100',
  runDateStart: '2019-07-31',
  c1Metric: 'Percentile E',
  c2Metric: 'Percentile S',
  c3Metric: 'Percentile G',
  c4Metric: 'Percentile Carbon',
  onlyIncludeTickers: 'false',
  includeTickers: '',
  excludeTickers: '',
  excludeSectors: '',
  selectedMetrics: '',
  combinedMetrics: '0,100',
};

export const ContainerBase = (props) => {
  let {
    universe,
    useEtf,
    asset,
    sectorCore,
    runDateStart,
    c1Metric,
    c2Metric,
    c3Metric,
    c4Metric,
    onlyIncludeTickers,
    includeTickers,
    excludeTickers,
    excludeSectors,
  } = props;
  const [data = { universe, sectorCore }, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isConstituentsOpen, setIsConstituentsOpen] = useState(false);
  const toggleSidebar = () => setIsConstituentsOpen(!isConstituentsOpen);
  const { token } = useContext(AccountContext);

  const fetchData = async () => {
    setIsLoading(true);
    const filterDataParams = {
      ...props,
      type: 'returns',
      logData: true,
    };
    const getFilteredData = fetchESGLabsData(filterDataParams, token);

    const benchmarkDataParams = {
      ...defaultProps,
      universe,
      useEtf,
      asset,
      sectorCore,
      runDateStart,
      c1Metric,
      c2Metric,
      c3Metric,
      c4Metric,
      type: 'returns',
    };
    const getBenchmarkData = fetchESGLabsData(benchmarkDataParams, token);

    const [{ filteredData }, { benchmarkData }] = await Promise.all([
      getFilteredData,
      getBenchmarkData,
    ]);

    setData({ filteredData, benchmarkData, universe, sectorCore });
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [
    c1Metric,
    c2Metric,
    c3Metric,
    c4Metric,
    onlyIncludeTickers,
    includeTickers,
    excludeTickers,
    excludeSectors,
  ]);

  const requestCSV = async () => {
    setIsLoading(true);
    const res = await fetchExportESGLabData(props, 'raw', token);
    const date = currentDatetimeAsFilename();
    saveAs(res, `esg_lab_${date}.csv`);
    setIsLoading(false);
  };

  return (
    <ReactStrapContainer fluid>
      <Row>
        <Col xs="3">
          <Form
            {...props}
            onClick={fetchData}
            isLoading={isLoading}
            hideMiniChartsForm={false}
          />
        </Col>
        <Col xs={isConstituentsOpen ? '6' : '8'}>
          <Row className="mb-1">
            <Chart data={data} requestCSV={requestCSV} />
          </Row>
          <Row>
            <Col>
              <MiniChart
                data={data}
                chartTitle={c1Metric}
                yAxisTitle={c1Metric}
                yAxisKey={'mini_chart_1'}
              />
            </Col>
            <Col>
              <MiniChart
                data={data}
                chartTitle={c2Metric}
                yAxisTitle={c2Metric}
                yAxisKey={'mini_chart_2'}
              />
            </Col>
            <Col>
              <MiniChart
                data={data}
                chartTitle={c3Metric}
                yAxisTitle={c3Metric}
                yAxisKey={'mini_chart_3'}
              />
            </Col>
            <Col>
              <MiniChart
                data={data}
                chartTitle={c4Metric}
                yAxisTitle={c4Metric}
                yAxisKey={'mini_chart_4'}
              />
            </Col>
          </Row>
        </Col>
        <Col
          xs={isConstituentsOpen ? '3' : '1'}
          style={{ backgroundColor: '#f7f7f7' }}
        >
          <div className="d-flex justify-content-around mt-2">
            <Button
              className="rounded text-center"
              color="info"
              onClick={toggleSidebar}
              style={{ fontSize: '.80rem' }}
            >
              <Icon
                name={
                  isConstituentsOpen
                    ? 'angle-double-right'
                    : 'angle-double-left'
                }
              />{' '}
              &nbsp; &nbsp;
              {isConstituentsOpen ? 'Hide' : 'View'} Constituents
            </Button>
          </div>
          {isConstituentsOpen && (
            <Collapse isOpen={isConstituentsOpen}>
              <Sidebar
                {...props}
                onClick={fetchData}
                isLoading={isLoading}
                isConstituentsOpen={isConstituentsOpen}
              />
            </Collapse>
          )}
        </Col>
      </Row>
    </ReactStrapContainer>
  );
};

ContainerBase.propTypes = {
  universe: PropTypes.string.isRequired,
  sectorCore: PropTypes.string,
  changeQuery: PropTypes.func.isRequired,
  runDateStart: PropTypes.string,
  c1Metric: PropTypes.string,
  c2Metric: PropTypes.string,
  c3Metric: PropTypes.string,
  c4Metric: PropTypes.string,
  onlyIncludeTickers: PropTypes.string,
  includeTickers: PropTypes.string,
  excludeTickers: PropTypes.string,
  excludeSectors: PropTypes.string,
};

export const Container = withQueryProps(ContainerBase, defaultProps);
