import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsTreemap from 'highcharts/modules/treemap';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import { constants } from '../../highcharts';
import { getGradientPosition } from '../../formatters';

HighchartsTreemap(Highcharts);
HighchartsHeatmap(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);

function getAlphaColor(quadrant, percent, min, max) {
  const quadrantColor = [
    '',
    ['#e0c37c', '#cea42c'], // q1 - leaders
    ['#95accf', '#7893bb'], // q2 - maybe
    ['#bfbfbf', '#dddddd'], // q3 - laggard
    ['#c9d3e4', '#b7c4dc'], // q4 - maybe -
  ];

  if (percent === undefined && quadrantColor[quadrant])
    return quadrantColor[quadrant][0];

  // Get gradient
  if (min === undefined) min = -1;
  if (max === undefined) max = 1;

  return getGradientPosition(
    quadrantColor[quadrant || 0][0],
    quadrantColor[quadrant || 0][1],
    Math.abs(percent)
  );
}

function quadrants(parent, children) {
  let width = parent.width / 2;
  let height = parent.height / 2;

  let areas = [
    {
      x: parent.x + width,
      y: parent.y,
      width,
      height,
    },
    {
      x: parent.x,
      y: parent.y,
      width,
      height,
    },
    {
      x: parent.x,
      y: parent.y + height,
      width,
      height,
    },
    {
      x: parent.x + width,
      y: parent.y + height,
      width,
      height,
    },
  ];
  for (let i = 0; i < children.length; i++) {
    areas.push();
  }
  return areas;
}

// @ts-ignore
Highcharts.seriesTypes.treemap.prototype.quadrants = quadrants;

export class Chart extends React.PureComponent {
  render() {
    let { data, changeQuery, requestCSV } = this.props;
    let universe = data.universe;
    let asset = data.asset;
    let metric = data.metric;
    let yMetric = data.yMetric;
    let quadrant = data.quadrant;
    let fundName = data.fundName;

    if (!data.data || !data.rawData || !data.data.length) {
      return <div className="field-required">No data available</div>;
    }

    // Custom CSV download function
    Highcharts.getOptions().exporting.menuItemDefinitions.downloadCSV = {
      textKey: 'downloadCSV',
      onclick: requestCSV,
    };

    let chartOptions;
    let hasQuadrant = quadrant && quadrant > 0;

    if (!hasQuadrant) {
      let points = [
        {
          id: 'q1',
          name: 'Leaders',
          value: 1,
          quadrant: 1,
          color: getAlphaColor(1),
        },
        {
          id: 'q2',
          name: 'Prospects',
          value: 1,
          quadrant: 2,
          color: getAlphaColor(2),
        },
        {
          id: 'q3',
          name: 'Laggards',
          value: 1,
          quadrant: 3,
          color: getAlphaColor(3),
        },
        {
          id: 'q4',
          name: 'Concerns',
          value: 1,
          quadrant: 4,
          color: getAlphaColor(4),
        },
      ];

      let totalCompanies = data.rawData.length;
      let activeCompanies = data.data.length;
      let activeWeight = 0;
      let qCompanies = [0, 0, 0, 0, 0];
      let qWeight = [0, 0, 0, 0, 0];
      for (let datum of data.data) {
        points.push({
          id: datum.id,
          name: datum.id,
          value: datum.z,
          color: getAlphaColor(datum.q, datum.y),
          raw: datum,
          parent: `q${datum.q}`,
          quadrant: null,
        });
        activeWeight += datum.z;
        qCompanies[datum.q] += 1;
        qWeight[datum.q] += datum.z;
      }

      chartOptions = {
        chart: {
          marginTop: 80,
          events: {
            click: function (e) {
              let x = e.xAxis[1].value;
              let y = e.yAxis[1].value;
              if (x >= 50 && y >= 50) return changeQuery({ quadrant: 1 });
              if (x >= 50 && y < 50) return changeQuery({ quadrant: 4 });
              if ((x < 50) & (y >= 50)) return changeQuery({ quadrant: 2 });
              if ((x < 50) & (y < 50)) return changeQuery({ quadrant: 3 });
            },
          },
        },
        title: {
          text: `${fundName} (${universe.toUpperCase()}) Constituent Quadrants for ${metric}`,
        },
        subtitle: {
          text: `${activeCompanies} of ${totalCompanies} companies (${activeWeight.toFixed(1)}% of total weight)`, // prettier-ignore
          y: 50,
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        exporting: {
          buttons: {
            contextButton: {
              symbol: 'download',
              menuItems: [
                // 'printChart',
                // 'viewFullscreen'
                // 'separator',
                'downloadPNG',
                // 'downloadJPEG',
                'downloadPDF',
                // 'downloadSVG',
                'separator',
                'downloadCSV',
                // 'downloadXLS',
              ],
            },
          },
          chartOptions: {
            plotOptions: {
              treemap: {
                dataLabels: {
                  style: {
                    fontSize: '0.5rem',
                    fontWeight: 'normal',
                  },
                },
              },
            },
            legend: {
              itemStyle: {
                color: constants.text_color,
                fontSize: '0.5rem',
                fontWeight: 'normal',
                fontFamily: constants.font_family,
              },
            },
            chart: {
              backgroundColor: constants.background_color,
              marginTop: 70,
            },
            title: {
              style: {
                color: constants.text_color,
                fontWeight: 'bold',
                fontSize: '1rem',
              },
            },
            subtitle: {
              style: {
                color: constants.text_color,
                fontWeight: 'bold',
                fontSize: '0.75rem',
              },
            },
            xAxis: {
              labels: {
                style: {
                  color: constants.text_color,
                  fontSize: '0.5rem',
                  fontFamily: constants.font_family,
                },
              },
              title: {
                style: {
                  color: constants.text_color,
                  fontWeight: 'lighter',
                  fontSize: '0.5rem',
                  fontFamily: constants.font_family,
                },
              },
            },
            yAxis: {
              labels: {
                style: {
                  color: constants.text_color,
                  fontSize: '0.5rem',
                  fontFamily: constants.font_family,
                },
              },
              title: {
                style: {
                  color: constants.text_color,
                  fontWeight: 'lighter',
                  fontSize: '0.5rem',
                  fontFamily: constants.font_family,
                },
              },
            },
          },
        },
        xAxis: [
          {
            title: {
              text: metric,
            },
            showEmpty: true,
            minorTicks: false,
            tickInterval: 0.5,
            min: 0,
            max: 1,
          },
          {},
        ],
        yAxis: [
          {
            title: {
              text: yMetric,
            },
            showEmpty: true,
            alignTicks: false,
            minorTicks: false,
            tickInterval: 1,
            min: -1,
            max: 1,
            labels: {
              formatter: function () {
                if (this.isFirst) return '-';
                if (this.isLast) return '+';
                return this.value;
              },
            },
          },
          {},
        ],
        series: [
          {
            type: 'treemap',
            allowTraversingTree: false,
            enableMouseTracking: false,
            animation: {
              duration: 0,
            },
            dataLabels: {
              enabled: false,
            },
            levelIsConstant: true,
            turboThreshold: 0,
            levels: [
              {
                level: 1,
                layoutAlgorithm: 'quadrants',
                dataLabels: {
                  enabled: true,
                  style: {
                    cursor: 'pointer',
                    textAlign: 'center',
                  },
                  useHTML: true,
                  formatter: function () {
                    let q = this.point.quadrant;
                    let name = this.point.name;
                    let shadowColor = q === 2 ? '#000' : '#FFF';
                    return `<div style="text-shadow: -1px 0 ${shadowColor}, 0 1px ${shadowColor}, 1px 0 ${shadowColor}, 0 -1px ${shadowColor}">
                      <div style="font-size: 18px; margin-bottom: 4px;">${name}</div>
                      <div style="font-size: 12px">${qCompanies[q]} companies (${qWeight[q].toFixed(1)}% of total weight)</div>
                    </div>`; // prettier-ignore
                  },
                },
              },
              {
                level: 2,
                layoutAlgorithm: 'squarified',
                borderWidth: 0.5,
                dataLabels: {
                  enabled: false,
                },
              },
            ],
            data: points,
            xAxis: 1,
            yAxis: 1,
          },
          {
            type: 'scatter',
          },
        ],
      };
    }

    if (hasQuadrant) {
      let points = [];

      let totalCompanies = data.rawData.length;
      let activeCompanies = 0;
      let activeWeight = 0;

      for (let datum of data.data.filter((p) => p.q == quadrant)) {
        points.push({
          id: datum.id,
          name: datum.id,
          value: datum.z,
          color: getAlphaColor(datum.q, datum.y),
          raw: datum,
          parent: `q${datum.q}`,
        });
        activeCompanies += 1;
        activeWeight += datum.z;
      }

      chartOptions = {
        chart: {
          marginTop: 80,
          events: {
            click: function () {
              changeQuery({ quadrant: 0 });
            },
          },
        },
        title: {
          text: `${fundName} (${universe.toUpperCase()}) Constituent Quadrants for ${metric}`,
        },
        subtitle: {
          text: `${activeCompanies} of ${totalCompanies} companies (${activeWeight.toFixed(1)}% of total weight)`, // prettier-ignore
          y: 50,
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        colors: constants.indicator_colors,
        exporting: {
          enabled: true,
          chartOptions: {
            plotOptions: {
              treemap: {
                dataLabels: {
                  style: {
                    fontSize: '0.4rem',
                    fontWeight: 'normal',
                  },
                },
              },
            },
            legend: {
              itemStyle: {
                color: constants.text_color,
                fontSize: '0.5rem',
                fontWeight: 'normal',
                fontFamily: constants.font_family,
              },
            },
            chart: {
              backgroundColor: constants.background_color,
              marginTop: 70,
            },
            title: {
              style: {
                color: constants.text_color,
                fontWeight: 'bold',
                fontSize: '1rem',
              },
            },
            subtitle: {
              style: {
                color: constants.text_color,
                fontWeight: 'bold',
                fontSize: '0.75rem',
              },
            },
          },
          buttons: {
            contextButton: {
              symbol: 'download',
              menuItems: [
                // 'printChart',
                // 'viewFullscreen'
                // 'separator',
                'downloadPNG',
                // 'downloadJPEG',
                'downloadPDF',
                // 'downloadSVG',
                'separator',
                'downloadCSV',
                // 'downloadXLS',
              ],
            },
          },
        },
        tooltip: {
          useHTML: true,
          followPointer: true,
          formatter: function () {
            let point = this.point;
            return `
              <div style="padding-bottom: 5px; width: 175px; white-space: normal">
                <span style="font-size: 1.1em; font-weight: bold;">${point.raw.name}</span>
                <span style="font-weight: normal; font-style: italic;">${point.id}</span>
              </div>
              <table>
                <tr>
                  <th style="padding-right: 1.5rem;">Weight:</th>
                  <td style="text-align: right">${(point.raw.z).toFixed(2)}%</td>
                </tr>
                <tr>
                  <th style="padding-right: 1.5rem;">${metric}:</th>
                  <td style="text-align: right">${point.raw.x.toFixed(3)}</td>
                </tr>
                <tr>
                  <th style="padding-right: 1.5rem;">Alpha:</th>
                  <td style="text-align: right">${point.raw.y.toFixed(3)}</td>
                </tr>
              </table>`; // prettier-ignore
          },
        },
        series: [
          {
            type: 'treemap',
            layoutAlgorithm: 'squarified',
            borderWidth: 0.5,
            animation: {
              duration: 0,
            },
            dataLabels: {
              enabled: true,
            },
            turboThreshold: 0,
            data: points,
          },
        ],
      };
    }

    return (
      <HighchartsReact
        highcharts={Highcharts}
        // @ts-ignore
        options={chartOptions}
        immutable={true}
      />
    );
  }
}

Chart.propTypes = {
  data: PropTypes.object,
  rawData: PropTypes.object,
  changeQuery: PropTypes.func.isRequired,
};
