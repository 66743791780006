import React, { useState, useEffect, useContext } from 'react';
import { fetchGlobalSettings, postUpdateGlobalSettings } from '../../../api';
import { Administration } from './administration';
import { AccountContext } from '../../../hocs/account-context';

export const AdministrationContainer = () => {
  const [settings, setSettings] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [err, setErr] = useState(null);
  const [msg, setMsg] = useState(null);
  const { token } = useContext(AccountContext);

  useEffect(() => {
    fetchGlobalSettings(token).then(res => {
      if (res.success) setSettings(res.settings);
      setLoading(false);
    });
  }, []);

  const onSubmit = e => {
    e.preventDefault();

    setLoading(true);
    setErr(null);
    setMsg(null);

    postUpdateGlobalSettings({ settings }, token).then(res => {
      setLoading(false);
      if (res.success) {
        setMsg('Successfully updated global settings');
        setErr(null);
      } else {
        setErr('Unable to save settings');
        setMsg(null);
      }
    });
  };

  const onUpdateSetting = e => {
    const { name, value } = e.target;
    const newSettings = settings.map(s => {
      if (s.key === name) s.value = value;
      return s;
    });

    setSettings(newSettings);
  };

  return (
    <Administration
      settings={settings}
      onSubmit={onSubmit}
      onUpdateSetting={onUpdateSetting}
      isLoading={isLoading}
      error={err}
      message={msg}
    />
  );
};
