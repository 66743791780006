import React, { useState, useContext } from 'react';
import { CreateAccount } from './create-account';
import { AccountContext } from '../../../hocs/account-context';
import { postCreateAccount } from '../../../api';
import { useHistory } from 'react-router-dom';

export const CreateAccountContainer = () => {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [permissions, setPermissions] = useState([]);
  const { token } = useContext(AccountContext);
  const history = useHistory();

  const handleChangeEmail = e => {
    const newEmail = e.target.value;
    setEmail(newEmail);
  };

  const handleChangePassword = e => {
    const newPassword = e.target.value;
    setPassword(newPassword);
  };

  const handleChangePermissions = e => {
    const selected = e.target.value;
    const idx = permissions.indexOf(selected);
    if (idx !== -1) {
      setPermissions([
        ...permissions.slice(0, idx),
        ...permissions.slice(idx + 1, permissions.length),
      ]);
    } else {
      setPermissions([...permissions, selected]);
    }
  };

  const handleCreateAccount = () => {
    postCreateAccount({ email, password, permissions }, token).then(res => {
      if (!res.success) setError(res.error);
      else history.push('/settings/accounts');
    });
  };

  return (
    <CreateAccount
      error={error}
      email={email}
      password={password}
      permissions={permissions}
      handleChangePermissions={handleChangePermissions}
      handleChangeEmail={handleChangeEmail}
      handleChangePassword={handleChangePassword}
      handleCreateAccount={handleCreateAccount}
    />
  );
};
