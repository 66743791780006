import { Button, Col, Collapse, Input, Row } from 'reactstrap';
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  deleteBookmark,
  fetchBookmarks,
  fetchBookmarkSettings,
  postCreateBookmark,
  postUpdateBookmark,
} from '../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { iconize } from '../../icon-util';
import { Combobox } from '../../components/combobox';
import { AccountContext } from '../../hocs/account-context';
import { dateToStringInESTWithDay } from '../../formatters';

function LoadingArea(props) {
  return null;
}

LoadingArea.propTypes = { children: PropTypes.node };
export const Bookmarks = ({
  isOpen,
  changeQuery,
  generate,
  settings,
  token,
}) => {
  const { account } = useContext(AccountContext);
  const isAdmin = account && account.permissions.includes('administrator');
  const [loading, setLoading] = useState(false);
  const [loadAndUpdate, setLoadAndUpdate] = useState(false);
  const [selected, setSelected] = useState({ name: '' });
  const [bookmarksArray, setBookmarksArray] = useState([]);
  const [filterBookmarksArray, setFilteredBookmarksArray] = useState([]);
  const [name, setName] = useState('');
  const formDivClassName = 'form-group form-group--column mb-3';

  const saveNewBookmark = async () => {
    setLoading(true);
    const res = await postCreateBookmark(settings, name, token);

    if (res.success) {
      setName('Successfully created');
      generate();
      setLoading(false);
    }
  };

  const loadBookmark = async () => {
    setLoading(true);

    const res = await fetchBookmarkSettings(selected.id, token);
    const {
      success,
      data: { settings },
    } = res;

    if (success && settings) {
      changeQuery(settings);
      generate();
      setLoading(false);
    }
  };

  const onClickUpdateBookmark = async () => {
    setLoading(true);

    const res = await postUpdateBookmark(settings, selected.id, token);

    if (res.success) {
      setLoading(false);
    }
  };

  const loadBookmarks = async () => {
    const { success, data } = await fetchBookmarks(token);
    if (success) {
      setBookmarksArray(data || []);
      setFilteredBookmarksArray(data || []);
    }
  };

  const onClickDeleteBookmark = async () => {
    setLoading(true);
    const { success } = await deleteBookmark(selected.id, token);

    if (success) {
      await loadBookmarks();
      setSelected({ name: '' });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!loadAndUpdate && isOpen) loadBookmarks();
  }, [loadAndUpdate, isOpen]);

  const onTextChange = (inputFilterText) => {
    if (bookmarksArray.length > 0) {
      const reg = new RegExp(inputFilterText.trim(), 'ig');
      const filtered = bookmarksArray.filter(({ name }) => name.match(reg));
      setFilteredBookmarksArray(
        filtered.length === 0
          ? [
              {
                id: 'unselectable',
                name: 'No Values',
              },
            ]
          : filtered
      );
    }
  };

  return (
    <Collapse isOpen={isOpen}>
      <div className={'form-check'}>
        <Input
          type="checkbox"
          name="tickers"
          className="mt-2"
          checked={loadAndUpdate}
          onChange={(e) => setLoadAndUpdate(e.target.checked)}
        />
        <label className="form-check-label">Create New Bookmark</label>
      </div>
      <div className={formDivClassName}>
        {loading && (
          <FontAwesomeIcon icon={iconize('spinner')} pulse size="2x" />
        )}
      </div>
      {loadAndUpdate && (
        <>
          <div className={formDivClassName}>
            <Input
              type="text"
              className="form-control form-control-sm"
              name="bookmark name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              placeholder="New Bookmark Name"
            />
          </div>
          <div className={formDivClassName}>
            <Button
              onClick={saveNewBookmark}
              disabled={
                name.length < 6 || loading || name === 'Successfully created'
              }
              color="primary"
              className="rounded"
              size="sm"
            >
              {name === 'Successfully created' ? 'Success' : 'Save Bookmark'}
            </Button>
          </div>
        </>
      )}
      {!loadAndUpdate && (
        <>
          <div className={formDivClassName}>
            <label className="form-check-label">
              Select Bookmark to Load or Update
            </label>
            <Combobox
              selected={selected.name}
              data={filterBookmarksArray}
              valueRenderer={({ name, created }) =>
                `${name} (${dateToStringInESTWithDay(created)})`
              }
              onTextChange={onTextChange}
              onSelect={(bookmark) => {
                setSelected(bookmark);
              }}
            />
          </div>
          <Row>
            <Col className="text-center">
              <Button
                onClick={loadBookmark}
                disabled={!selected.id && !selected.name}
                color="primary"
                className="rounded"
                size="sm"
              >
                Load
              </Button>
            </Col>
            {isAdmin && (
              <>
                <Col className="text-center">
                  <Button
                    onClick={onClickUpdateBookmark}
                    disabled={!selected.id && !selected.name}
                    color="warning"
                    className="rounded"
                    size="sm"
                  >
                    Update
                  </Button>
                </Col>
                <Col className="text-center">
                  <Button
                    onClick={onClickDeleteBookmark}
                    disabled={!selected.id && !selected.name}
                    color="danger"
                    className="rounded"
                    size="sm"
                  >
                    Delete
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </>
      )}
    </Collapse>
  );
};

Bookmarks.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  changeQuery: PropTypes.func.isRequired,
  generate: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
  token: PropTypes.any.isRequired,
};
