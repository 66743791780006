import React from 'react';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Alert,
  Card,
  CardBody,
  Row,
  Col,
  Container,
} from 'reactstrap';
import { PermissionsPicker } from '../../../components/permissions-picker';

export const CreateAccount = ({
  error,
  email,
  password,
  permissions,
  handleChangePermissions,
  handleChangeEmail,
  handleChangePassword,
  handleCreateAccount,
}) => {
  return (
    <Container className="mt-3">
      {error && <Alert color="danger">{error}</Alert>}
      <Form>
        <Row>
          <Col md="12">
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                id="email"
                type="email"
                value={email}
                onChange={handleChangeEmail}
              ></Input>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <FormGroup>
              <Label for="password">Password</Label>
              <Input
                id="password"
                type="password"
                value={password}
                onChange={handleChangePassword}
              ></Input>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <PermissionsPicker
              selectedPermissions={permissions}
              handleChangeSelectedPermissions={handleChangePermissions}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button color="primary" onClick={handleCreateAccount}>
              Create
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};
