/**
 * Parses a query string and returns a object of key value pairs
 * For example "foo=bar&derp=taco%20taco" return { foo: 'bar, derp: 'taco taco' }
 * @param {string} query
 * @return {object}
 */
export function decodeQueryString(query) {
  let results = {};
  let parts = query.substr(1).split('&');
  for (let part of parts) {
    let kvp = part.split('=');
    results[decodeURIComponent(kvp[0])] = decodeURIComponent(kvp[1]);
  }
  return results;
}

/**
 * Accepts an object and returns a query string generated from the object.
 * @param {object} obj
 * @returns {string}
 */
export function encodeQueryString(obj) {
  let parts = [];
  for (let key in obj) {
    parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
  }
  return parts.join('&');
}
