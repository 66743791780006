import React from 'react';
import PropTypes from 'prop-types';
import { Loading } from '../../components/loading';
import { Table } from 'reactstrap';

export const MetricsTable = ({ data }) => {
  const { tableData } = data;
  if (!tableData) {
    return (
      <div className="mt-5 w-100 justify-content-center align-items-center">
        <Loading />
      </div>
    );
  }

  const { benchmarkMetrics, monthlyMetrics } = tableData;
  if (!monthlyMetrics) {
    return <div className="field-required">No data available</div>;
  }

  return (
    <Table hover className="ml-5">
      <thead>
        <tr>
          <th>Metric</th>
          <th>Monthly Rebalance</th>
          {/*<th>Quarterly</th>*/}
          <th>Benchmark</th>
        </tr>
      </thead>
      <tbody>
        <tr
          className={
            monthlyMetrics.portfolio1mReturn >
            benchmarkMetrics.benchmark1mReturn
              ? 'table-success'
              : ''
          }
        >
          <th scope="row">1 Month Percent Return</th>
          <td>{monthlyMetrics.portfolio1mReturn.toFixed(2)} %</td>
          {/*<td>{quarterlyMetrics.portfolioTotalReturn.toFixed(2)} %</td>*/}
          <td>{benchmarkMetrics.benchmark1mReturn.toFixed(2)} %</td>
        </tr>
        <tr
          className={
            monthlyMetrics.portfolio3mReturn >
            benchmarkMetrics.benchmark3mReturn
              ? 'table-success'
              : ''
          }
        >
          <th scope="row">3 Month Percent Return</th>
          <td>{monthlyMetrics.portfolio3mReturn.toFixed(2)} %</td>
          {/*<td>{quarterlyMetrics.portfolioTotalReturn.toFixed(2)} %</td>*/}
          <td>{benchmarkMetrics.benchmark3mReturn.toFixed(2)} %</td>
        </tr>
        <tr
          className={
            monthlyMetrics.portfolio6mReturn >
            benchmarkMetrics.benchmark6mReturn
              ? 'table-success'
              : ''
          }
        >
          <th scope="row">6 Month Percent Return</th>
          <td>{monthlyMetrics.portfolio6mReturn.toFixed(2)} %</td>
          {/*<td>{quarterlyMetrics.portfolioTotalReturn.toFixed(2)} %</td>*/}
          <td>{benchmarkMetrics.benchmark6mReturn.toFixed(2)} %</td>
        </tr>
        <tr
          className={
            monthlyMetrics.portfolio12mReturn >
            benchmarkMetrics.benchmark12mReturn
              ? 'table-success'
              : ''
          }
        >
          <th scope="row">1 Year Percent Return</th>
          <td>{monthlyMetrics.portfolio12mReturn.toFixed(2)} %</td>
          {/*<td>{quarterlyMetrics.portfolioTotalReturn.toFixed(2)} %</td>*/}
          <td>{benchmarkMetrics.benchmark12mReturn.toFixed(2)} %</td>
        </tr>
        <tr
          className={
            monthlyMetrics.portfolioTotalReturn >
            benchmarkMetrics.benchmarkTotalReturn
              ? 'table-success'
              : ''
          }
        >
          <th scope="row">Since Inception Percent Return</th>
          <td>{monthlyMetrics.portfolioTotalReturn.toFixed(2)} %</td>
          {/*<td>{quarterlyMetrics.portfolioTotalReturn.toFixed(2)} %</td>*/}
          <td>{benchmarkMetrics.benchmarkTotalReturn.toFixed(2)} %</td>
        </tr>
        <tr>
          <th scope="row">Average # Constituents</th>
          <td>{monthlyMetrics.averageNumberOfConstituents}</td>
          {/*<td>{quarterlyMetrics.averageNumberOfConstituents}</td>*/}
          <td></td>
        </tr>
        <tr>
          <th scope="row">Standard Deviation</th>
          <td>{monthlyMetrics?.standardDeviation?.toFixed(5)}</td>
          {/*<td>{quarterlyMetrics?.standardDeviation?.toFixed(5)}</td>*/}
          <td>{benchmarkMetrics?.standardDeviation?.toFixed(5)}</td>
          <td></td>
        </tr>
        <tr>
          <th scope="row">Correlation</th>
          <td>{monthlyMetrics?.correlation?.toFixed(5)}</td>
          {/*<td>{quarterlyMetrics?.correlation?.toFixed(5)}</td>*/}
          <td></td>
        </tr>
        <tr>
          <th scope="row">Information Ratio</th>
          <td>{monthlyMetrics?.informationRatio?.toFixed(5)}</td>
          {/*<td>{quarterlyMetrics?.informationRatio?.toFixed(5)}</td>*/}
          <td></td>
        </tr>
        <tr>
          <th scope="row">Sharpe Ratio (1y)</th>
          <td>{monthlyMetrics?.sharpeRatio?.toFixed(5)}</td>
          {/*<td>{quarterlyMetrics?.sharpeRatio?.toFixed(5)}</td>*/}
          <td></td>
        </tr>
        <tr>
          <th scope="row">Beta Since Inception</th>
          <td>{monthlyMetrics?.betaSinceInception?.toFixed(5)}</td>
          {/*<td>{quarterlyMetrics?.betaSinceInception?.toFixed(5)}</td>*/}
          <td></td>
        </tr>
        <tr>
          <th scope="row">Beta (1y)</th>
          <td>{monthlyMetrics?.betaOneYear?.toFixed(5)}</td>
          {/*<td>{quarterlyMetrics?.betaOneYear?.toFixed(5)}</td>*/}
          <td></td>
        </tr>
        <tr>
          <th scope="row">Alpha Since Inception</th>
          <td>{monthlyMetrics?.alphaSinceInception?.toFixed(5)}</td>
          {/*<td>{quarterlyMetrics?.alphaSinceInception?.toFixed(5)}</td>*/}
          <td></td>
        </tr>
        <tr>
          <th scope="row">Treynor Ratio</th>
          <td>{monthlyMetrics?.treynorRatio?.toFixed(5)}</td>
          {/*<td>{quarterlyMetrics?.treynorRatio?.toFixed(5)}</td>*/}
          <td></td>
        </tr>
        <tr>
          <th scope="row">Sortino Ratio (1y, Monthly)</th>
          <td>{monthlyMetrics?.sortinoRatioOneYearMonthly?.toFixed(5)}</td>
          {/*<td>{quarterlyMetrics?.sortinoRatioOneYearMonthly?.toFixed(5)}</td>*/}
          <td></td>
        </tr>
      </tbody>
    </Table>
  );
};

MetricsTable.propTypes = {
  data: PropTypes.object,
};
