import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { UniversePicker } from '../../components/universe-picker';
import { ConstituentMetricPicker } from '../../components/constituent-metric-picker';
import { ConstituentColorMetricPicker } from '../../components/constituent-color-metric-picker';
import { SizeSlider } from '../../components/size-slider';
import { ETFConstituentsContext } from './container';
import { ConstituentClassificationsPicker } from '../../components/constituent-classifications-picker';
import { AssetPickerV2 } from '../../components/asset-picker-v2';
import { DatePickerAssetV2 } from '../../components/date-picker-asset-v2';
import { Toolbar } from '../../components/toolbar';
import { MedianPickerV2 } from '../../components/median-picker-v2';
import { useLocation } from 'react-router';
import { CustomPortfolioPathname } from '../../layout';
import { ConstituentColorByPicker } from '../../components/constituent-color-by-picker';
import { Link } from 'react-router-dom';

export const Form = ({
  universe,
  assetDate,
  sector,
  industry_group,
  industry,
  subindustry,
  asset,
  metric,
  yMetric,
  colorMetric,
  colorBy,
  min,
  max,
  changeQuery,
  medianMethod,
  isLoading,
  totalConstituents,
}) => {
  const { sectors, industries, subindustries, industryGroups } = useContext(
    ETFConstituentsContext
  );
  const { pathname } = useLocation();
  const [dates, setDates] = useState([]);
  const useCustom = pathname === CustomPortfolioPathname;

  return (
    <Toolbar isLoading={isLoading && !!dates.length}>
      <div className="form-group mr-2">
        <label>X Metric</label>
        <ConstituentMetricPicker
          metric={metric}
          onChange={(e) => changeQuery({ metric: e.target.value })}
        />
      </div>
      <div className="form-group mr-2">
        <label>Y Metric</label>
        <ConstituentMetricPicker
          metric={yMetric}
          onChange={(e) => changeQuery({ yMetric: e.target.value })}
        />
      </div>
      <div className="form-group mr-2">
        <label>Color Metric</label>
        <ConstituentColorMetricPicker
          metric={colorMetric}
          onChange={(e) => changeQuery({ colorMetric: e.target.value })}
        />
      </div>
      <div className="form-group mr-2">
        <label>Color By</label>
        <ConstituentColorByPicker
          metric={colorBy}
          onChange={(e) => changeQuery({ colorBy: e.target.value })}
        />
      </div>
      <div className="form-group mr-2">
        <label>Median</label>
        <MedianPickerV2
          median={medianMethod}
          onChange={(e) => changeQuery({ medianMethod: e.target.value })}
        />
      </div>
      <div className="form-group mr-2">
        <label>Weight</label>
        <SizeSlider
          min={min}
          max={max}
          onChange={(v) => {
            let [v0, v1] = v;
            if (min == v0 && max == v1) return;
            if (max == v1) changeQuery({ min: v0 });
            if (min == v0) changeQuery({ max: v1 });
          }}
        />
      </div>
      <div className="form-group mr-2">
        <label>Universe</label>
        <UniversePicker
          selected={universe}
          onChange={(e) => changeQuery({ universe: e.target.value })}
        />
      </div>
      {!useCustom && (
        <div className="form-group mr-2">
          <label>Asset</label>
          <AssetPickerV2
            target="etf-constituents"
            type="ETF"
            universe={universe}
            selected={asset}
            onSelect={(asset) => changeQuery({ asset: asset.id })}
          />
        </div>
      )}
      <div className="form-group mr-2">
        <label>Run Date</label>
        <DatePickerAssetV2
          type="etf-constituents"
          asset={asset}
          universe={universe}
          date={assetDate}
          assetType="etf-constituents"
          setParentDates={setDates}
          onChange={(e) => changeQuery({ assetDate: e.target.value })}
        />
      </div>
      <div className="form-group mr-2">
        <label>
          Sector
          {totalConstituents
            ? ` - Total Constituents: ${totalConstituents}`
            : ''}
        </label>
        <ConstituentClassificationsPicker
          universe={universe}
          keyName={'sector'}
          classification={sector}
          classifications={sectors}
          defaultClassifications={[]}
          onChange={(e) => changeQuery({ sector: e.target.value })}
        />
      </div>
      <div className="form-group mr-2">
        <label>Industry Group</label>
        <ConstituentClassificationsPicker
          universe={universe}
          keyName={'industry_group'}
          classification={industry_group}
          classifications={industryGroups}
          defaultClassifications={[]}
          onChange={(e) => changeQuery({ industry_group: e.target.value })}
        />
      </div>
      <div className="form-group mr-2">
        <label>Industry</label>
        <ConstituentClassificationsPicker
          universe={universe}
          keyName={'industry'}
          classification={industry}
          classifications={industries}
          defaultClassifications={[]}
          onChange={(e) => changeQuery({ industry: e.target.value })}
        />
      </div>
      <div className="form-group mr-2">
        <label>Subindustry</label>
        <ConstituentClassificationsPicker
          universe={universe}
          keyName={'subindustry'}
          classification={subindustry}
          classifications={subindustries}
          defaultClassifications={[]}
          onChange={(e) => changeQuery({ subindustry: e.target.value })}
        />
      </div>
      <div className="form-group mr-2">
        <label>Link to Climate Profile</label>
        <Link
          to={`/charts/etf/carbon-profile?universe=${universe}&asset=${asset}&startDate=${
            dates.length
              ? new Date(dates[dates.length - 1]['date'])
                  .toISOString()
                  .split('T')[0]
              : '2018-01-31'
          }`}
          className="form-control-sm"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p>{asset}'s climate profile</p>
        </Link>
      </div>
    </Toolbar>
  );
};

Form.propTypes = {
  universe: PropTypes.string.isRequired,
  sector: PropTypes.string.isRequired,
  asset: PropTypes.string.isRequired,
  metric: PropTypes.string.isRequired,
  yMetric: PropTypes.string.isRequired,
  min: PropTypes.string.isRequired,
  max: PropTypes.string.isRequired,
  changeQuery: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  sectors: PropTypes.any,
  assetDate: PropTypes.any,
  industry: PropTypes.any,
  subindustry: PropTypes.any,
  industry_group: PropTypes.any,
  colorMetric: PropTypes.any,
  colorBy: PropTypes.string,
  medianMethod: PropTypes.any,
  totalConstituents: PropTypes.number,
};
