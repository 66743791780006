import Highcharts from 'highcharts';

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});

export const constants = {
  title_color: '#111111C0',
  text_color: '#363636C0',
  line_color: '#1a1a1a',
  background_color: '#ffffff',
  font_family: '"proxima-nova", Verdana, sans-serif',

  indicator_colors: [
    '#2E5894',
    '#C5B358',
    '#78184A',
    '#50C878',
    '#918FDF',
    '#30DD2F',
    '#5685D3',
    '#D2F2D9',
    '#80DEF7',
    '#A47627',
  ],

  chart: {
    title: {
      text: ' ',
    },
    chart: {
      zoomType: 'x',
    },
    series: [
      {
        name: 'Series 1',
        type: 'line',
        data: [1, 2, 3],
      },
    ],
    xAxis: {
      type: 'datetime',
    },
  },
};

const theme = {
  colors: constants.indicator_colors,
  chart: {
    backgroundColor: 'transparent',
    marginTop: 50,
  },
  title: {
    style: {
      color: constants.title_color,
      fontWeight: 'bold',
      fontSize: '1.5rem',
      fontFamily: constants.font_family,
    },
  },
  subtitle: {
    style: {
      color: constants.text_color,
      fontWeight: 'bold',
      fontSize: '1rem',
      fontFamily: constants.font_family,
    },
  },
  legend: {
    itemStyle: {
      color: constants.text_color,
      fontSize: '1rem',
      fontWeight: 'normal',
      fontFamily: constants.font_family,
    },
    itemHoverStyle: {
      color: constants.text_color,
    },
  },
  xAxis: {
    labels: {
      style: {
        color: constants.text_color,
        fontSize: '0.9rem',
        fontFamily: constants.font_family,
      },
    },
    title: {
      style: {
        color: constants.text_color,
        fontWeight: 'bold',
        fontSize: '1rem',
        fontFamily: constants.font_family,
      },
    },
  },
  yAxis: {
    labels: {
      style: {
        color: constants.text_color,
        fontSize: '0.9rem',
        fontFamily: constants.font_family,
      },
    },
    title: {
      style: {
        color: constants.text_color,
        fontWeight: 'bold',
        fontSize: '1rem',
        fontFamily: constants.font_family,
      },
    },
  },
};

Highcharts.setOptions(theme);

Highcharts.SVGRenderer.prototype.symbols.download = function(x, y, w, h) {
  // prettier-ignore
  var path = [
    // Arrow stem
    'M', x + w * 0.5, y,
    'L', x + w * 0.5, y + h * 0.7,
    // Arrow head
    'M', x + w * 0.3, y + h * 0.5,
    'L', x + w * 0.5, y + h * 0.7,
    'L', x + w * 0.7, y + h * 0.5,
    // Box
    'M', x, y + h * 0.9,
    'L', x, y + h,
    'L', x + w, y + h,
    'L', x + w, y + h * 0.9
  ];
  return path;
};
