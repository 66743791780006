import React, { useState, useEffect, useContext } from 'react';
import { AccountContext } from '../../../hocs/account-context';
import { fetchAllAccounts } from '../../../api';
import { Accounts } from './accounts';

export const AccountsContainer = () => {
  const [accounts, setAccounts] = useState([]);
  const { token } = useContext(AccountContext);

  // Get all accounts
  useEffect(() => {
    fetchAllAccounts(token).then(res => {
      if (res.success) setAccounts(res.accounts);
    });
  }, []);

  return <Accounts accounts={accounts} />;
};
