import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { fetchSectors } from '../api';
import { AccountContext } from '../hocs/account-context';

export const SectorPicker = props => {
  const { universe, sector, onChange } = props;
  const [sectors, setSectors] = useState([]);
  const { token } = useContext(AccountContext);

  useEffect(() => {
    fetchSectors(props, token).then(res => setSectors(res));
  }, [universe]);

  return (
    <select
      className="form-control form-control-sm"
      value={sector}
      onChange={onChange}
    >
      {sectors.map(p => (
        <option key={p.sector} value={p.sector}>
          {p.sector}
        </option>
      ))}
    </select>
  );
};

SectorPicker.propTypes = {
  universe: PropTypes.string.isRequired,
  sector: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
