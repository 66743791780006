import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import { Loading } from '../../components/loading';
import { dateToStringInEST } from '../../formatters';

HighchartsMore(Highcharts);

export class Chart extends React.PureComponent {
  render() {
    let {
      data,
      median,
      medianMethod,
      xMinMax,
      yMinMax,
      chartTitle,
      requestCSV,
    } = this.props;
    let { metric, yMetric, colorMetric } = data;

    if (data.data?.length === 0 && data.rawData?.length === 0) {
      return <div className="field-required">No data available</div>;
    } else if (!data.data || !data.rawData || !data.data.length) {
      return (
        <div className="row h-100 justify-content-center align-items-center">
          <Loading />
        </div>
      );
    }

    if (requestCSV) {
      Highcharts.getOptions().exporting.menuItemDefinitions.downloadCSV = {
        textKey: 'downloadCSV',
        onclick: requestCSV,
      };
    }

    let zMin = data.rawData[0].z;
    let zMax = data.rawData[data.rawData.length - 1].z;

    const { publish_date } = data.rawData[0];

    let chartOptions = {
      title: {
        text: publish_date
          ? `${chartTitle} - ${dateToStringInEST(publish_date)}`
          : chartTitle,
      },
      chart: {
        type: 'bubble',
        zoomType: 'xy',
        panning: {
          enabled: true,
          type: 'xy',
        },
        panKey: 'shift',
      },
      legend: {
        enabled: false,
      },
      credits: {
        text: 'Confluence Analytics',
      },
      exporting: {
        enabled: !!requestCSV,
        buttons: {
          contextButton: {
            symbol: 'download',
            menuItems: ['downloadCSV'],
          },
        },
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: '{point.id}',
          },
        },
      },
      xAxis: {
        title: {
          text: metric,
        },
        plotLines: [
          {
            label: {
              text: medianMethod === 'absolute' ? '' : `${medianMethod} median`,
            },
            color: 'black',
            dashStyle: 'dash',
            width: 1,
            value: median.x,
            zIndex: 1,
          },
        ],
        gridLineWidth: 1,
        min: xMinMax[0],
        max: xMinMax[1],
      },
      yAxis: {
        title: {
          text: yMetric,
        },
        plotLines: [
          {
            label: {
              text: medianMethod === 'absolute' ? '' : `${medianMethod} median`,
            },
            color: 'black',
            dashStyle: 'dash',
            width: 1,
            value: median.y,
            zIndex: 1,
          },
        ],
        min: yMinMax[0],
        max: yMinMax[1],
      },
      tooltip: {
        useHTML: true,
        followPointer: true,
        formatter: function () {
          let point = this.point;
          return `
            <div style="padding-bottom: 5px; width: 175px; white-space: normal">
              <span style="font-size: 1.1em; font-weight: bold;">${point.name}</span>
              <span style="font-weight: normal; font-style: italic;">${point.id}</span>
            </div>
            <table>
              <tr>
                <th style="padding-right: 1.5rem;">Weight:</th>
                <td style="text-align: right">${(point.z * 100).toFixed(2)}%</td>
              </tr>
              <tr>
                <th style="padding-right: 1.5rem;">${metric}:</th>
                <td style="text-align: right">${point.x.toFixed(3)}</td>
              </tr>
              <tr>
                <th style="padding-right: 1.5rem;">${colorMetric}:</th>
                <td style="text-align: right">${point.c.toFixed(3)}</td>
              </tr>
              <tr>
                <th style="padding-right: 1.5rem;">${yMetric}:</th>
                <td style="text-align: right">${point.y.toFixed(3)}</td>
              </tr>
            </table>`; // prettier-ignore
        },
      },
      series: [
        {
          animation: {
            duration: 0,
          },
          data: data.data,
          sizeBy: 'area',
          sizeByAbsoluteValue: true,
          zMin,
          zMax,
        },
      ],
    };

    return (
      <HighchartsReact
        containerProps={{ style: { height: '100%' } }}
        highcharts={Highcharts}
        options={chartOptions}
        immutable={true}
      />
    );
  }
}

Chart.propTypes = {
  data: PropTypes.object,
  median: PropTypes.any,
  medianMethod: PropTypes.any,
  xMinMax: PropTypes.any,
  yMinMax: PropTypes.any,
  chartTitle: PropTypes.string,
  requestCSV: PropTypes.func,
};
