import React, { useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { withQueryProps } from '../../hocs/with-query-props';
import {
  ContainerBase as Labs,
  defaultProps as labsDefaultProps,
} from '../labs-esg-core/container';
import {
  ContainerBase as Constituents,
  defaultProps as constituentsProps,
} from '../labs-constituents/container';
import { ContainerBase as CarbonProfile } from '../labs-carbon-profile/container';
import { ContainerBase as Metrics } from '../labs-metrics/container';

const defaultProps = {
  ...labsDefaultProps,
  ...constituentsProps,
};

export const ContainerBase = (props) => {
  const [tab, setTab] = useState('1');
  return (
    <>
      <Nav tabs>
        {[
          'Create Portfolio',
          'Risk/Return Metrics',
          'Inspect Constituents',
          'Emissions Profile',
        ].map((title, i) => {
          return (
            <NavItem key={i}>
              <NavLink
                className={classnames({ active: tab === i.toString() })}
                onClick={() => {
                  setTab(i.toString());
                }}
              >
                {title}
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>
      <TabContent activeTab={tab}>
        <TabPane tabId="0">{tab === '0' && <Labs {...props} />}</TabPane>
        <TabPane tabId="1">{tab === '1' && <Metrics {...props} />}</TabPane>
        <TabPane tabId="2">
          {tab === '2' && <Constituents {...props} />}
        </TabPane>
        <TabPane tabId="3">
          {tab === '3' && <CarbonProfile {...props} />}
        </TabPane>
      </TabContent>
    </>
  );
};

export const Container = withQueryProps(ContainerBase, defaultProps);
