import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Collapse,
  Container as ReactStrapContainer,
  Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { fetchESGLabsData, fetchExportESGLabData } from '../../api';
import { AccountContext } from '../../hocs/account-context';
import { saveAs } from 'file-saver';
import { withQueryProps } from '../../hocs/with-query-props';
import { Form } from '../labs-esg-core/form';
import { Sidebar } from '../labs-plus/constituents-sidebar';
import { Icon } from '../../components/icon';
import { defaultProps } from '../labs-esg-core/container';
import { Chart } from './chart';
import { MetricsTable } from './table';
import { currentDatetimeAsFilename } from '../../formatters';

export const ContainerBase = (props) => {
  let {
    universe,
    sectorCore,
    runDateStart,
    onlyIncludeTickers,
    includeTickers,
    excludeTickers,
    excludeSectors,
  } = props;
  const [data = { universe, sectorCore }, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isConstituentsOpen, setIsConstituentsOpen] = useState(false);
  const toggleSidebar = () => setIsConstituentsOpen(!isConstituentsOpen);
  const { token } = useContext(AccountContext);

  const fetchData = async () => {
    setIsLoading(true);
    setData({ universe, sectorCore });
    const metricsTableParams = {
      ...props,
      type: 'metrics-table',
      logData: true,
    };
    const getMetricsData = await fetchESGLabsData(metricsTableParams, token);

    const { monthlyPercentReturn, benchmarkPercentReturn, tableData } =
      getMetricsData;

    setData({
      monthlyPercentReturn,
      benchmarkPercentReturn,
      universe,
      tableData,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [onlyIncludeTickers, includeTickers, excludeTickers, excludeSectors]);

  const requestCSV = async () => {
    setIsLoading(true);
    const res = await fetchExportESGLabData(props, 'metrics', token);
    const date = currentDatetimeAsFilename();
    saveAs(res, `esg_lab_metrics_${date}.csv`);
    setIsLoading(false);
  };

  const requestIndexOneCSV = async () => {
    setIsLoading(true);
    const res = await fetchExportESGLabData(props, 'indexOne', token);
    const date = currentDatetimeAsFilename();
    saveAs(res, `esg_lab_index_one_${date}.csv`);
    setIsLoading(false);
  };

  return (
    <ReactStrapContainer fluid>
      <Row>
        <Col xs="3">
          <Form
            {...props}
            onClick={fetchData}
            isLoading={isLoading}
            hideMiniChartsForm={true}
          />
        </Col>
        <Col xs={isConstituentsOpen ? '6' : '8'}>
          <Row className="mb-1">
            <Chart
              data={data}
              requestCSV={requestCSV}
              requestIndexOneCSV={requestIndexOneCSV}
            />
          </Row>
          <Row>
            <MetricsTable data={data} />
          </Row>
        </Col>
        <Col
          xs={isConstituentsOpen ? '3' : '1'}
          style={{ backgroundColor: '#f7f7f7' }}
        >
          <div className="d-flex justify-content-around mt-2">
            <Button
              className="rounded text-center"
              color="info"
              onClick={toggleSidebar}
              style={{ fontSize: '.80rem' }}
            >
              <Icon
                name={
                  isConstituentsOpen
                    ? 'angle-double-right'
                    : 'angle-double-left'
                }
              />{' '}
              &nbsp; &nbsp;
              {isConstituentsOpen ? 'Hide' : 'View'} Constituents
            </Button>
          </div>
          {isConstituentsOpen && (
            <Collapse isOpen={isConstituentsOpen}>
              <Sidebar
                {...props}
                onClick={fetchData}
                isLoading={isLoading}
                isConstituentsOpen={isConstituentsOpen}
              />
            </Collapse>
          )}
        </Col>
      </Row>
    </ReactStrapContainer>
  );
};

ContainerBase.propTypes = {
  universe: PropTypes.string.isRequired,
  sectorCore: PropTypes.string,
  changeQuery: PropTypes.func.isRequired,
  runDateStart: PropTypes.string,
  onlyIncludeTickers: PropTypes.string,
  includeTickers: PropTypes.string,
  excludeTickers: PropTypes.string,
  excludeSectors: PropTypes.string,
};

export const Container = withQueryProps(ContainerBase, defaultProps);
