import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import {
  fetchConstituentData,
  fetchExportConstituentData,
  fetchMedian,
} from '../../api';

import { AccountContext } from '../../hocs/account-context';
import { withQueryProps } from '../../hocs/with-query-props';
import { filterData } from '../../filter';
import {
  currentDatetimeAsFilename,
  median as generateMedian,
} from '../../formatters';

import { Chart } from './chart';
import { Form } from './form';

const defaultProps = {
  universe: 'us',
  asset: 'VOO',
  metric: 'Percentile ESG',
  yMetric: 'ESG Signal 2',
  medianMethod: 'universe',
  min: '0',
  max: '100',
  quadrant: '0',
  sector: '',
};

function getQuadrant(alpha, metric, median) {
  if (alpha >= median.y && metric >= median.x) return 1;
  if (alpha >= median.y && metric < median.x) return 2;
  if (alpha < median.y && metric < median.x) return 3;
  if (alpha < median.y && metric >= median.x) return 4;
}

const ContainerBase = (props) => {
  const { token } = useContext(AccountContext);
  let {
    universe,
    sector,
    asset,
    metric,
    yMetric,
    min,
    max,
    quadrant,
    medianMethod,
  } = props;
  let [chartData, setChartData] = useState({
    quadrant,
    universe,
    asset,
    metric,
    data: undefined,
    rawData: undefined,
    fundName: ''
  }); // prettier-ignore
  const [median, setMedian] = useState({ x: 0.5, y: 0.5 });
  const [hasData, setHasData] = useState(false);
  // This is only used to recalculate the medians after the data changes
  const [lastLoadedDataSet, setLastLoadedDataSet] = useState('');

  const calculateMedian = () => {
    switch (medianMethod) {
      case 'universe':
        fetchMedian({ universe, metric, yMetric }, token).then((res) =>
          setMedian(res)
        );
        break;
      case 'asset': {
        if (!hasData) break;
        const allMetricValues = chartData.rawData
          .map((r) => r.x) // Get all x values as an array
          .filter((r) => !isNaN(r)) // Filter NaNs
          .sort((a, b) => a - b); // Sort
        const allAlphaValues = chartData.rawData
          .map((r) => r.y) // Get all y values as an array
          .filter((r) => !isNaN(r)) // Filter NaNs
          .sort((a, b) => a - b); // Sort

        const metricMedian = generateMedian(allMetricValues);
        const alphaMedian = generateMedian(allAlphaValues);

        setMedian({
          x: metricMedian,
          y: alphaMedian,
        });
        break;
      }
      case 'absolute':
        setMedian({ x: 0.5, y: 0.5 });
    }
  };

  useEffect(() => {
    calculateMedian();
  }, [
    universe,
    metric,
    yMetric,
    asset,
    medianMethod,
    hasData,
    lastLoadedDataSet,
  ]);

  // data fetch on option change
  useEffect(() => {
    fetchConstituentData(props, token).then((objs) => {
      let rawData = [];
      for (let { id, name, x, y, z, sector } of objs) {
        if (!x || !y || !z) continue;
        x = parseFloat(x);
        y = parseFloat(y);
        z = parseFloat(z) * 100;
        let q = getQuadrant(y, x, median);
        rawData.push({
          id,
          name,
          x,
          y,
          z,
          q,
          sector,
        });
      }

      let data = filterData(rawData, min, max);
      if (sector) data = data.filter((p) => p.sector === sector);
      data.sort((a, b) => {
        if (a.z > b.z) return 1;
        if (a.z < b.z) return -1;
        return 0;
      });

      let fundName = objs.length ? objs[0].fund : '';

      setChartData({
        universe,
        asset,
        metric,
        yMetric,
        data,
        rawData,
        quadrant,
        fundName,
      });
      setHasData(!!rawData.length);
      setLastLoadedDataSet(asset + metric + yMetric);
    });
  }, [universe, asset, metric, yMetric, median.x, median.y]);

  // filter if min/max change
  useEffect(() => {
    let data = filterData(chartData.rawData, min, max);
    if (sector) data = data.filter((p) => p.sector === sector);
    setChartData(Object.assign({}, chartData, { data }));
  }, [min, max, sector]);

  // change quadrant
  useEffect(() => {
    setChartData(Object.assign({}, chartData, { quadrant }));
  }, [quadrant]);

  const requestCSV = () => {
    fetchExportConstituentData(
      {
        universe,
        asset,
        metric,
        yMetric,
        min,
        max,
        sector,
        alphaMedian: median.y,
        metricMedian: median.x,
        quadrant,
        colorMetric: null,
        format: 'csv',
      },
      token
    ).then((res) =>
      saveAs(res, `etf_constituent_quadrant_${currentDatetimeAsFilename()}.csv`)
    );
  };

  return (
    <React.Fragment>
      <Form {...props} />
      <Chart
        data={chartData}
        changeQuery={props.changeQuery}
        requestCSV={requestCSV}
        median={median}
      />
    </React.Fragment>
  );
};

ContainerBase.propTypes = {
  universe: PropTypes.string.isRequired,
  sector: PropTypes.string.isRequired,
  asset: PropTypes.string.isRequired,
  metric: PropTypes.string.isRequired,
  yMetric: PropTypes.string.isRequired,
  min: PropTypes.string.isRequired,
  max: PropTypes.string.isRequired,
  quadrant: PropTypes.string,
  changeQuery: PropTypes.func.isRequired,
};

export const Container = withQueryProps(ContainerBase, defaultProps);
