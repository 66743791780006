import moment from 'moment';

/**
 * Formats the number into billions
 * @param {number} num
 */
export function toBillions(num) {
  return (num / 1e3).toFixed(2) + ' B';
}

/**
 * Gets the current datetime as a string for file export naming
 * @returns {string}
 */
export function currentDatetimeAsFilename() {
  return moment().format('YYYY-MM-DD_HHmmss');
}

/**
 * Get a color based off of percent position in a gradient
 * @param {string} startColor Starting color of the gradient
 * @param {string} endColor Ending color of the gradient
 * @param {number} percent 0 to 1 where in the gradient to select
 * @returns {string} hex code
 */
export function getGradientPosition(startColor, endColor, percent) {
  if (percent >= 1) return endColor;
  if (percent <= 0) return startColor;

  // strip the leading # if it's there
  startColor = startColor.replace(/^\s*#|\s*$/g, '');
  endColor = endColor.replace(/^\s*#|\s*$/g, '');

  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if (startColor.length == 3) startColor = startColor.replace(/(.)/g, '$1$1');

  if (endColor.length == 3) endColor = endColor.replace(/(.)/g, '$1$1');

  // get colors
  const startRed = parseInt(startColor.substr(0, 2), 16);
  const startGreen = parseInt(startColor.substr(2, 2), 16);
  const startBlue = parseInt(startColor.substr(4, 2), 16);

  const endRed = parseInt(endColor.substr(0, 2), 16);
  const endGreen = parseInt(endColor.substr(2, 2), 16);
  const endBlue = parseInt(endColor.substr(4, 2), 16);

  // calculate new color
  let diffRed = ((endRed - startRed) * percent + startRed)
    .toString(16)
    .split('.')[0];
  let diffGreen = ((endGreen - startGreen) * percent + startGreen)
    .toString(16)
    .split('.')[0];
  let diffBlue = ((endBlue - startBlue) * percent + startBlue)
    .toString(16)
    .split('.')[0];

  // ensure 2 digits by color
  if (`${diffRed}`.length == 1) diffRed = '0' + diffRed;
  if (`${diffGreen}`.length == 1) diffGreen = '0' + diffGreen;
  if (`${diffBlue}`.length == 1) diffBlue = '0' + diffBlue;

  return '#' + diffRed + diffGreen + diffBlue;
}

/**
 * Finds the median of a sorted numeric array
 * @param {number[]} values
 * @returns {number} median
 */
export function median(values) {
  const len = values.length;
  if (len === 0) return 0;

  if (len % 2 === 1) {
    return values[Math.floor(len / 2)];
  } else {
    const upperMid = values[Math.ceil((len - 1) / 2)];
    const lowerMid = values[Math.floor((len - 1) / 2)];
    return (upperMid + lowerMid) / 2;
  }
}

/**
 * Generate 3 quartile markers given a sorted set of data
 * @param {number[]} data Sorted data array
 * @returns {[number, number, number]} quartile markers
 */
export function generateQuartiles(data) {
  if (data.length === 0) return [0, 0, 0];

  const middle = median(data);
  const firstHalfData = data.filter((d) => d <= middle);
  const firstQuartile = median(firstHalfData);
  const secondQuartile = firstHalfData[firstHalfData.length - 1];
  const thirdQuartile = median(data.filter((d) => d > middle));
  return [firstQuartile, secondQuartile, thirdQuartile];
}

export function getQuarterFromQuartile(value, quartiles) {
  if (value < quartiles[0]) return 0;
  if (value < quartiles[1]) return 1;
  if (value < quartiles[2]) return 2;
  return 3;
}

export const dateToStringInEST = (d) => {
  d = new Date(d);
  return `${new Intl.DateTimeFormat('en-US', { month: 'long' }).format(
    d
  )}, ${d.getFullYear()}`;
};

export const dateToStringInESTWithDay = (d) => {
  d = new Date(d);
  return `${new Intl.DateTimeFormat('en-US', {
    year: '2-digit',
    month: 'numeric',
    day: '2-digit',
  }).format(d)}`;
};
