import React from 'react';
import PropTypes from 'prop-types';

export const universeMapping = {
  global_all: 'Global All',
  global_large: 'Global Developed',
  global_small: 'Global Emerging',
  intl_all: 'International All',
  intl_large: 'International Developed',
  intl_small: 'International Emerging',
  us_all: 'US All',
  us_large: 'US Large Cap',
  us_mid: 'US Mid Cap',
  us_small: 'US Small Cap',
};

export const LabsUniversePicker = ({ selected, onChange }) => {
  return (
    <select
      className="form-control form-control-sm"
      value={selected}
      onChange={onChange}
    >
      {Object.keys(universeMapping).map((u) => {
        if (!universeMapping[u]) return null;
        return (
          <option key={u} value={u}>
            {universeMapping[u]}
          </option>
        );
      })}
    </select>
  );
};

LabsUniversePicker.propTypes = {
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
