import React, { useState, useContext } from 'react';
import { Login } from './login';
import { AccountContext } from '../../../hocs/account-context';

export const LoginContainer = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const { login } = useContext(AccountContext);

  const handleChangeEmail = event => setEmail(event.target.value);
  const handleChangePassword = event => setPassword(event.target.value);

  const handleSubmit = e => {
    e.preventDefault();
    login(email, password).then(res => {
      if (!res) setError('Incorrect username or password');
    });
  };

  return (
    <Login
      {...{
        email,
        handleChangeEmail,
        password,
        handleChangePassword,
        handleSubmit,
        error,
      }}
    />
  );
};
