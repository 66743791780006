import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import {
  fetchConstituentData,
  fetchExportConstituentData,
  fetchMedian,
} from '../../api';

import { AccountContext } from '../../hocs/account-context';
import { withQueryProps } from '../../hocs/with-query-props';
import { filterData } from '../../filter';
import {
  currentDatetimeAsFilename,
  median as generateMedian,
} from '../../formatters';

import { Chart } from './chart';
import { Form } from './form';
import { setDynamicConstituentMinMax } from '../../../../server/metrics';

export const defaultProps = {
  universe: 'us',
  asset: 'VOO',
  metric: 'Percentile ESG',
  yMetric: 'ESG Signal 2',
  colorMetric: 'ESG Signal 1',
  colorBy: 'color_universe',
  medianMethod: 'universe',
  min: '0',
  max: '100',
  sector: '',
  industry_group: '',
  industry: '',
  subindustry: '',
};

/*
level 1 - sector
level 2 - industry group
level 3 - industry
level 4 - subindustry
 */
export const ETFConstituentsContext = createContext({
  sectors: [],
  industryGroups: [],
  industries: [],
  subindustries: [],
});

const ContainerBase = (props) => {
  let {
    universe,
    sector,
    industry,
    subindustry,
    industry_group,
    asset,
    assetDate,
    metric,
    yMetric,
    min,
    max,
    colorMetric,
    colorBy,
    medianMethod,
  } = props;
  let [chartData, setChartData] = useState({
    universe,
    asset,
    metric,
    yMetric,
    data: undefined,
    rawData: undefined,
    colorMetric,
  });
  const { token, account } = useContext(AccountContext);
  const isAdmin = account && account.permissions.includes('administrator');

  const [isLoading, setIsLoading] = useState(true);
  const [median, setMedian] = useState({ x: 0.5, y: 0.5 });
  const [hasData, setHasData] = useState(false);
  const [classifications, setClassifications] = useState({
    sectors: [],
    industries: [],
    subindustries: [],
    industryGroups: [],
  });

  let xMin = 0;
  let xMax = 1;
  let yMin = 0;
  let yMax = 1;

  if (chartData.data && chartData.data.length > 0) {
    if (setDynamicConstituentMinMax.has(metric)) {
      xMin = Math.min(...chartData.data.map((p) => p.x).filter(Boolean));
      xMax = Math.max(...chartData.data.map((p) => p.x).filter(Boolean));
    } else {
      xMin = chartData.data[0].xmin;
      xMax = chartData.data[0].xmax;
    }

    if (setDynamicConstituentMinMax.has(yMetric)) {
      yMin = Math.min(...chartData.data.map((p) => p.y).filter(Boolean));
      yMax = Math.max(...chartData.data.map((p) => p.y).filter(Boolean));
    } else {
      yMin = chartData.data[0].ymin;
      yMax = chartData.data[0].ymax;
    }
  }

  const calcMedianAsset = useCallback(
    (filtered = false) => {
      const allMetricValues = chartData[filtered ? 'data' : 'rawData']
        .map((r) => r.x)
        .filter((r) => !isNaN(r))
        .sort((a, b) => a - b);

      const allAlphaValues = chartData[filtered ? 'data' : 'rawData']
        .map((r) => r.y)
        .filter((r) => !isNaN(r))
        .sort((a, b) => a - b);

      const metricMedian = generateMedian(allMetricValues);
      const alphaMedian = generateMedian(allAlphaValues);
      return { metricMedian, alphaMedian };
    },
    [chartData]
  );

  // Calculate / set median values
  useEffect(() => {
    switch (medianMethod) {
      case 'universe':
        fetchMedian({ universe, metric }, token).then((res) => setMedian(res));
        break;
      case 'asset': {
        if (!hasData) break;
        const { metricMedian, alphaMedian } = calcMedianAsset();
        setMedian({
          x: metricMedian,
          y: alphaMedian,
        });

        break;
      }
      case 'filtered asset': {
        if (!hasData) break;
        const { metricMedian, alphaMedian } = calcMedianAsset(true);
        setMedian({
          x: metricMedian,
          y: alphaMedian,
        });
        break;
      }
      case 'absolute': {
        if (!chartData.rawData) {
          setMedian({ x: 0.5, y: 0.5 });
          break;
        }
        setMedian({ x: (xMax + xMin) / 2, y: (yMax + yMin) / 2 });
        break;
      }
    }
  }, [
    medianMethod,
    asset,
    yMetric,
    universe,
    metric,
    hasData,
    chartData.rawData,
    chartData.data,
  ]);

  const updateClassifications = (objs, sector, industry_group) => {
    let total = objs.length;
    const getUniqueItems = (arr, key) => {
      const valuesArray = arr.map((o) => o[key]);
      const uniqSorted = [...new Set(valuesArray)].sort();
      return uniqSorted.map((value) => {
        const t = valuesArray.filter((i) => i === value).length;
        // we don't need to show 100% of no values just display "No Sector" etc
        const title =
          uniqSorted.length === 1 &&
          value.match(new RegExp(`no ${key.replace('_', ' ')}`, 'i'))
            ? value
            : `${value} - ${t} -  ${((t / total) * 100).toFixed(2)}%`;
        return {
          [key]: {
            value,
            title,
          },
        };
      });
    };
    // Never filter out sectors
    const sectors = getUniqueItems(objs, 'sector');

    // Constrain all groups below if sector is selected
    if (sector) objs = objs.filter((p) => p.sector === sector);
    total = objs.length;
    const industryGroups = getUniqueItems(objs, 'industry_group');

    // Constrain all groups below if industry_group is selected
    if (industry_group)
      objs = objs.filter((p) => p.industry_group === industry_group);
    total = objs.length;
    const industries = getUniqueItems(objs, 'industry');
    const subindustries = getUniqueItems(objs, 'subindustry');

    setClassifications({
      sectors,
      industryGroups,
      industries,
      subindustries,
    });
  };
  const filterChartData = useCallback(
    (objs) => {
      if (objs) {
        updateClassifications(objs, sector, industry_group, industry);
        let data = filterData(objs, min, max);
        if (sector) data = data.filter((p) => p.sector === sector);
        if (industry_group)
          data = data.filter((p) => p.industry_group === industry_group);
        if (industry) data = data.filter((p) => p.industry === industry);
        if (subindustry)
          data = data.filter((p) => p.subindustry === subindustry);

        // Set Color Value
        data = data.map((d) => ({
          ...d,
          color: d[colorBy],
        }));

        return data;
      }
    },
    [industry, industry_group, max, min, sector, subindustry]
  );

  // Set Color Value
  useEffect(() => {
    if (chartData.data) {
      const data = chartData.data.map((d) => ({
        ...d,
        color: d[colorBy],
      }));
      setChartData({
        ...chartData,
        data,
      });
    }
  }, [colorBy]);

  // data fetch on option change
  useEffect(() => {
    setIsLoading(true);
    fetchConstituentData(props, token).then((objs) => {
      let data = filterChartData(objs);
      setChartData({
        universe,
        asset,
        metric,
        yMetric,
        data,
        rawData: objs,
        colorMetric,
      });
      setHasData(!!objs.length);
      setIsLoading(false);
    });
  }, [universe, asset, metric, yMetric, colorMetric, assetDate]);

  // filter if min/max change
  useEffect(() => {
    setIsLoading(true);
    let data = filterChartData(chartData.rawData);
    setChartData(Object.assign({}, chartData, { data }));
    setIsLoading(false);
  }, [filterChartData]);

  const requestCSV = () => {
    setIsLoading(true);
    fetchExportConstituentData(
      {
        universe,
        asset,
        metric,
        yMetric,
        min,
        max,
        sector,
        quadrant: null,
        metricMedian: median.x,
        alphaMedian: median.y,
        format: 'csv',
        colorMetric,
        assetDate,
      },
      token
    ).then((res) => {
      setIsLoading(false);
      saveAs(res, `etf_constituent_${currentDatetimeAsFilename()}.csv`);
    });
  };

  const requestCSVAdvanced = (filter = '') => {
    setIsLoading(true);
    fetchExportConstituentData(
      {
        universe,
        asset,
        metric,
        yMetric,
        min,
        max,
        sector,
        quadrant: null,
        metricMedian: median.x,
        alphaMedian: median.y,
        format: `csv-advanced${filter}`,
        colorMetric,
        assetDate,
      },
      token
    ).then((res) => {
      setIsLoading(false);
      saveAs(
        res,
        `${universe}_${asset}${
          filter ? filter.replace('-', '_') : ''
        }_constituent_advanced_${currentDatetimeAsFilename()}.csv`
      );
    });
  };

  const requestPNG = () => {
    setIsLoading(true);
    fetchExportConstituentData(
      {
        universe,
        asset,
        metric,
        yMetric,
        min,
        max,
        sector,
        metricMedian: median.x,
        alphaMedian: median.y,
        colorMetric,
        format: 'png',
        quadrant: null,
        assetDate,
      },
      token
    ).then((res) => {
      setIsLoading(false);
      saveAs(res, `etf_constituent_${currentDatetimeAsFilename()}.png`);
    });
  };

  const requestPDF = () => {
    setIsLoading(true);
    fetchExportConstituentData(
      {
        universe,
        asset,
        metric,
        yMetric,
        min,
        max,
        sector,
        metricMedian: median.x,
        alphaMedian: median.y,
        colorMetric,
        format: 'pdf',
        quadrant: null,
        assetDate,
      },
      token
    ).then((res) => {
      setIsLoading(false);
      saveAs(res, `etf_constituent_${currentDatetimeAsFilename()}.pdf`);
    });
  };

  return (
    <React.Fragment>
      <ETFConstituentsContext.Provider value={classifications}>
        <Form
          {...props}
          isLoading={isLoading}
          totalConstituents={chartData.rawData?.length}
        />
        <Chart
          data={chartData}
          requestCSV={requestCSV}
          requestPNG={requestPNG}
          requestPDF={requestPDF}
          requestCSVAdvanced={requestCSVAdvanced}
          median={median}
          medianMethod={medianMethod}
          xMinMax={[xMin, xMax]}
          yMinMax={[yMin, yMax]}
          isAdmin={isAdmin}
        />
      </ETFConstituentsContext.Provider>
    </React.Fragment>
  );
};

ContainerBase.propTypes = {
  universe: PropTypes.string.isRequired,
  sector: PropTypes.string.isRequired,
  industry_group: PropTypes.string.isRequired,
  industry: PropTypes.string.isRequired,
  subindustry: PropTypes.string.isRequired,
  asset: PropTypes.string.isRequired,
  metric: PropTypes.string.isRequired,
  yMetric: PropTypes.string.isRequired,
  min: PropTypes.string.isRequired,
  max: PropTypes.string.isRequired,
  changeQuery: PropTypes.func.isRequired,
  assetDate: PropTypes.any,
  colorMetric: PropTypes.any,
  medianMethod: PropTypes.any,
};

export const Container = withQueryProps(ContainerBase, defaultProps);
