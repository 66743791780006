function postData(url, data, token) {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'auth-token': token,
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data),
  });
}

function deleteData(url, data, token) {
  return fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'auth-token': token,
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data),
  });
}

function getData(url, token) {
  return fetch(url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'auth-token': token,
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });
}

export function fetchEsgChartData(
  { type, universe, asset, start, end, date, metric, useRebalanced },
  token
) {
  return getData(
    `/api/chart/${type}/${universe}?asset=${asset}&start=${start}&end=${end}&date=${date}&metric=${metric}&useRebalanced=${useRebalanced}`,
    token
  ).then((res) => res.json());
}

export function fetchCarbonChartData({ universe, asset, start, end }, token) {
  return getData(
    `/api/chart/${universe}/carbon?asset=${asset}&start=${start}&end=${end}`,
    token
  ).then((res) => res.json());
}

export function fetchESGLabsAnalysisData(
  { leftAsset, rightAsset, metric, colorMetric, yMetric, joinType, asOfDate },
  token
) {
  const searchParams = new URLSearchParams({
    metric,
    colorMetric,
    yMetric,
    leftAsset,
    rightAsset,
    joinType,
    asOfDate,
    logData: true,
  }).toString();
  return getData(`/api/chart/labs-analysis?${searchParams}`, token).then(
    (res) => res.json()
  );
}

export function fetchExportESGLabsAnalysisData(
  { leftAsset, rightAsset, metric, colorMetric, yMetric, joinType, asOfDate },
  token
) {
  const searchParams = new URLSearchParams({
    metric,
    colorMetric,
    yMetric,
    leftAsset,
    rightAsset,
    joinType,
    asOfDate,
    logData: true,
  }).toString();
  return getData(`/api/export/labs-analysis?${searchParams}`, token).then(
    (res) => res.blob()
  );
}

export function fetchESGLabsData(
  {
    universe,
    useEtf,
    asset,
    sectorCore: sector,
    pEnv,
    pSoc,
    pGov,
    pDiv,
    pCarbon,
    cMktCap,
    s3MktCap,
    percentESG,
    perfESG,
    performanceESG,
    carbonAlphaESG,
    priceMo,
    priceMoST,
    priceMoLT,
    priceMoSector,
    priceMoSectorST,
    priceMoSectorLT,
    c1Metric,
    c2Metric,
    c3Metric,
    c4Metric,
    metric,
    yMetric,
    colorMetric,
    min,
    max,
    runDateStart,
    type,
    onlyIncludeTickers,
    includeTickers,
    excludeTickers,
    excludeSectors,
    selectedMetrics,
    combinedMetrics,
    logData = false,
  },
  token
) {
  const searchParams = new URLSearchParams({
    sector,
    useEtf,
    asset,
    pEnv,
    pSoc,
    pGov,
    pDiv,
    pCarbon,
    cMktCap,
    s3MktCap,
    percentESG,
    perfESG,
    performanceESG,
    carbonAlphaESG,
    priceMo,
    priceMoST,
    priceMoLT,
    priceMoSector,
    priceMoSectorST,
    priceMoSectorLT,
    c1Metric,
    c2Metric,
    c3Metric,
    c4Metric,
    metric,
    yMetric,
    colorMetric,
    min,
    max,
    runDateStart,
    onlyIncludeTickers,
    includeTickers,
    excludeTickers,
    excludeSectors,
    selectedMetrics,
    combinedMetrics,
    logData,
  }).toString();
  return getData(
    `/api/chart/${universe}/esg-lab/${type}?${searchParams}`,
    token
  ).then((res) => res.json());
}

export function fetchEtfMetrics({ universe, asset, type, startDate }, token) {
  const searchParams = new URLSearchParams({ asset, startDate }).toString();
  return getData(
    `/api/etf/${universe}/metrics/${type}?${searchParams}`,
    token
  ).then((res) => res.json());
}

export function fetchExportEtfMetrics(
  { universe, asset, type, startDate },
  token
) {
  const searchParams = new URLSearchParams({ asset, startDate }).toString();
  return getData(
    `/api/export/etf-metrics/${universe}/${type}?${searchParams}`,
    token
  ).then((res) => res.blob());
}

export function fetchExportESGLabData(
  {
    universe,
    useEtf,
    asset,
    sectorCore: sector,
    pEnv,
    pSoc,
    pGov,
    pDiv,
    pCarbon,
    cMktCap,
    s3MktCap,
    percentESG,
    perfESG,
    performanceESG,
    carbonAlphaESG,
    priceMo,
    priceMoST,
    priceMoLT,
    priceMoSector,
    priceMoSectorST,
    priceMoSectorLT,
    runDateStart,
    onlyIncludeTickers,
    includeTickers,
    excludeTickers,
    excludeSectors,
    selectedMetrics,
    combinedMetrics,
    logData = true,
  },
  type,
  token
) {
  const searchParams = new URLSearchParams({
    sector,
    useEtf,
    asset,
    pEnv,
    pSoc,
    pGov,
    pDiv,
    pCarbon,
    cMktCap,
    s3MktCap,
    percentESG,
    performanceESG,
    perfESG,
    carbonAlphaESG,
    priceMo,
    priceMoST,
    priceMoLT,
    priceMoSector,
    priceMoSectorST,
    priceMoSectorLT,
    runDateStart,
    onlyIncludeTickers,
    includeTickers,
    excludeTickers,
    excludeSectors,
    selectedMetrics,
    combinedMetrics,
    logData,
  }).toString();
  return getData(
    `/api/export/labs/${universe}/${type}?${searchParams}`,
    token
  ).then((res) => res.blob());
}

export function fetchExportESGLabRankingsData(
  {
    universe,
    asset,
    selectedMetrics,
    sliderOne,
    sliderTwo,
    benchmark,
    logData = true,
  },
  token
) {
  const searchParams = new URLSearchParams({
    asset,
    selectedMetrics,
    sliderOne,
    sliderTwo,
    benchmark,
    logData,
  }).toString();
  return getData(
    `/api/export/labs-rankings/${universe}?${searchParams}`,
    token
  ).then((res) => res.blob());
}

export function postCreateBookmark(
  {
    universe,
    useEtf,
    asset,
    sectorCore: sector,
    pEnv,
    pSoc,
    pGov,
    pDiv,
    pCarbon,
    cMktCap,
    s3MktCap,
    percentESG,
    perfESG,
    performanceESG,
    carbonAlphaESG,
    priceMo,
    priceMoST,
    priceMoLT,
    priceMoSector,
    priceMoSectorST,
    priceMoSectorLT,
    runDateStart,
    onlyIncludeTickers,
    includeTickers,
    excludeTickers,
    excludeSectors,
    selectedMetrics,
    combinedMetrics,
  },
  name,
  token
) {
  return postData(
    '/api/labs/bookmarks',
    {
      name,
      universe,
      useEtf,
      asset,
      sectorCore: sector,
      pEnv,
      pSoc,
      pGov,
      pDiv,
      pCarbon,
      cMktCap,
      s3MktCap,
      percentESG,
      perfESG,
      performanceESG,
      carbonAlphaESG,
      priceMo,
      priceMoST,
      priceMoLT,
      priceMoSector,
      priceMoSectorST,
      priceMoSectorLT,
      runDateStart,
      onlyIncludeTickers,
      includeTickers,
      excludeTickers,
      excludeSectors,
      selectedMetrics,
      combinedMetrics,
    },
    token
  ).then((res) => res.json());
}

export function postUpdateBookmark(
  {
    universe,
    useEtf,
    asset,
    sectorCore: sector,
    pEnv,
    pSoc,
    pGov,
    pDiv,
    pCarbon,
    cMktCap,
    s3MktCap,
    percentESG,
    perfESG,
    performanceESG,
    carbonAlphaESG,
    priceMo,
    priceMoST,
    priceMoLT,
    priceMoSector,
    priceMoSectorST,
    priceMoSectorLT,
    runDateStart,
    onlyIncludeTickers,
    includeTickers,
    excludeTickers,
    excludeSectors,
    selectedMetrics,
    combinedMetrics,
  },
  id,
  token
) {
  return postData(
    `/api/labs/bookmarks/${id}/settings`,
    {
      universe,
      useEtf,
      asset,
      sectorCore: sector,
      pEnv,
      pSoc,
      pGov,
      pDiv,
      pCarbon,
      cMktCap,
      s3MktCap,
      percentESG,
      perfESG,
      performanceESG,
      carbonAlphaESG,
      priceMo,
      priceMoST,
      priceMoLT,
      priceMoSector,
      priceMoSectorST,
      priceMoSectorLT,
      runDateStart,
      onlyIncludeTickers,
      includeTickers,
      excludeTickers,
      excludeSectors,
      selectedMetrics,
      combinedMetrics,
    },
    token
  ).then((res) => res.json());
}

export function fetchBookmarks(token) {
  return getData('/api/labs/bookmarks', token).then((res) => res.json());
}

export function fetchBookmarkSettings(id, token) {
  return getData(`/api/labs/bookmarks/${id}`, token).then((res) => res.json());
}

export function deleteBookmark(id, token) {
  return deleteData(`/api/labs/bookmarks/${id}`, {}, token).then((res) =>
    res.json()
  );
}

export function fetchUniverses(_, token) {
  return getData('/api/universes', token).then((res) => res.json());
}

export function fetchTickers({ type, universe, query }, token) {
  return getData(`/api/tickers/${type}/${universe}?query=${query}`, token).then(
    (res) => res.json()
  );
}

export function fetchStockSectors({ universe, date }, token) {
  return getData(
    `/api/stocks/sectors?universe=${universe}&date=${date}`,
    token
  ).then((res) => res.json());
}

export function fetchStockAssets({ sector, universe, date }, token) {
  return getData(
    `/api/stocks/assets?sector=${sector}&universe=${universe}&date=${date}`,
    token
  ).then((res) => res.json());
}

export function fetchSectors({ universe }, token) {
  return getData(`/api/sectors?universe=${universe}`, token).then((res) =>
    res.json()
  );
}

export function fetchMedian({ universe, metric, yMetric }, token) {
  return getData(
    `/api/etf/${universe}/constituents/median?metric=${metric}&yMetric=${yMetric}`,
    token
  ).then((res) => res.json());
}

export function fetchDates({ universe, assetType = 'esg', asset }, token) {
  return getData(
    `/api/dates?universe=${universe}&assetType=${assetType}${
      asset ? `&asset=${asset}` : ''
    }`,
    token
  ).then((res) => res.json());
}

export function fetchConstituentMetrics({ universe }, token) {
  return getData(`/api/etf/${universe}/constituents/metrics`, token).then(
    (res) => res.json()
  );
}

export function fetchConstituentColorMetrics({ universe }, token) {
  return getData(`/api/etf/${universe}/constituents/color-metrics`, token).then(
    (res) => res.json()
  );
}

export function fetchEsgAnalysisMetrics(_, token) {
  return getData('/api/esg/analysis/metrics', token).then((res) => res.json());
}

export function fetchEsgAnalysisUniverses(_, token) {
  return getData('/api/esg/analysis/universes', token).then((res) =>
    res.json()
  );
}

export function fetchConstituentTickers({ universe, query }, token) {
  return getData(
    `/api/etf/${universe}/constituents/tickers?query=${query}`,
    token
  ).then((res) => res.json());
}

export function fetchConstituentSectors({ universe }, token) {
  return getData(`/api/etf/${universe}/constituents/sectors`, token).then(
    (res) => res.json()
  );
}

export function fetchConstituentWeights({ universe, asset }, token) {
  return getData(
    `/api/etf/${universe}/constituents/weight?asset=${asset}`,
    token
  ).then((res) => res.json());
}

export function fetchConstituentData(
  { universe, asset, metric, colorMetric, min, max, yMetric, assetDate },
  token
) {
  return getData(
    `/api/etf/${universe}/constituents?asset=${asset}&metric=${metric}${
      colorMetric ? `&colorMetric=${colorMetric}` : ''
    }&min=${min}&max=${max}&yMetric=${yMetric}${
      assetDate ? `&date=${assetDate}` : ''
    }`,
    token
  ).then((res) => res.json());
}

export function fetchCustomPortfolioData(
  { universe, asset, metric, colorMetric, min, max, yMetric, assetDate },
  token
) {
  return getData(
    `/api/etf/${universe}/constituents?asset=${asset}&metric=${metric}${
      colorMetric ? `&colorMetric=${colorMetric}` : ''
    }&min=${min}&max=${max}&yMetric=${yMetric}${
      assetDate ? `&date=${assetDate}` : ''
    }`,
    token
  ).then((res) => res.json());
}

export function fetchExportConstituentData(
  {
    universe,
    asset,
    metric,
    min,
    max,
    sector,
    alphaMedian,
    metricMedian,
    quadrant,
    format,
    colorMetric,
    assetDate,
  },
  token
) {
  return getData(
    `/api/export/etf/${universe}/constituents/${format}?asset=${asset}&metric=${metric}&min=${min}&max=${max}&sector=${sector}&alphaMedian=${alphaMedian}&metricMedian=${metricMedian}&quadrant=${quadrant}&colorMetric=${colorMetric}${
      assetDate ? `&date=${assetDate}` : ''
    }`,
    token
  ).then((res) => res.blob());
}

export function fetchExportRawData({ universe, dataSet, date }, token) {
  return getData(
    `/api/export/raw-data/${universe}?dataSet=${dataSet}&date=${date}`,
    token
  ).then((res) => res.blob());
}

export function fetchExportEsgAnalysisData(
  { universe, metric, date, format },
  token
) {
  return getData(
    `/api/export/esg-analysis/${universe}/${format}?metric=${metric}&date=${date}`,
    token
  ).then((res) => res.blob());
}

export function postCreateAccount({ email, password, permissions }, token) {
  return postData(
    '/api/admin/account',
    { email, password, permissions },
    token
  ).then((res) => res.json());
}

export function postCreateSession({ email, password }) {
  return postData('/api/account/session', { email, password }).then((res) =>
    res.json()
  );
}

export function fetchAccount(token) {
  return getData('/api/account', token).then((res) => res.json());
}

export function fetchAccountById({ id }, token) {
  return getData(`/api/admin/account/${id}`, token).then((res) => res.json());
}

export function deleteSession(token) {
  return deleteData('/api/account/session', {}, token);
}

export function fetchAllAccounts(token) {
  return getData('/api/admin/accounts', token).then((res) => res.json());
}

export function postUpdateAccountEmail({ email, accountId }, token) {
  return postData(
    `/api/admin/account/${accountId}/email`,
    { email },
    token
  ).then((res) => res.json());
}

export function postUpdateAccountPassword(
  { password, passwordConfirmation, accountId },
  token
) {
  return postData(
    `/api/admin/account/${accountId}/password`,
    { password, passwordConfirmation },
    token
  ).then((res) => res.json());
}

export function postUpdateAccountPermissions(
  { permissions, accountId },
  token
) {
  return postData(
    `/api/admin/account/${accountId}/permissions/`,
    { permissions },
    token
  ).then((res) => res.json());
}

export function deleteAccount({ accountId }, token) {
  return deleteData(`/api/admin/account/${accountId}`, {}, token).then((res) =>
    res.json()
  );
}

export function fetchGlobalSettings(token) {
  return getData('/api/admin/global-settings', token).then((res) => res.json());
}

export function postUpdateGlobalSettings({ settings }, token) {
  return postData('/api/admin/global-settings', { settings }, token).then(
    (res) => res.json()
  );
}

export function fetchLastProductionMonth(token) {
  return getData('/api/last-production-month', token).then((res) => res.json());
}
