import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fetchUniverses } from '../api';
import { AccountContext } from '../hocs/account-context';

const universeMapping = {
  us: 'US',
  intl: 'International',
  global: 'Global',
};

export const UniversePicker = ({ selected, onChange }) => {
  const [universes, setUniverses] = useState([]);
  const { token } = useContext(AccountContext);

  useEffect(() => {
    fetchUniverses({}, token).then(res => {
      setUniverses(res);
    });
  }, []);

  return (
    <select
      className="form-control form-control-sm"
      value={selected}
      onChange={onChange}
    >
      {universes.map(u => {
        if (!universeMapping[u]) return null;
        return (
          <option key={u} value={u}>
            {universeMapping[u]}
          </option>
        );
      })}
    </select>
  );
};

UniversePicker.propTypes = {
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
