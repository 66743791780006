import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ConstituentMetricPicker } from '../../components/constituent-metric-picker';
import { ConstituentColorMetricPicker } from '../../components/constituent-color-metric-picker';
import { ETFConstituentsContext } from '../etf-constituents/container';
import { ConstituentClassificationsPicker } from '../../components/constituent-classifications-picker';
import { MedianPickerV2 } from '../../components/median-picker-v2';
import { ConstituentColorByPicker } from '../../components/constituent-color-by-picker';
import { ToolbarColumn } from '../../components/toolbar-column';

export const Form = ({
  universe,
  sector,
  industry_group,
  industry,
  subindustry,
  metric,
  yMetric,
  colorMetric,
  colorBy,
  changeQuery,
  medianMethod,
  isLoading,
  totalConstituents,
}) => {
  const { sectors, industries, subindustries, industryGroups } = useContext(
    ETFConstituentsContext
  );
  const formDivClassName = 'form-group form-group--column';
  return (
    <ToolbarColumn isLoading={isLoading}>
      <div className={formDivClassName}>
        <label>X Metric</label>
        <ConstituentMetricPicker
          metric={metric}
          onChange={(e) => changeQuery({ metric: e.target.value })}
        />
      </div>
      <div className={formDivClassName}>
        <label>Y Metric</label>
        <ConstituentMetricPicker
          metric={yMetric}
          onChange={(e) => changeQuery({ yMetric: e.target.value })}
        />
      </div>
      <div className={formDivClassName}>
        <label>Color Metric</label>
        <ConstituentColorMetricPicker
          metric={colorMetric}
          onChange={(e) => changeQuery({ colorMetric: e.target.value })}
        />
      </div>
      <div className={formDivClassName}>
        <label>Color By</label>
        <ConstituentColorByPicker
          metric={colorBy}
          onChange={(e) => changeQuery({ colorBy: e.target.value })}
        />
      </div>
      <div className={formDivClassName}>
        <label>Median</label>
        <MedianPickerV2
          median={medianMethod}
          onChange={(e) => changeQuery({ medianMethod: e.target.value })}
        />
      </div>
      {/*<div className={formDivClassName}>*/}
      {/*  <label>Run Date</label>*/}
      {/*  <DatePickerAssetV2*/}
      {/*    type="etf-constituents"*/}
      {/*    asset={asset}*/}
      {/*    universe={universe}*/}
      {/*    date={assetDate}*/}
      {/*    assetType="etf-constituents"*/}
      {/*    onChange={(e) => changeQuery({ assetDate: e.target.value })}*/}
      {/*  />*/}
      {/*</div>*/}
      <div className={formDivClassName}>
        <label>
          Sector
          {totalConstituents
            ? ` - Total Constituents: ${totalConstituents}`
            : ''}
        </label>
        <ConstituentClassificationsPicker
          universe={universe}
          keyName={'sector'}
          classification={sector}
          classifications={sectors}
          defaultClassifications={[]}
          onChange={(e) => changeQuery({ sector: e.target.value })}
        />
      </div>
      <div className={formDivClassName}>
        <label>Industry Group</label>
        <ConstituentClassificationsPicker
          universe={universe}
          keyName={'industry_group'}
          classification={industry_group}
          classifications={industryGroups}
          defaultClassifications={[]}
          onChange={(e) => changeQuery({ industry_group: e.target.value })}
        />
      </div>
      <div className={formDivClassName}>
        <label>Industry</label>
        <ConstituentClassificationsPicker
          universe={universe}
          keyName={'industry'}
          classification={industry}
          classifications={industries}
          defaultClassifications={[]}
          onChange={(e) => changeQuery({ industry: e.target.value })}
        />
      </div>
      <div className={formDivClassName}>
        <label>Subindustry</label>
        <ConstituentClassificationsPicker
          universe={universe}
          keyName={'subindustry'}
          classification={subindustry}
          classifications={subindustries}
          defaultClassifications={[]}
          onChange={(e) => changeQuery({ subindustry: e.target.value })}
        />
      </div>
    </ToolbarColumn>
  );
};

Form.propTypes = {
  universe: PropTypes.string.isRequired,
  sector: PropTypes.string.isRequired,
  metric: PropTypes.string.isRequired,
  yMetric: PropTypes.string.isRequired,
  min: PropTypes.string.isRequired,
  max: PropTypes.string.isRequired,
  changeQuery: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  sectors: PropTypes.any,
  assetDate: PropTypes.any,
  industry: PropTypes.any,
  subindustry: PropTypes.any,
  industry_group: PropTypes.any,
  colorMetric: PropTypes.any,
  colorBy: PropTypes.string,
  medianMethod: PropTypes.any,
  totalConstituents: PropTypes.number,
  onlyIncludeTickers: PropTypes.string.isRequired,
  includeTickers: PropTypes.string.isRequired,
  excludeTickers: PropTypes.string.isRequired,
};
