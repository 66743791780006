import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Collapse,
  Container as ReactStrapContainer,
  Row,
} from 'reactstrap';
import PropTypes from 'prop-types';

import { withQueryProps } from '../../hocs/with-query-props';
import { ContainerBase as Constituents } from '../labs-constituents/container';
import { AccountContext } from '../../hocs/account-context';
import { fetchESGLabsData, fetchExportESGLabData } from '../../api';
import { saveAs } from 'file-saver';
import { defaultProps } from '../labs-esg-core/container';
import { LineChart } from './line-chart';
import { PieChart } from './pie-chart';
import { CalculateEmissionsForm } from './calculate-emissions-form';
import { Icon } from '../../components/icon';
import { Sidebar } from '../labs-plus/constituents-sidebar';
import { Loading } from '../../components/loading';
import { currentDatetimeAsFilename, dateToStringInEST } from '../../formatters';

export const ContainerBase = (props) => {
  const {
    universe,
    sectorCore,
    runDateStart,
    onlyIncludeTickers,
    includeTickers,
    excludeTickers,
    excludeSectors,
  } = props;
  const [lastProdMonth, setLastProdMonth] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isConstituentsOpen, setIsConstituentsOpen] = useState(false);
  const toggleSidebar = () => setIsConstituentsOpen(!isConstituentsOpen);
  const [lineChartData = { universe, sectorCore }, setLineChartData] =
    useState();
  const [pieChartData, setPieChartData] = useState();
  const { token } = useContext(AccountContext);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const filteredLineChartParams = {
      ...props,
      type: 'emissions-line-chart',
      logData: true,
    };
    const getFilteredLineChartData = fetchESGLabsData(
      filteredLineChartParams,
      token
    );

    const benchmarkLineChartParams = {
      ...defaultProps,
      universe,
      sectorCore,
      runDateStart,
      type: 'emissions-line-chart',
    };
    const getBenchmarkLineChartData = fetchESGLabsData(
      benchmarkLineChartParams,
      token
    );

    const filteredPieChartParams = { ...props, type: 'emissions-pie-chart' };
    const getFilteredPieChartData = fetchESGLabsData(
      filteredPieChartParams,
      token
    );

    const [filteredData, benchmarkData, filteredPieChartData] =
      await Promise.all([
        getFilteredLineChartData,
        getBenchmarkLineChartData,
        getFilteredPieChartData,
      ]);

    const { date } = filteredData[filteredData.length - 1];
    if (date) setLastProdMonth(` - ${dateToStringInEST(date)}`);

    setPieChartData(filteredPieChartData);
    setLineChartData({ filteredData, benchmarkData, universe });
    setIsLoading(false);
  }, [props, runDateStart, sectorCore, token, universe]);

  useEffect(() => {
    fetchData();
  }, [onlyIncludeTickers, includeTickers, excludeTickers, excludeSectors]);

  const requestCSV = async () => {
    const res = await fetchExportESGLabData(props, 'climate', token);
    const date = currentDatetimeAsFilename();
    saveAs(res, `esg_lab_climate_metrics_${date}.csv`);
  };

  return (
    <ReactStrapContainer fluid>
      <Row>
        <Col>
          {isLoading ? (
            <div className="mt-5 w-100 justify-content-center align-items-center">
              <Loading />
            </div>
          ) : (
            <>
              <Row>
                <Col xs="12">
                  <h2 className="text-center mb-2 mt-2">
                    Total Emissions Analysis Since&nbsp;
                    {dateToStringInEST(runDateStart)}
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col xs="8">
                  <LineChart
                    data={lineChartData}
                    chartTitle={
                      'Avg Climate Emissions Intensity (tons of GHG/tCO2e per $MM Invested)'
                    }
                    yAxisTitle={'Average Climate Emissions Intensity'}
                    yAxisKey={'total_emissions_per_mkt_cap'}
                    metricsKey={'climate_total_emissions'}
                    requestCSV={requestCSV}
                  />
                </Col>
                <Col xs="4">
                  <CalculateEmissionsForm
                    tonsPerDollar={
                      lineChartData?.filteredData[
                        lineChartData.filteredData.length - 1
                      ]['total_emissions_per_mkt_cap_avg']
                    }
                    title={'Total Emissions'}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <h2 className="text-center mb-2 mt-2">
                    Total Emissions {lastProdMonth}
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <PieChart
                    data={pieChartData}
                    chartTitle={'Percent Total Emissions Per Sector'}
                    yAxisTitle={'(Scope 1 and 2) Emissions'}
                    yAxisKey={'climate_total_emissions'}
                  />
                </Col>
                <Col xs="6">
                  <PieChart
                    data={pieChartData}
                    chartTitle={'Percent Allocation Per Sector'}
                    yAxisTitle={'Allocations'}
                    yAxisKey={'num_constituents_per_sector'}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Constituents
                    {...props}
                    metric={'Percentile Carbon'}
                    yMetric={'Percentile Carbon Mkt Cap'}
                    colorMetric={'Percentile Carbon'}
                    colorBy={'color_universe'}
                    chartTitle={'Carbon Profile'}
                    medianMethod={'filtered_asset'}
                    min={'0'}
                    max={'100'}
                    sector={''}
                    industry_group={''}
                    industry={''}
                    subindustry={''}
                    showForm={false}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <h2 className="text-center mb-2 mt-2">
                    Scope 3 Emissions Analysis Since&nbsp;
                    {dateToStringInEST(runDateStart)}
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col xs="8">
                  <LineChart
                    data={lineChartData}
                    chartTitle={
                      'Avg Scope3 Climate Emissions Intensity (tons of GHG/tCO2e per $MM Invested)'
                    }
                    yAxisTitle={'Average Scope3 Climate Emissions Intensity'}
                    yAxisKey={'total_scope3_emissions_per_mkt_cap'}
                    metricsKey={'climate_scope3_emissions'}
                    requestCSV={requestCSV}
                  />
                </Col>
                <Col xs="4">
                  {!isLoading && (
                    <CalculateEmissionsForm
                      tonsPerDollar={
                        lineChartData?.filteredData[
                          lineChartData.filteredData.length - 1
                        ]['total_scope3_emissions_per_mkt_cap_avg']
                      }
                      title={'Scope3 Emissions'}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <h2 className="text-center mb-2 mt-2">
                    Scope 3 Emissions {lastProdMonth}
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <PieChart
                    data={pieChartData}
                    chartTitle={'Percent Scope 3 Emissions Per Sector'}
                    yAxisTitle={'(Scope 3) Emissions'}
                    yAxisKey={'climate_scope3_emissions'}
                  />
                </Col>
                <Col xs="6">
                  <PieChart
                    data={pieChartData}
                    chartTitle={'Percent Allocation Per Sector'}
                    yAxisTitle={'Allocations'}
                    yAxisKey={'num_constituents_per_sector'}
                  />
                </Col>
              </Row>
            </>
          )}
        </Col>
        <Col
          xs={isConstituentsOpen ? '3' : '1'}
          style={{ backgroundColor: '#f7f7f7' }}
        >
          <div className="d-flex justify-content-around mt-2">
            <Button
              className="rounded text-center"
              color="info"
              onClick={toggleSidebar}
              style={{ fontSize: '.80rem' }}
            >
              <Icon
                name={
                  isConstituentsOpen
                    ? 'angle-double-right'
                    : 'angle-double-left'
                }
              />{' '}
              &nbsp; &nbsp;
              {isConstituentsOpen ? 'Hide' : 'View'} Constituents
            </Button>
          </div>
          {isConstituentsOpen && (
            <Collapse isOpen={isConstituentsOpen}>
              <Sidebar
                {...props}
                onClick={fetchData}
                isLoading={isLoading}
                isConstituentsOpen={isConstituentsOpen}
              />
            </Collapse>
          )}
        </Col>
      </Row>
    </ReactStrapContainer>
  );
};

ContainerBase.propTypes = {
  universe: PropTypes.string.isRequired,
  sectorCore: PropTypes.string,
  changeQuery: PropTypes.func.isRequired,
  runDateStart: PropTypes.string,
  onlyIncludeTickers: PropTypes.string,
  includeTickers: PropTypes.string,
  excludeTickers: PropTypes.string,
  excludeSectors: PropTypes.string,
};

export const Container = withQueryProps(ContainerBase, {});
