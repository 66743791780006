import React, { useEffect, useState, useContext } from 'react';
import { AccountContext } from '../../../hocs/account-context';
import { useParams, useHistory } from 'react-router-dom';
import {
  fetchAccountById,
  postUpdateAccountEmail,
  postUpdateAccountPassword,
  deleteAccount,
  postUpdateAccountPermissions,
} from '../../../api';
import { EditAccount } from './edit-account';

export const EditAccountContainer = () => {
  const [account, setAccount] = useState({});
  const [email, setEmail] = useState('');
  const [permissions, setPermissions] = useState([]);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  // Disable interaction until account loads
  const [isInteractionEnabled, setIsInteractionEnabled] = useState(false);
  const { token } = useContext(AccountContext);
  const params = useParams();
  const history = useHistory();
  const id = Number(params?.id);

  useEffect(() => {
    fetchAccountById({ id }, token).then(res => {
      if (res.success) {
        setAccount(res.account);
        setEmail(res.account.email);
        setPermissions(res.account.permissions);
        setIsInteractionEnabled(true);
      } else setError(res.error);
    });
  }, [token]);

  const handleUpdateEmail = e => {
    const email = e.target.value;
    setAccount(Object.assign({}, account, { email: email }));
  };

  const handleSaveEmail = () => {
    setIsInteractionEnabled(false);
    setError(null);
    setMessage(null);
    postUpdateAccountEmail({ email: account.email, accountId: id }, token)
      .then(res => {
        setIsInteractionEnabled(true);
        if (res.success) {
          setError(null);
          setMessage('Email updated');
        } else {
          setError(res.error);
          setMessage(null);
        }
      })
      .catch(() => {
        setError('Unable to save email');
        setIsInteractionEnabled(true);
      });
  };

  const handleUpdatePassword = e => {
    const password = e.target.value;
    setAccount(Object.assign({}, account, { password: password }));
  };

  const handleUpdatePasswordConfirmation = e => {
    const passwordConfirmation = e.target.value;
    setAccount(
      Object.assign({}, account, { passwordConfirmation: passwordConfirmation })
    );
  };

  const handleSavePassword = () => {
    setIsInteractionEnabled(false);
    setMessage(null);
    postUpdateAccountPassword(
      {
        password: account.password,
        passwordConfirmation: account.passwordConfirmation,
        accountId: id,
      },
      token
    )
      .then(res => {
        setIsInteractionEnabled(true);
        setError(null);
        setMessage(null);
        if (res.success) {
          setError(null);
          setMessage('Password updated');
        } else {
          setError(res.error);
          setMessage(null);
        }
      })
      .catch(() => {
        setError('Unable to save password');
        setIsInteractionEnabled(true);
      });
  };

  const handleChangePermissions = e => {
    const selected = e.target.value;
    const idx = permissions.indexOf(selected);
    if (idx !== -1) {
      setPermissions([
        ...permissions.slice(0, idx),
        ...permissions.slice(idx + 1, permissions.length),
      ]);
    } else {
      setPermissions([...permissions, selected]);
    }
  };

  const handleSavePermissions = () => {
    setMessage(null);
    setError(null);
    setIsInteractionEnabled(false);
    postUpdateAccountPermissions({ permissions, accountId: id }, token).then(
      res => {
        setIsInteractionEnabled(true);
        if (res.success) {
          setMessage('Permissions saved');
          setError(null);
        } else {
          setMessage(null);
          setError(res.error);
        }
      }
    );
  };

  const handleDeleteAccount = () => {
    const didConfirm = confirm('Are you sure you want to delete this account?');

    if (didConfirm) {
      deleteAccount({ accountId: id }, token).then(() => {
        history.push('/settings/accounts');
      });
    }
  };

  return (
    account && (
      <EditAccount
        account={account}
        message={message}
        error={error}
        permissions={permissions}
        email={email}
        handleUpdateEmail={handleUpdateEmail}
        handleUpdatePassword={handleUpdatePassword}
        handleUpdatePasswordConfirmation={handleUpdatePasswordConfirmation}
        handleSaveEmail={handleSaveEmail}
        handleSavePassword={handleSavePassword}
        handleChangePermissions={handleChangePermissions}
        handleSavePermissions={handleSavePermissions}
        isInteractionEnabled={isInteractionEnabled}
        handleDeleteAccount={handleDeleteAccount}
      />
    )
  );
};
