import React from 'react';

export const Login = ({
  email,
  handleChangeEmail,
  password,
  handleChangePassword,
  handleSubmit,
  error,
}) => {
  return (
    <div className="container mt-5 account">
      <div className="row mb-3 justify-content-md-center">
        <form className="card">
          <div
            className="card-body justify-content-center"
            style={{ display: 'flex' }}
          >
            <h4>Please log in to view this content</h4>
          </div>
          <div className="card-body">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Email address</label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={email}
                onChange={handleChangeEmail}
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1">Password</label>
              <input
                type="password"
                className="form-control"
                id="exampleInputPassword1"
                value={password}
                onChange={handleChangePassword}
              />
            </div>
            <button onClick={handleSubmit} className="btn btn-primary">
              Log in
            </button>

            {error && (
              <div className="card-body">
                <div className="alert alert-warning">
                  Incorrect username or password
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};
