import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import moment from 'moment';

import { fetchExportRawData } from '../../api';
import { AccountContext } from '../../hocs/account-context';
import { withQueryProps } from '../../hocs/with-query-props';
import { currentDatetimeAsFilename } from '../../formatters';

import { Form } from './form';
import { Chart } from './chart';

const defaultProps = {
  dataSet: 'esg',
  universe: 'us',
  date: moment().format('YYYY-MM-DD'),
};

const ContainerBase = props => {
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useContext(AccountContext);
  let { dataSet, universe, date } = props;

  const requestCSV = () => {
    setIsLoading(true);
    fetchExportRawData({ universe, dataSet, date }, token).then(res => {
      saveAs(res, `raw_data_${currentDatetimeAsFilename()}.csv`);
      setIsLoading(false);
    });
  };

  return (
    <React.Fragment>
      <Form {...props} />
      <Chart handleDownloadClick={requestCSV} isLoading={isLoading} />
    </React.Fragment>
  );
};

ContainerBase.propTypes = {
  universe: PropTypes.string.isRequired,
  dataSet: PropTypes.string.isRequired,
  changeQuery: PropTypes.func.isRequired,
};

export const Container = withQueryProps(ContainerBase, defaultProps);
