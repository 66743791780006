import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { iconize } from '../../icon-util';
import { Loading } from '../../components/loading';

export const Chart = ({ handleDownloadClick, isLoading }) => {
  return (
    <div className="container data-export-container">
      {isLoading && (
        <div className="loading-bg">
          <Loading />
        </div>
      )}
      <div className="download-help">
        Use the filters in the form above to select data, then press the button
        to download
      </div>
      <div className="download-button-container">
        <div className="download-button" onClick={handleDownloadClick}>
          <FontAwesomeIcon
            // @ts-ignore
            icon={iconize('download')}
            size="8x"
          />
          <h1>Download</h1>
        </div>
      </div>
    </div>
  );
};

Chart.propTypes = {
  handleDownloadClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
