import React from 'react';
import PropTypes from 'prop-types';

export const ConstituentClassificationsPicker = (props) => {
  const {
    keyName,
    classification,
    classifications,
    defaultClassifications,
    onChange,
  } = props;
  const useClassifications = classifications
    ? classifications
    : defaultClassifications;

  return (
    <select
      className="form-control form-control-sm"
      value={classification}
      onChange={onChange}
      style={{ minWidth: '202px' }}
    >
      {useClassifications.length !== 1 && (
        <option key={'all'} value="">
          All
        </option>
      )}
      {useClassifications.map((p, i) => (
        <option key={i} value={p[keyName].value}>
          {p[keyName].title}
        </option>
      ))}
    </select>
  );
};

ConstituentClassificationsPicker.propTypes = {
  universe: PropTypes.string.isRequired,
  keyName: PropTypes.string.isRequired,
  classification: PropTypes.string,
  classifications: PropTypes.array,
  defaultClassifications: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};
