import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Loading } from '../../components/loading';

export const LineChart = ({
  data,
  chartTitle,
  yAxisTitle,
  yAxisKey,
  metricsKey,
  requestCSV,
}) => {
  const formatDataForChart = (objs) =>
    objs.map((point) => {
      const {
        date,
        num_constituents: numOfConstituents,
        market_cap_avg: marketCapAvg,
      } = point;
      const minMaxAvg = [
        parseFloat(point[metricsKey + '_min']),
        parseFloat(point[metricsKey + '_max']),
        parseFloat(point[metricsKey + '_avg']),
      ];

      return {
        x: new Date(date).valueOf(),
        y: parseFloat(point[yAxisKey + '_avg']),
        numOfConstituents,
        numOfNulls: parseInt(point[yAxisKey + '_null_count']),
        minMaxAvg,
        marketCapAvg: marketCapAvg.toFixed(2),
      };
    });

  if (data.filteredData?.length === 0) {
    return <div className="field-required">No data available</div>;
  } else if (!data.filteredData || !data.filteredData?.length) {
    return (
      <div className="row h-100 justify-content-center align-items-center">
        <Loading />
      </div>
    );
  }

  let series = [
    {
      name: yAxisTitle,
      data: formatDataForChart(data.filteredData),
      color: '#005BBB',
    },
  ];

  if (data.benchmarkData || data.benchmarkData?.length) {
    series = [
      ...series,
      {
        name: `${data.universe.toUpperCase()} ${yAxisTitle}`,
        data: formatDataForChart(data.benchmarkData),
        color: '#FFD500',
      },
    ];
  }

  // Custom CSV download function
  Highcharts.getOptions().exporting.menuItemDefinitions.downloadCSV = {
    textKey: 'downloadCSV',
    onclick: requestCSV,
  };

  let chartOptions = {
    title: {
      text: chartTitle,
      margin: 75,
    },
    chart: {
      type: 'line',
      zoomType: 'xy',
      panning: {
        enabled: true,
        type: 'xy',
      },
      panKey: 'shift',
    },
    credits: {
      text: 'Confluence Analytics',
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          symbol: 'download',
          menuItems: ['downloadCSV'],
        },
      },
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
      },
    },
    tooltip: {
      shared: true,
      crosshairs: true,
      formatter: function () {
        if (this.points.length === 1) {
          const { point } = this.points[0];
          const emissions = point.y.toFixed(2);
          const { minMaxAvg, marketCapAvg, numOfConstituents, numOfNulls } =
            point;
          const [min, max, avg] = minMaxAvg;
          const date = new Date(point.x).toLocaleDateString();
          return `
          <b>${emissions} - ${point.series.name}</b><br>
          ${min} - Min, ${max} - Max, ${avg.toFixed(
            2
          )} - Avg (tons of GHG/tCO2e)<br>
          ${
            numOfNulls
              ? `${
                  (numOfNulls / numOfConstituents).toFixed(2) * 100
                }% Constituents Not Calculated (${numOfNulls})<br>`
              : ''
          }
           ${numOfConstituents} Constituents, ${marketCapAvg} - Avg Mkt Cap ($MM)<br>
           ${date} <br>
        `;
        } else {
          const { point } = this.points[0];
          const { point: benchmark } = this.points[1];
          const emissions = point.y.toFixed(2);
          const emissionsBenchmark = benchmark.y.toFixed(2);
          const date = new Date(point.x).toLocaleDateString();
          const { minMaxAvg, marketCapAvg, numOfConstituents, numOfNulls } =
            point;
          const [min, max, avg] = minMaxAvg;
          return `
          <b>${point.series.name}</b><br>
          ${emissions} - Custom Portfolio<br>
          ${min} - Min, ${max} - Max, ${avg.toFixed(
            2
          )} - Avg (tons of GHG/tCO2e)<br>
          ${numOfConstituents} Constituents, ${marketCapAvg} - Avg Mkt Cap ($MM) <br>
          ${
            numOfNulls
              ? `${
                  (numOfNulls / numOfConstituents).toFixed(2) * 100
                }% Constituents Not Calculated (${numOfNulls})<br>`
              : ''
          }
          ${emissionsBenchmark}- Benchmark <br>
          ${date} <br>
        `;
        }
      },
    },
    xAxis: {
      type: 'datetime',
    },
    yAxis: {
      title: {
        text: yAxisTitle,
      },
    },
    series,
  };

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

LineChart.propTypes = {
  data: PropTypes.object.isRequired,
  chartTitle: PropTypes.string.isRequired,
  yAxisTitle: PropTypes.string.isRequired,
  yAxisKey: PropTypes.string.isRequired,
  metricsKey: PropTypes.string.isRequired,
  requestCSV: PropTypes.any.isRequired,
};
