import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

export const DatePickerRaw = (props) => {
  const { date, dates, onChange } = props;
  const [closestDate, setClosestDate] = useState('');
  const selectedTimestamp = moment(date).utc().unix();

  /**
   * Find the closest date to the selected one and set that as selected
   *
   * This fixes a bug that occurs when the selected date is not present
   * in the list of dates returned from the server
   */
  const findClosest = (inDates) => {
    let currentClosestDifference = Infinity,
      currentClosest = {};

    for (let d of inDates) {
      const diff = Math.abs(d.timestamp - selectedTimestamp);
      if (diff < currentClosestDifference) {
        currentClosestDifference = diff;
        currentClosest = d;
      }
    }
    const date = moment.utc(currentClosest.date).format('YYYY-MM-DD');
    if (
      inDates.filter(
        ({ date: d }) => moment.utc(d).format('YYYY-MM-DD') === date
      ).length
    ) {
      setClosestDate(date);
      onChange({ target: { value: date } });
    }
  };

  useEffect(() => {
    if (dates.length) {
      findClosest(dates);
    }
  }, [date, dates]);

  return (
    <select
      className="form-control form-control-sm"
      value={closestDate}
      onChange={onChange}
    >
      {dates.map((p) => {
        const val = moment.utc(p.date).format('YYYY-MM-DD');
        const display = moment.utc(p.date).format('MM/DD/YYYY');
        return (
          <option key={val} value={val}>
            {display}
          </option>
        );
      })}
    </select>
  );
};

DatePickerRaw.propTypes = {
  date: PropTypes.string,
  dates: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};
