import { Form, FormGroup, Input, Label, Table } from 'reactstrap';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const CalculateEmissionsForm = ({ tonsPerDollar, title }) => {
  const [dollarsInvested, setDollarsInvested] = useState(1000000);
  const [tonCost, setTonCost] = useState(5);

  const tonsInPortfolio = ((dollarsInvested / 1000000) * tonsPerDollar).toFixed(
    4
  );
  const maxRatio = 0.002;
  const maxCost = dollarsInvested * maxRatio;
  const maxCoverage = (maxCost / tonCost / tonsInPortfolio) * 100;

  return (
    <>
      <Form>
        <h2>Calculate {title} on Investment</h2>
        <FormGroup>
          <Label for="dollarsInvested">Dollars Invested</Label>
          <Input
            type="number"
            name="dollars"
            id="dollarsInvested"
            value={dollarsInvested}
            min="0"
            placeholder="Input Dollars Invested to Calculate Emissions"
            onChange={(e) => setDollarsInvested(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="tonCost">Offset Cost per Ton of GHG/tCO2e</Label>
          <Input
            type="number"
            name="tons"
            id="tonCost"
            value={tonCost}
            min="0"
            placeholder="Input Cost of Offset to Calculate Emissions"
            onChange={(e) => setTonCost(e.target.value)}
          />
        </FormGroup>
      </Form>
      <Table hover size="sm">
        <thead>
          <tr>
            <th>Coverage of {title}</th>
            <th>Cost ($)</th>
            <th>Cost/Invested (%)</th>
          </tr>
        </thead>
        <tbody>
          {[0.5, 0.75, 1].map((percent, key) => {
            const cost = tonsInPortfolio * percent * tonCost;
            const ratio = cost / dollarsInvested;
            let className = '';
            if (percent === 1 && ratio <= maxRatio) {
              className = 'table-info';
            } else if (ratio > maxRatio) {
              className = 'table-danger';
            }
            return (
              <tr className={className} key={key}>
                <td>{percent * 100}%</td>
                <td>{cost.toFixed(2)}</td>
                <td>{(cost / dollarsInvested).toFixed(5)}</td>
              </tr>
            );
          })}
          <tr className={maxCoverage >= 100 ? 'table-success' : 'table-danger'}>
            <td>{maxCoverage.toFixed(2)} %</td>
            <td>{maxCost.toFixed(2)}</td>
            <td>{maxRatio.toFixed(5)}</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

CalculateEmissionsForm.propTypes = {
  tonsPerDollar: PropTypes.number,
  title: PropTypes.string,
};
