import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { fetchTickers } from '../api';
import { fetchConstituentTickers } from '../api';
import { Combobox } from './combobox';
import { AccountContext } from '../hocs/account-context';

export const AssetPicker = ({ target, type, universe, selected, onSelect }) => {
  let [assets = [], setAssets] = useState();
  const { token } = useContext(AccountContext);

  function loadTickers(query) {
    if (target === 'etf-constituents') {
      fetchConstituentTickers({ universe, query }, token).then(setAssets);
    } else {
      fetchTickers({ type, universe, query }, token).then(setAssets);
    }
  }

  return (
    <Combobox
      selected={selected}
      data={assets}
      valueRenderer={asset => `${asset.name}`}
      onTextChange={loadTickers}
      onSelect={onSelect}
    />
  );
};

AssetPicker.propTypes = {
  target: PropTypes.string,
  type: PropTypes.string.isRequired,
  universe: PropTypes.string.isRequired,
  selected: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};
