import React from 'react';
import PropTypes from 'prop-types';
import { BarChart } from './components/bar-chart';
import { PieChart } from './components/pie-chart';
import { Companies } from './components/companies';
import styled from 'styled-components';

export const ChartContainer = styled.div`
  width: 100%;
  display: flex;
`;
export const HalfWidth = styled.div`
  width: 50%;
`;

export const Chart = ({
  metric,
  data,
  requestPDF,
  requestPNG,
  useRebalanced,
  isLoading,
}) => {
  if (!isLoading && (!data || !data.success)) {
    return <div className="field-required">No data available</div>;
  }

  if (isLoading && (!data || !data.success)) return null;

  return (
    <React.Fragment>
      <BarChart
        metric={metric}
        barChart={data.barChart}
        requestPDF={requestPDF}
        requestPNG={requestPNG}
      />

      <ChartContainer>
        {/*
          PieChart not implemented with useRebalanced
        */}
        {useRebalanced !== 'True' && <PieChart pieChart={data.pieChart} />}
        {useRebalanced === 'True' && <HalfWidth />}
        <Companies companies={data.companies} metric={metric} />
      </ChartContainer>
    </React.Fragment>
  );
};

Chart.propTypes = {
  metric: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  requestPDF: PropTypes.func.isRequired,
  requestPNG: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  useRebalanced: PropTypes.string,
};
