import React from 'react';
import PropTypes from 'prop-types';

export const CoreMetricPicker = (props) => {
  let { metric, metrics, onChange } = props;

  return (
    <select
      className="form-control form-control-sm"
      value={metric}
      onChange={onChange}
    >
      {metrics.map((p) => (
        <option key={p} value={p}>
          {p}
        </option>
      ))}
    </select>
  );
};

CoreMetricPicker.propTypes = {
  metric: PropTypes.string.isRequired,
  metrics: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
};
