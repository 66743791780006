import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useState } from 'react';
import { fetchConstituentMetrics } from '../api';
import { AccountContext } from '../hocs/account-context';

export const ConstituentMetricPicker = props => {
  let { metric, onChange } = props;
  let [metrics = [], setMetrics] = useState();
  const { token } = useContext(AccountContext);

  useEffect(() => {
    fetchConstituentMetrics(props, token).then(metrics => setMetrics(metrics));
  }, []);

  return (
    <select
      className="form-control form-control-sm"
      value={metric}
      onChange={onChange}
    >
      {metrics.map(p => (
        <option key={p} value={p}>
          {p}
        </option>
      ))}
    </select>
  );
};

ConstituentMetricPicker.propTypes = {
  metric: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
