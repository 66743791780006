import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { AccountContext } from '../../hocs/account-context';
import { fetchStockSectors } from '../../api';
import { withQueryProps } from '../../hocs/with-query-props';

import { Chart } from './chart';
import { Form } from './form';

const defaultProps = {
  universe: 'global',
  date: '2019-06-30',
};

const ContainerBase = props => {
  let { universe, date } = props;
  let [chartData = Object.assign({}, props), setChartData] = useState();
  const { token } = useContext(AccountContext);

  useEffect(() => {
    fetchStockSectors(props, token).then(d => {
      setChartData(Object.assign({}, { data: d }, props));
    });
  }, [universe, date]);

  return (
    <React.Fragment>
      <Form {...props} />
      <Chart data={chartData} />
    </React.Fragment>
  );
};

ContainerBase.propTypes = {
  universe: PropTypes.string,
  date: PropTypes.string,
  changeQuery: PropTypes.func,
};

export const Container = withQueryProps(ContainerBase, defaultProps);
