import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

export const MedianPicker = ({ onChange, median }) => {
  return (
    <select
      className="form-control form-control-sm"
      value={median}
      onChange={onChange}
      style={{ minWidth: '202px' }}
    >
      <option value="universe">Universe</option>
      <option value="asset">Asset</option>
      <option value="absolute">Absolute</option>
    </select>
  );
};

MedianPicker.propTypes = {
  median: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
