import React from 'react';
import PropTypes from 'prop-types';
import { UniversePicker } from '../../components/universe-picker';
import { ConstituentMetricPicker } from '../../components/constituent-metric-picker';
import { SizeSlider } from '../../components/size-slider';
import { MedianPicker } from '../../components/median-picker';
import { iconize } from '../../icon-util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { ConstituentClassificationsPicker } from '../../components/constituent-classifications-picker';
import { sectors } from '../../data/gics';
import { AssetPickerV2 } from '../../components/asset-picker-v2';

export const Form = ({
  universe,
  sector,
  asset,
  metric,
  yMetric,
  min,
  max,
  changeQuery,
  quadrant,
  medianMethod,
}) => {
  return (
    <form className="chart-form form-inline mb-2">
      {quadrant != 0 && (
        <div className="form-group mr-2" style={{ alignItems: 'center' }}>
          <label>View all Quadrants</label>
          <Button
            size="sm"
            color="link"
            onClick={() => changeQuery({ quadrant: 0 })}
          >
            <FontAwesomeIcon
              // @ts-ignore
              icon={iconize('reply')}
            />
          </Button>
        </div>
      )}
      <div className="form-group mr-2">
        <label>X Metric</label>
        <ConstituentMetricPicker
          metric={metric}
          onChange={(e) => changeQuery({ metric: e.target.value })}
        />
      </div>
      <div className="form-group mr-2">
        <label>Y Metric</label>
        <ConstituentMetricPicker
          metric={yMetric}
          onChange={(e) => changeQuery({ yMetric: e.target.value })}
        />
      </div>
      <div className="form-group mr-2">
        <label>Median</label>
        <MedianPicker
          median={medianMethod}
          onChange={(e) => changeQuery({ medianMethod: e.target.value })}
        />
      </div>
      <div className="form-group mr-2">
        <label>Weight</label>
        <SizeSlider
          min={min}
          max={max}
          onChange={(v) => {
            let [v0, v1] = v;
            if (min == v0 && max == v1) return;
            if (max == v1) changeQuery({ min: v0 });
            if (min == v0) changeQuery({ max: v1 });
          }}
        />
      </div>
      <div className="form-group mr-2">
        <label>Universe</label>
        <UniversePicker
          selected={universe}
          onChange={(e) => changeQuery({ universe: e.target.value })}
        />
      </div>
      <div className="form-group mr-2">
        <label>Asset</label>
        <AssetPickerV2
          target="etf-constituents"
          type="ETF"
          universe={universe}
          selected={asset}
          onSelect={(asset) => changeQuery({ asset: asset.id })}
        />
      </div>
      <div className="form-group mr-2">
        <label>Sector</label>
        <ConstituentClassificationsPicker
          universe={universe}
          keyName={'sector'}
          classification={sector}
          classifications={sectors}
          defaultClassifications={sectors}
          onChange={(e) => changeQuery({ sector: e.target.value })}
        />
      </div>
    </form>
  );
};

Form.propTypes = {
  universe: PropTypes.string.isRequired,
  sector: PropTypes.string.isRequired,
  asset: PropTypes.string.isRequired,
  metric: PropTypes.string.isRequired,
  yMetric: PropTypes.string.isRequired,
  min: PropTypes.string.isRequired,
  max: PropTypes.string.isRequired,
  changeQuery: PropTypes.func.isRequired,
};
