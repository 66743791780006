import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class Combobox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      value: '',
      timeout: null,
      active: 0,
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.onTextKeyUp = this.onTextKeyUp.bind(this);
    this.onTextKeyDown = this.onTextKeyDown.bind(this);
  }

  render() {
    let { selected, valueRenderer, data } = this.props;

    let { show } = this.state;

    let containerCls = ['combobox-container'];
    if (!show) containerCls.push('hide');

    return (
      <div className="combobox input-group">
        <input
          type="text"
          className="form-control form-control-sm"
          placeholder={selected}
          onChange={this.onTextChange}
          onKeyUp={this.onTextKeyUp}
          onKeyDown={this.onTextKeyDown}
          value={this.state.value}
        />

        <div
          className="input-group-append"
          onClick={this.onCaretClick.bind(this)}
          onKeyUp={this.onTextKeyUp}
          onKeyDown={this.onTextKeyDown}
        >
          <div className="input-group-text">
            <div className="caret"></div>
          </div>
        </div>

        <div className={containerCls.join(' ')}>
          {(() => {
            if (data.length === 0) {
              return <div className="combobox-value">Loading...</div>;
            } else if (data.length === 1 && data[0].id === 'unselectable') {
              return <div className="combobox-value">No Values</div>;
            } else {
              return data.map((item, idx) => {
                let boundClick = this.onSelect.bind(this, item, idx);
                let valueCls = ['combobox-value'];
                if (idx === this.state.active) valueCls.push('active');
                let key = idx;
                return (
                  <div
                    key={key}
                    className={valueCls.join(' ')}
                    onClick={boundClick}
                  >
                    {valueRenderer(item)}
                  </div>
                );
              });
            }
          })()}
        </div>
      </div>
    );
  }

  onTextKeyDown(e) {
    const KEYCODE_ENTER = 13;

    switch (e.keyCode) {
      case KEYCODE_ENTER:
        this.hide();
        this.props.onSelect(
          this.props.data[this.state.active],
          this.state.active
        );
        e.preventDefault();
        break;
    }
  }

  onTextKeyUp(e) {
    const KEYCODE_ESCAPE = 27;
    const KEYCODE_UP = 38;
    const KEYCODE_DOWN = 40;

    switch (e.keyCode) {
      case KEYCODE_ESCAPE:
        this.hide();
        break;
      case KEYCODE_UP:
        {
          let newState = Math.max(0, this.state.active - 1);
          this.setState({ active: newState });
        }
        break;
      case KEYCODE_DOWN:
        {
          let newState = Math.min(
            this.props.data.length - 1,
            this.state.active + 1
          );
          this.setState({ active: newState });
        }
        break;
    }
  }

  show() {
    this.setState({ show: true });
  }

  hide() {
    this.setState({ show: false });
  }

  onCaretClick() {
    if (this.state.show) {
      // close
      this.hide();
    } else {
      // trigger reload
      this.props.onTextChange(this.state.value);
      this.show();
    }
  }

  onTextChange(e) {
    let value = e.target.value;
    let delay = this.props.typingDelay || 500;

    clearTimeout(this.state.timeout);

    let timeout = setTimeout(() => {
      this.props.onTextChange(value);
    }, delay);
    this.setState({ timeout, value, show: true, active: 0 });
  }

  onSelect(item, idx) {
    if (this.props.onSelect) {
      this.props.onSelect(item, idx);
    }
    this.setState({ show: false, value: '', active: 0 });
  }
}

Combobox.propTypes = {
  selected: PropTypes.string,
  valueRenderer: PropTypes.func,
  data: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onTextChange: PropTypes.func.isRequired,
  typingDelay: PropTypes.number,
};
