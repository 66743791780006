import React, { useState, useEffect } from 'react';
import { postCreateSession, fetchAccount, deleteSession } from '../api';

export const AccountContext = React.createContext(null);

export const useAccount = () => {
  const [account, setAccountState] = useState(getAccountLocalStorage());

  // Get token from local storage
  const [token, setTokenState] = useState(getTokenLocalStorage());

  const verifyAccountToken = token => {
    fetchAccount(token).then(res => {
      if (res.success) {
        setAccountState(res.account);
        setAccountLocalStorage(res.account);
      } else {
        setAccountState(null);
        setAccountLocalStorage(null);
        setTokenLocalStorage(null);
        setTokenState(null);
      }
    });
  };

  // Verify token on initial load
  useEffect(() => {
    if (token) verifyAccountToken(token);
  }, []);

  const login = (email, password) => {
    return postCreateSession({ email, password }).then(res => {
      if (res.success) {
        setAccountLocalStorage(res.account);
        setAccountState(res.account);
        setTokenLocalStorage(res.token);
        setTokenState(res.token);

        return true;
      } else {
        return false;
      }
    });
  };

  const logout = () => {
    return deleteSession(token).then(res => {
      setAccountLocalStorage(null);
      setAccountState(null);
      setTokenLocalStorage(null);
      setTokenState(null);
      return true;
    });
  };

  return {
    account,
    token,
    login,
    logout,
  };
};

const LOCALSTORAGE_ACCOUNT_KEY = 'cca:account';
const LOCALSTORAGE_TOKEN_KEY = 'cca:token';

const getAccountLocalStorage = () => {
  let acctString = localStorage.getItem(LOCALSTORAGE_ACCOUNT_KEY);

  // JSON parse handles null better than undefined or empty string
  if (!acctString || acctString.length === 0) acctString = null;

  return JSON.parse(acctString);
};

const setAccountLocalStorage = account => {
  let acctString = JSON.stringify(account);
  localStorage.setItem(LOCALSTORAGE_ACCOUNT_KEY, acctString);
};

const getTokenLocalStorage = () => {
  return localStorage.getItem(LOCALSTORAGE_TOKEN_KEY);
};

const setTokenLocalStorage = token => {
  return localStorage.setItem(LOCALSTORAGE_TOKEN_KEY, token);
};
