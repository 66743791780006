// eslint-disable-next-line no-unused-vars
import React from 'react';
import { Route } from 'react-router-dom';
import { AppNav } from './components/navbar';
import { Footer } from './components/footer';
import { HomeContainer } from './scenes/home/home-container';
import { LoginContainer } from './scenes/account/login/login-container';
import { AccountsContainer } from './scenes/settings/accounts/accounts-container';
import { EditAccountContainer } from './scenes/settings/edit-account/edit-account-container';
import { CreateAccountContainer } from './scenes/settings/create-account/create-account-container';
import { AdministrationContainer } from './scenes/settings/administration/administration-container';
import { Container as EsgChartContainer } from './scenes/esg-chart/container';
import { Container as CarbonChartContainer } from './scenes/carbon-chart/container';
import { Container as EsgAnalysisContainer } from './scenes/esg-analysis/container';
import { Container as StockSectorTreeMapContainer } from './scenes/stock-sector-tree-map/container';
import { Container as StockAssetTreeMapContainer } from './scenes/stock-asset-tree-map/container';
import { Container as EtfConstituentsContainer } from './scenes/etf-constituents/container';
import { Container as EtfCarbonProfileContainer } from './scenes/etf-carbon-profile/container';
import { Container as EtfConstituentQuadrantsContainer } from './scenes/etf-constituents-quadrants/container';
import { Container as DataExportContainer } from './scenes/data-export/container';
import { Container as ESGLabsPlusContainer } from './scenes/labs-plus/container';
import { Container as ESGLabsAnalysisContainer } from './scenes/labs-analysis/container';
import { Container as ESGLabsRankingsContainer } from './scenes/labs-rankings/container';

import { AccountContext, useAccount } from './hocs/account-context';

export const Layout = () => {
  const accountObj = useAccount();
  const account = accountObj.account;
  const isAdmin = account && account.permissions.includes('administrator');

  return (
    <div className="layout">
      <AccountContext.Provider value={accountObj}>
        <AppNav />
        <div className="content container-fluid">
          {!account && <Route path="/" component={LoginContainer} />}

          {account && (
            <React.Fragment>
              <Route exact path="/" component={HomeContainer} />
              <Route exact path="/charts/esg" component={EsgChartContainer} />
              <Route
                exact
                path="/charts/esg-analysis"
                component={EsgAnalysisContainer}
              />
              <Route
                exact
                path="/charts/carbon-analysis"
                component={CarbonChartContainer}
              />
              <Route
                exact
                path="/charts/etf/constituent-quadrants"
                component={EtfConstituentQuadrantsContainer}
              />
              <Route
                exact
                path="/charts/stocks/asset-tree-map"
                component={StockAssetTreeMapContainer}
              />
              <Route
                exact
                path="/charts/etf/constituents"
                component={EtfConstituentsContainer}
              />
              <Route
                exact
                path="/charts/etf/carbon-profile"
                component={EtfCarbonProfileContainer}
              />
              <Route
                exact
                path="/charts/stocks/sector-tree-map"
                component={StockSectorTreeMapContainer}
              />
              <Route
                exact
                path="/charts/data/export"
                component={DataExportContainer}
              />
              <Route
                exact
                path="/charts/labs-plus"
                component={ESGLabsPlusContainer}
              />
              <Route
                exact
                path="/charts/labs-analysis"
                component={ESGLabsAnalysisContainer}
              />
              <Route
                exact
                path="/charts/labs-rankings"
                component={ESGLabsRankingsContainer}
              />
              {isAdmin && (
                <React.Fragment>
                  <Route
                    exact
                    path="/settings/accounts"
                    component={AccountsContainer}
                  />
                  <Route
                    exact
                    path="/settings/account/create"
                    component={CreateAccountContainer}
                  />
                  <Route
                    exact
                    path="/settings/account/edit/:id"
                    component={EditAccountContainer}
                  />
                  <Route
                    exact
                    path="/settings/administration"
                    component={AdministrationContainer}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
        <Footer />
      </AccountContext.Provider>
    </div>
  );
};
