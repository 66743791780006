import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { iconize } from '../icon-util';

export const Icon = ({ name, color = 'white' }) => (
  <FontAwesomeIcon color={color} icon={iconize(name)} />
);

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.any,
};
