// Keys
const percentile_esg_performance = 'ESG Signal 1';
const percentile_dynamic_model_ex_market_sector = 'ESG Signal 2';
const percentile_dynamic_carbon_model_ex_market_sector = 'Carbon Signal 3';

const constituentMetricMap = new Map([
	['Percentile E1', 'percentile_e1'],
	['Percentile E2', 'percentile_e2'],
	['Percentile E3', 'percentile_e3'],
	['Percentile EMP1', 'percentile_emp1'],
	['Percentile EMP2', 'percentile_emp2'],
	['Percentile EMP3', 'percentile_emp3'],
	['Percentile CIT1', 'percentile_cit1'],
	['Percentile CIT2', 'percentile_cit2'],
	['Percentile CIT3', 'percentile_cit3'],
	['Percentile G1', 'percentile_g1'],
	['Percentile G2', 'percentile_g2'],
	['Percentile G3', 'percentile_g3'],
	['Percentile EMP', 'percentile_emp'],
	['Percentile CIT', 'percentile_cit'],
	['Percentile E', 'percentile_e'],
	['Percentile S', 'percentile_s'],
	['Percentile G', 'percentile_g'],
	['Percentile ESG', 'percentile_esg'],
	['Percentile Carbon', 'percentile_carbon'],
	['Percentile Diversity', 'percentile_diversity'],
	['Percentile Controversy', 'percentile_controversy'],
	['Price Momentum', 'percentile_price_mo'],
	['Price Momentum (ST)', 'percentile_price_mo_st'],
	['Price Momentum (LT)', 'percentile_price_mo_lt'],
	['Percentile Carbon Mkt Cap', 'percentile_carbon_mkt_cap'],
	['Percentile Scope3 Mkt Cap', 'percentile_scope3_mkt_cap'],
	[percentile_esg_performance, 'percentile_esg_performance'],
	[percentile_dynamic_model_ex_market_sector, 'percentile_dynamic_model_ex_market_sector'],
	[percentile_dynamic_carbon_model_ex_market_sector, 'percentile_dynamic_carbon_model_ex_market_sector'],
	['Percentile Carbon', 'percentile_carbon'],
	['EV/EBITDA', 'ev_ebitda'],
	['P/E', 'pe'],
	['Free Cash Flow Yield', 'fcfy'],
	['Return on Invested Capital', 'oper_roic'],
	['P/S', 'ps'],
	['Operating Income Growth (12-mo)', 't12m_oper_inc_gr'],
	['Sales Growth (12-mo)', 't12m_sales_gr'],
	['Diluted EPS Growth (12-mo)', 't12m_deps_gr'],
	['1-Mo Price Return', 'm1_pct_return'],
	['3-Mo Price Return', 'm3_pct_return'],
	['6-Mo Price Return', 'm6_pct_return'],
	['YTD Price Return', 'ytd_pct_return'],
	['1-Yr Price Return', 'y1_pct_return'],
	['3-Yr Price Return', 'y3_pct_return'],
]);

const setDynamicConstituentMinMax = new Map([
	['Free Cash Flow Yield', 'fcfy'],
	['Return on Invested Capital', 'oper_roic'],
	['P/S', 'ps'],
	['Operating Income Growth (12-mo)', 't12m_oper_inc_gr'],
	['Sales Growth (12-mo)', 't12m_sales_gr'],
	['Diluted EPS Growth (12-mo)', 't12m_deps_gr'],
	['1-Mo Price Return', 'm1_pct_return'],
	['3-Mo Price Return', 'm3_pct_return'],
	['6-Mo Price Return', 'm6_pct_return'],
	['YTD Price Return', 'ytd_pct_return'],
	['1-Yr Price Return', 'y1_pct_return'],
	['3-Yr Price Return', 'y3_pct_return'],
]);

const constituentMinMax = {
	m1_pct_return: [-100, 100],
	m3_pct_return: [-100, 100],
	m6_pct_return: [-100, 100],
	ytd_pct_return: [-100, 100],
	y1_pct_return: [-100, 500],
	y3_pct_return: [-100, 1000],
	ev_ebitda: [0, 100],
	pe: [0, 100],
	fcfy: [0, 1],
	oper_roic: [0, 1],
	ps: [0, 30],
	t12m_oper_inc_gr: [0, 1],
	t12m_sales_gr: [0, 1],
	t12m_deps_gr: [0, 1],
};

const constituentColorMetricMap = new Map([
	['Percentile E1', 'percentile_e1'],
	['Percentile E2', 'percentile_e2'],
	['Percentile E3', 'percentile_e3'],
	['Percentile EMP1', 'percentile_emp1'],
	['Percentile EMP2', 'percentile_emp2'],
	['Percentile EMP3', 'percentile_emp3'],
	['Percentile CIT1', 'percentile_cit1'],
	['Percentile CIT2', 'percentile_cit2'],
	['Percentile CIT3', 'percentile_cit3'],
	['Percentile G1', 'percentile_g1'],
	['Percentile G2', 'percentile_g2'],
	['Percentile G3', 'percentile_g3'],
	['Percentile EMP', 'percentile_emp'],
	['Percentile CIT', 'percentile_cit'],
	['Percentile E', 'percentile_e'],
	['Percentile S', 'percentile_s'],
	['Percentile G', 'percentile_g'],
	['Percentile ESG', 'percentile_esg'],
	['Percentile Carbon', 'percentile_carbon'],
	['Percentile Diversity', 'percentile_diversity'],
	['Percentile Controversy', 'percentile_controversy'],
	['Stock Performance 1 Month', 'm1_pct_return'],
	['Stock Performance 3 Months', 'm3_pct_return'],
	['Stock Performance 6 Months', 'm6_pct_return'],
	['Stock Performance 1 Year', 'y1_pct_return'],
	['Price Momentum', 'percentile_price_mo'],
	[percentile_esg_performance, 'percentile_esg_performance'],
	[percentile_dynamic_model_ex_market_sector, 'percentile_dynamic_model_ex_market_sector'],
	[percentile_dynamic_carbon_model_ex_market_sector, 'percentile_dynamic_carbon_model_ex_market_sector'],
	['Percentile Carbon Mkt Cap', 'percentile_carbon_mkt_cap'],
	['Percentile Scope3 Mkt Cap', 'percentile_scope3_mkt_cap'],
]);

const constituentMetricMedianVariableMap = new Map([
	['Percentile ESG', 'esg'],
	['Percentile E', 'e'],
	['Percentile S', 's'],
	['Percentile G', 'g'],
	['Percentile E1', 'e1'],
	['Percentile E2', 'e2'],
	['Percentile E3', 'e3'],
	['Percentile EMP1', 'emp1'],
	['Percentile EMP2', 'emp2'],
	['Percentile EMP3', 'emp3'],
	['Percentile CIT1', 'cit1'],
	['Percentile CIT2', 'cit2'],
	['Percentile CIT3', 'cit3'],
	['Percentile G1', 'g1'],
	['Percentile G2', 'g2'],
	['Percentile G3', 'g3'],
	['Percentile EMP', 'emp'],
	['Percentile CIT', 'cit'],
	['Percentile Carbon', 'carbon'],
	['Percentile Diversity', 'diversity'],
	['Percentile Controversy', 'controversy'],
	['Price Momentum', 'price_mo'],
	['Percentile Carbon Mkt Cap', 'percentile_carbon_mkt_cap'],
	['Percentile Scope3 Mkt Cap', 'percentile_scope3_mkt_cap'],
	['Percentile Carbon Mkt Cap', 'percentile_carbon_mkt_cap'],
	['Percentile Scope3 Mkt Cap', 'percentile_scope3_mkt_cap'],
	[percentile_esg_performance, 'esg_performance'],
	[percentile_dynamic_model_ex_market_sector, 'percentile_dynamic_model_ex_market_sector'],
	[percentile_dynamic_carbon_model_ex_market_sector, 'percentile_dynamic_carbon_model_ex_market_sector'],
	['Percentile Carbon', 'percentile_carbon'],
	['Price Momentum (ST)', 'percentile_price_mo_st'],
	['Price Momentum (LT)', 'percentile_price_mo_lt'],
	['EV/EBITDA', 'ev_ebitda'],
	['P/E', 'pe'],
	['Free Cash Flow Yield', 'fcfy'],
	['Return on Invested Capital', 'oper_roic'],
	['P/S', 'ps'],
	['Operating Income Growth (12-mo)', 't12m_oper_inc_gr'],
	['Sales Growth (12-mo)', 't12m_sales_gr'],
	['SaDiluted EPS Growth (12-mo)', 't12m_deps_gr'],
	['1-Mo Price Return', 'm1_pct_return'],
	['3-Mo Price Return', 'm3_pct_return'],
	['6-Mo Price Return', 'm6_pct_return'],
	['YTD Price Return', 'ytd_pct_return'],
	['1-Yr Price Return', 'y1_pct_return'],
	['3-Yr Price Return', 'y3_pct_return'],
]);

const esgAnalysisMetricMap = new Map(constituentMetricMap);
esgAnalysisMetricMap.set(percentile_dynamic_model_ex_market_sector, 'percentile_dynamic_model_ex_market_sector');

const universeMedianUniverseMap = new Map([
	['us', 'us_all'],
	['intl', 'intl_all'],
	['global', 'global_all'],
]);

module.exports = {
	constituentMinMax,
	constituentMetricMap,
	constituentColorMetricMap,
	constituentMetricMedianVariableMap,
	universeMedianUniverseMap,
	esgAnalysisMetricMap,
	setDynamicConstituentMinMax,
};
