import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fetchEsgAnalysisUniverses } from '../api';
import { AccountContext } from '../hocs/account-context';

export const universeMapping = {
  global_all: 'Global All',
  global_large: 'Global Developed',
  global_large_cap: 'Global large cap',
  global_small: 'Global Emerging',
  global_small_cap: 'Global small cap',
  intl_all: 'International All',
  intl_large: 'International Developed',
  intl_large_cap: 'International large cap',
  intl_small: 'International Emerging',
  intl_small_cap: 'International small cap',
  us_all: 'US All',
  us_large: 'US Large Cap',
  us_large_cap: 'US large cap',
  us_mid: 'US Mid Cap',
  us_small: 'US Small Cap',
  us_small_cap: 'US small cap',
};

export const EsgAnalysisUniversePicker = ({ selected, onChange }) => {
  const [universes, setUniverses] = useState([]);
  const { token } = useContext(AccountContext);

  useEffect(() => {
    fetchEsgAnalysisUniverses({}, token).then(({ universes }) => {
      setUniverses(universes);
    });
  }, []);

  return (
    <select
      className="form-control form-control-sm"
      value={selected}
      onChange={onChange}
    >
      {universes.map((u) => {
        if (!universeMapping[u]) return null;
        return (
          <option key={u} value={u}>
            {universeMapping[u]}
          </option>
        );
      })}
    </select>
  );
};

EsgAnalysisUniversePicker.propTypes = {
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
