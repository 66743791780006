import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fetchEsgAnalysisMetrics } from '../api';
import { AccountContext } from '../hocs/account-context';

export const EsgAnalysisMetricPicker = (props) => {
  let { metric, onChange } = props;
  let [metrics = [], setMetrics] = useState();
  const { token } = useContext(AccountContext);

  useEffect(() => {
    fetchEsgAnalysisMetrics({}, token).then((metrics) => {
      setMetrics(metrics);
    });
  }, []);

  return (
    <select
      className="form-control form-control-sm"
      value={metric}
      onChange={onChange}
    >
      {metrics.map((p) => (
        <option key={p} value={p}>
          {p}
        </option>
      ))}
    </select>
  );
};

EsgAnalysisMetricPicker.propTypes = {
  metric: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
