import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export const Chart = ({ data }) => {
  let universe = data.universe;
  let assetName = data.assetName;

  if (!data.esg || !data.esg.length) {
    return <div className="field-required">No data available</div>;
  }

  let chartOptions = {
    title: {
      text: `${assetName} ESG Ratings (${universe.toUpperCase()})`,
    },
    chart: {
      type: 'line',
    },
    credits: {
      enabled: false,
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
      },
    },
    tooltip: {
      shared: true,
      crosshairs: true,
    },
    xAxis: {
      type: 'datetime',
    },
    yAxis: {
      title: {
        text: 'Score',
      },
      max: 1,
    },
    series: [
      {
        name: 'ESG Total',
        data: data.esg,
      },
      {
        name: 'Environmental',
        data: data.e,
        dashStyle: 'ShortDot',
      },
      {
        name: 'Social',
        data: data.s,
        dashStyle: 'ShortDot',
      },
      {
        name: 'Governance',
        data: data.g,
        dashStyle: 'ShortDot',
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

Chart.propTypes = {
  data: PropTypes.object,
  universe: PropTypes.string,
  ticker: PropTypes.string,
  type: PropTypes.string,
};
