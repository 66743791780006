import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, NavbarBrand, Nav, NavItem, NavLink, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { AccountContext } from '../hocs/account-context';

export const AppNav = () => {
  const history = useHistory();
  const { account, logout } = useContext(AccountContext);
  const isAdmin = account && account.permissions.includes('administrator');

  return (
    <Navbar className="main-navbar" dark expand="lg">
      <NavbarBrand tag={Link} to="/" className="mr-auto">
        <img
          alt="Confluence Capital Analytics"
          src="/static/img/confluence_logo_white.png"
        />
      </NavbarBrand>
      {account && (
        <Nav>
          {isAdmin && (
            <React.Fragment>
              <NavItem>
                <NavLink href="/settings/accounts">Accounts</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/settings/administration">
                  Administration
                </NavLink>
              </NavItem>
            </React.Fragment>
          )}
          <NavItem>
            <Button
              color="primary"
              href="#"
              onClick={e => {
                e.preventDefault();
                logout().then(() => history.push('/'));
              }}
            >
              Log out
            </Button>
          </NavItem>
        </Nav>
      )}
    </Navbar>
  );
};
