import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Input,
  ListGroup,
  ListGroupItem,
  ListGroupItemText,
} from 'reactstrap';
import { AccountContext } from '../../hocs/account-context';
import { ToolbarColumn } from '../../components/toolbar-column';
import { DebounceInput } from 'react-debounce-input';
import { fetchESGLabsData } from '../../api';
import { Icon } from '../../components/icon';

export const Sidebar = (props) => {
  const {
    changeQuery,
    onlyIncludeTickers,
    includeTickers,
    excludeTickers,
    excludeSectors,
    isConstituentsOpen,
    isLoading,
  } = props;

  const [constituents, setConstituents] = useState();

  const { token } = useContext(AccountContext);

  useEffect(() => {
    const fetchConstituents = async () => {
      const filterDataParams = {
        ...props,
        type: 'constituents-list',
      };
      const getFilteredData = await fetchESGLabsData(filterDataParams, token);
      setConstituents(getFilteredData);
    };
    if (isConstituentsOpen && !isLoading) fetchConstituents();
  }, [isConstituentsOpen, isLoading]); // when the primary data is loaded, fetchConstituents if open

  const formDivClassName = 'form-group form-group--column mb-3';
  return (
    <ToolbarColumn isLoading={isLoading}>
      <div className={formDivClassName}>
        <label>Include Tickers</label>
        <DebounceInput
          className="form-control form-control-sm"
          type="etf-constituents"
          value={includeTickers}
          minLength={1}
          debounceTimeout={550}
          onChange={(e) => changeQuery({ includeTickers: e.target.value })}
        />
        <label>
          <Input
            type="checkbox"
            name="tickers"
            className="mt-1"
            checked={onlyIncludeTickers === 'true'}
            onChange={(e) =>
              changeQuery({ onlyIncludeTickers: e.target.checked })
            }
          />{' '}
          Only Included Tickers
        </label>
      </div>
      <div className={formDivClassName}>
        <label>Exclude Tickers</label>
        <DebounceInput
          className="form-control form-control-sm"
          disabled={onlyIncludeTickers === 'true'}
          value={excludeTickers}
          minLength={1}
          debounceTimeout={550}
          onChange={(e) => changeQuery({ excludeTickers: e.target.value })}
        />
      </div>
      <div className={formDivClassName}>
        <hr className="col-md-8" />
        <label>Exclude Sectors</label>
        <DebounceInput
          className="form-control form-control-sm"
          value={excludeSectors}
          minLength={1}
          debounceTimeout={550}
          onChange={(e) => changeQuery({ excludeSectors: e.target.value })}
        />
      </div>
      <div className={formDivClassName}>
        <hr className="col-md-8" />
        {constituents &&
          listConstituents({
            constituents,
            excludeTickers,
            excludeSectors,
            changeQuery,
            isLoading,
          })}
      </div>
    </ToolbarColumn>
  );
};

const listConstituents = ({
  constituents,
  excludeTickers,
  excludeSectors,
  changeQuery,
  isLoading,
}) => {
  let previousSector = '';
  return (
    <ListGroup
      flush
      style={{
        maxHeight: '800px',
        overflow: 'auto',
      }}
    >
      {constituents.map((c, i) => {
        const { ticker, company_name: companyName, sector } = c;
        let addSector = false;
        if (previousSector !== sector) {
          previousSector = sector;
          addSector = true;
        }
        return (
          <div key={i}>
            {addSector && (
              <h2 key={sector}>
                {sector}&nbsp;&nbsp;{' '}
                <Button
                  color="danger"
                  style={{
                    fontSize: '0.75rem',
                    borderRadius: '.75rem',
                    background: 'none',
                    border: 'none',
                  }}
                  onClick={() => {
                    if (!isLoading)
                      changeQuery({
                        excludeSectors:
                          excludeSectors === ''
                            ? sector
                            : `${excludeSectors},${sector}`,
                      });
                  }}
                  data-toggle="tooltip"
                  data-placement="top"
                  title={`Remove ${sector}`}
                >
                  <Icon
                    name="trash"
                    color="black"
                    style={{ cursor: 'pointer' }}
                  />
                </Button>
              </h2>
            )}
            <ListGroupItem>
              <ListGroupItemText className="mb-0">
                {ticker} - {companyName}&nbsp;&nbsp;
                <Button
                  color="danger"
                  style={{
                    fontSize: '0.75rem',
                    borderRadius: '.75rem',
                    background: 'none',
                    border: 'none',
                  }}
                  onClick={() => {
                    if (!isLoading)
                      changeQuery({
                        excludeTickers:
                          excludeTickers === ''
                            ? ticker
                            : `${excludeTickers},${ticker}`,
                      });
                  }}
                  data-toggle="tooltip"
                  data-placement="top"
                  title={`Remove ${ticker}`}
                >
                  <Icon
                    name="trash"
                    color="black"
                    style={{ cursor: 'pointer' }}
                  />
                </Button>
              </ListGroupItemText>
            </ListGroupItem>
          </div>
        );
      })}
    </ListGroup>
  );
};

Sidebar.propTypes = {
  isLoading: PropTypes.bool,
  changeQuery: PropTypes.func.isRequired,
  onlyIncludeTickers: PropTypes.string.isRequired,
  includeTickers: PropTypes.string.isRequired,
  excludeTickers: PropTypes.string.isRequired,
  excludeSectors: PropTypes.string.isRequired,
  isConstituentsOpen: PropTypes.bool.isRequired,
};
