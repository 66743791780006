import React from 'react';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Card,
  CardBody,
  Row,
  Col,
  Container,
} from 'reactstrap';
import { PermissionsPicker } from '../../../components/permissions-picker';

export const EditAccount = ({
  account,
  message,
  error,
  email,
  permissions,
  handleUpdateEmail,
  handleUpdatePassword,
  handleUpdatePasswordConfirmation,
  handleSaveEmail,
  handleSavePassword,
  handleChangePermissions,
  handleSavePermissions,
  isInteractionEnabled,
  handleDeleteAccount,
}) => {
  return (
    <Container className="mt-5">
      {error && <Alert color="danger">{error}</Alert>}
      {message && <Alert color="primary">{message}</Alert>}
      <h2>Editing account: {email}</h2>
      <Row className="mb-3">
        <Col>
          <Card>
            <CardBody>
              <Form>
                <h3>Update email...</h3>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    type="email"
                    id="email"
                    value={account.email}
                    onChange={handleUpdateEmail}
                    disabled={!isInteractionEnabled}
                  ></Input>
                </FormGroup>
                <Button
                  onClick={handleSaveEmail}
                  disabled={!isInteractionEnabled}
                >
                  Save email
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <Card>
            <CardBody>
              <Form>
                <h3>Update password...</h3>
                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                    type="password"
                    id="password"
                    value={account.password}
                    onChange={handleUpdatePassword}
                    disabled={!isInteractionEnabled}
                  ></Input>
                </FormGroup>
                <FormGroup>
                  <Label for="password-confirmation">
                    Password confirmation
                  </Label>
                  <Input
                    type="password"
                    id="password-confirmation"
                    value={account.passwordConfirmation}
                    onChange={handleUpdatePasswordConfirmation}
                    disabled={!isInteractionEnabled}
                  ></Input>
                </FormGroup>
                <Button
                  onClick={handleSavePassword}
                  disabled={!isInteractionEnabled}
                >
                  Save password
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <Card>
            <CardBody>
              <Form>
                <h3>Update permissions...</h3>
                <PermissionsPicker
                  handleChangeSelectedPermissions={handleChangePermissions}
                  selectedPermissions={permissions}
                  isInteractionEnabled={isInteractionEnabled}
                />
                <Button
                  onClick={handleSavePermissions}
                  disabled={!isInteractionEnabled}
                >
                  Save permissions
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <Button onClick={handleDeleteAccount} color="danger">
            Delete account
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
