import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Combobox } from './combobox';
import { AccountContext } from '../hocs/account-context';
import { fetchConstituentTickers, fetchTickers } from '../api';

export const AssetPickerV2 = ({
  target,
  type,
  universe,
  selected,
  onSelect,
}) => {
  const [assets, setAssets] = useState([]);
  const [filteredAssets, setFilteredAssets] = useState([]);
  const { token } = useContext(AccountContext);

  const formatAndSetAssets = (tickers) => {
    const distinctTickers = [...new Set(tickers.map(({ id }) => id))];
    const formattedTickers = distinctTickers.map((id) => {
      const fundName = tickers
        .filter((t) => t.id === id)
        .map(({ fundname }) => fundname)
        .join('');
      return {
        id,
        name: id,
        fullName: `${id}${fundName ? ` - ${fundName}` : ''}`,
      };
    });
    setAssets(formattedTickers);
  };

  function loadTickers(query) {
    if (target === 'etf-constituents') {
      fetchConstituentTickers({ universe, query }, token).then(
        formatAndSetAssets
      );
    } else {
      fetchTickers({ type, universe, query }, token).then(formatAndSetAssets);
    }
  }

  // set filtered to all assets
  useEffect(() => {
    setFilteredAssets(assets);
  }, [assets]);

  // initial load tickers and if universe is changed
  useEffect(() => {
    setFilteredAssets([]);
    loadTickers('');
  }, [universe]);

  // filter list based on text
  const onTextChange = useCallback(
    (inputFilterText) => {
      if (assets.length > 0) {
        const reg = new RegExp(inputFilterText.trim(), 'ig');
        const filtered = assets.filter(({ fullName }) => fullName.match(reg));
        setFilteredAssets(
          filtered.length === 0
            ? [
                {
                  id: 'unselectable',
                  fullName: 'No Values',
                },
              ]
            : filtered
        );
      }
    },
    [assets]
  );

  return (
    <Combobox
      selected={selected}
      data={filteredAssets}
      valueRenderer={(asset) => `${asset.fullName}`}
      onTextChange={onTextChange}
      onSelect={onSelect}
    />
  );
};

AssetPickerV2.propTypes = {
  target: PropTypes.string,
  type: PropTypes.string.isRequired,
  universe: PropTypes.string.isRequired,
  selected: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};
