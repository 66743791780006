import React from 'react';
import PropTypes from 'prop-types';

const benchmarkMapping = {
  ivv: 'IVV - iShares Core S&P 500 ETF',
  iwb: 'IWB - iShares Russell 1000 ETF',
  iwv: 'IWV - iShares Russell 3000 ETF',
  efa: 'EFA - iShares MSCI EAFE ETF',
  iev: 'IEV - iShares Europe ETF',
};

export const BenchmarkPicker = ({ selected, onChange }) => {
  return (
    <select
      className="form-control form-control-sm"
      value={selected}
      onChange={onChange}
    >
      {Object.entries(benchmarkMapping).map(([key, value]) => {
        return (
          <option key={key} value={key}>
            {value}
          </option>
        );
      })}
    </select>
  );
};

BenchmarkPicker.propTypes = {
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
