import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { iconize } from '../icon-util';

const LoadingArea = styled.div`
  width: 50px;
  display: flex;
  justify-content: center;
`;

export const Toolbar = ({ isLoading, children }) => {
  return (
    <form className="chart-form form-inline mb-2">
      {children}
      <LoadingArea>
        {isLoading && (
          <FontAwesomeIcon
            // @ts-ignore
            icon={iconize('spinner')}
            pulse
            size="2x"
          />
        )}
      </LoadingArea>
    </form>
  );
};

Toolbar.propTypes = {
  isLoading: PropTypes.bool,
};
