import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { fetchCarbonChartData } from '../../api';
import { AccountContext } from '../../hocs/account-context';

import { withQueryProps } from '../../hocs/with-query-props';

import { Chart } from './chart';
import { Form } from './form';

const defaultProps = {
  type: 'stock',
  universe: 'us',
  asset: 'US0231351067',
  assetName: 'Amazon.com, Inc.',
  start: moment()
    .subtract(1, 'year')
    .format('YYYY-MM-DD'),
  end: moment().format('YYYY-MM-DD'),
};

const ContainerBase = props => {
  let { type, universe, asset, assetName, start, end } = props;
  let [data = { type, universe, asset }, setData] = useState();
  const { token } = useContext(AccountContext);

  useEffect(() => {
    fetchCarbonChartData(props, token)
      .then(objs => {
        let avgs = [],
          c = [],
          cmc = [],
          cs3 = [];
        for (let obj of objs) {
          if (!obj.date) continue;
          let ms = new Date(obj.date).valueOf();
          avgs.push([ms, obj.averageESG]);
          c.push([ms, obj.percentile_carbon]);
          cmc.push([ms.valueOf(), obj.percentile_carbon_mkt_cap]);
          cs3.push([ms.valueOf(), obj.percentile_scope3_mkt_cap]);
        }
        return { avgs, c, cmc, cs3, type, universe, asset, assetName };
      })
      .then(setData);
  }, [type, universe, asset, start, end]);

  return (
    <React.Fragment>
      <Form {...props} />
      <Chart data={data} />
    </React.Fragment>
  );
};

ContainerBase.propTypes = {
  type: PropTypes.string.isRequired,
  universe: PropTypes.string.isRequired,
  asset: PropTypes.string.isRequired,
  assetName: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  changeQuery: PropTypes.func.isRequired,
};

export const Container = withQueryProps(ContainerBase, defaultProps);
