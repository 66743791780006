import React from 'react';
import PropTypes from 'prop-types';
import { UniversePicker } from '../../components/universe-picker';
import { DatePickerAsset } from '../../components/date-picker-asset';
import { SectorPicker } from '../../components/sector-picker';
import { SizeSlider } from '../../components/size-slider';

export const Form = ({
  metric,
  sector,
  universe,
  date,
  min,
  max,
  changeQuery,
}) => {
  return (
    <form className="chart-form form-inline mb-2">
      <div className="form-group mr-2">
        <label>Metric</label>
        <select
          className="form-control form-control-sm"
          value={metric}
          onChange={e => changeQuery({ metric: e.target.value })}
        >
          <option value="ESG Percentile">ESG Percentile</option>
          <option value="ESG Performance">ESG Performance</option>
        </select>
      </div>
      <div className="form-group mr-2">
        <label>Market Cap</label>
        <SizeSlider
          min={min}
          max={max}
          onChange={v => {
            if (v[0] == min && v[1] == max) return;
            if (v[0] == min) changeQuery({ max: v[1] });
            if (v[1] == max) changeQuery({ min: v[0] });
          }}
        />
      </div>
      <div className="form-group mr-2">
        <label>Universe</label>
        <UniversePicker
          selected={universe}
          onChange={e => changeQuery({ universe: e.target.value })}
        />
      </div>
      <div className="form-group mr-2">
        <label>Sector</label>
        <SectorPicker
          universe={universe}
          sector={sector}
          onChange={e => changeQuery({ sector: e.target.value })}
        />
      </div>
      <div className="form-group mr-2">
        <label>Date</label>
        <DatePickerAsset
          type="stock"
          universe={universe}
          sector={sector}
          date={date}
          onChange={e => changeQuery({ date: e.target.value })}
        />
      </div>
    </form>
  );
};

Form.propTypes = {
  metric: PropTypes.string.isRequired,
  universe: PropTypes.string.isRequired,
  sector: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  min: PropTypes.string.isRequired,
  max: PropTypes.string.isRequired,
  changeQuery: PropTypes.func.isRequired,
};
