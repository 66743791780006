import React from 'react';
import PropTypes from 'prop-types';
import { TypePicker } from '../../components/type-picker';
import { UniversePicker } from '../../components/universe-picker';
import { AssetPicker } from '../../components/asset-picker';
import { DatePickerAsset } from '../../components/date-picker-asset';

export const Form = ({
  type,
  universe,
  assetName,
  start,
  end,
  changeQuery,
}) => {
  return (
    <form className="chart-form form-inline mb-2">
      <div className="form-group mr-2">
        <label>Asset Type</label>
        <TypePicker
          selected={type}
          onChange={e =>
            changeQuery({
              type: e.target.value,
              asset: '',
              assetName: '',
            })
          }
        />
      </div>
      <div className="form-group mr-2">
        <label>Universe</label>
        <UniversePicker
          selected={universe}
          onChange={e => changeQuery({ universe: e.target.value })}
        />
      </div>
      <div className="form-group mr-2">
        <label>Asset</label>
        <AssetPicker
          type={type}
          universe={universe}
          selected={assetName}
          onSelect={asset =>
            changeQuery({ asset: asset.id, assetName: asset.name })
          }
        />
      </div>
      <div className="form-group mr-2">
        <label>Start Date</label>
        <DatePickerAsset
          type={type}
          universe={universe}
          date={start}
          onChange={e => changeQuery({ start: e.target.value })}
        />
      </div>
      <div className="form-group mr-2">
        <label>End Date</label>
        <DatePickerAsset
          type={type}
          universe={universe}
          date={end}
          onChange={e => changeQuery({ end: e.target.value })}
        />
      </div>
    </form>
  );
};

Form.propTypes = {
  type: PropTypes.string.isRequired,
  universe: PropTypes.string.isRequired,
  asset: PropTypes.string.isRequired,
  assetName: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  changeQuery: PropTypes.func.isRequired,
};
