import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ConstituentMetricPicker } from '../../components/constituent-metric-picker';
import { ConstituentColorMetricPicker } from '../../components/constituent-color-metric-picker';
import { ETFConstituentsContext } from '../etf-constituents/container';
import { ConstituentClassificationsPicker } from '../../components/constituent-classifications-picker';
import { MedianPickerV2 } from '../../components/median-picker-v2';
import { ConstituentColorByPicker } from '../../components/constituent-color-by-picker';
import { ToolbarColumn } from '../../components/toolbar-column';
import { AssetAnalysisPicker } from '../../components/asset-analysis-picker';
import { fetchBookmarks, fetchConstituentTickers } from '../../api';
import { AccountContext } from '../../hocs/account-context';
import { DatePickerAsset } from '../../components/date-picker-asset';
import { Button, Collapse } from 'reactstrap';
import { Icon } from '../../components/icon';

const joinTypes = [
  {
    name: 'Only in Left',
    value: 'leftOnly',
  },
  {
    name: 'Only in Right',
    value: 'rightOnly',
  },
  {
    name: 'Not shared',
    value: 'notShared',
  },
  {
    name: 'Shared in both',
    value: 'inBoth',
  },
  {
    name: 'Combined',
    value: 'full',
  },
];
export const Form = ({
  universe,
  sector,
  industry_group,
  industry,
  subindustry,
  metric,
  yMetric,
  colorMetric,
  colorBy,
  changeQuery,
  medianMethod,
  isLoading,
  totalConstituents,
  leftAsset,
  rightAsset,
  setChartTitle,
  asOfDate,
  joinType,
}) => {
  const { token } = useContext(AccountContext);
  const [isMetricsOpen, setIsMetricsOpen] = useState(false);
  const toggleMetrics = () => setIsMetricsOpen(!isMetricsOpen);
  const [bookmarksArray, setBookmarksArray] = useState([]);
  const [assetsArray, setAssetsArray] = useState([]);

  const { sectors, industries, subindustries, industryGroups } = useContext(
    ETFConstituentsContext
  );

  const loadBookmarks = async () => {
    const { success, data } = await fetchBookmarks(token);
    if (success) {
      setBookmarksArray(data || []);
    }
  };

  const loadTickers = async () => {
    const tickers = await fetchConstituentTickers(
      { universe, query: '' },
      token
    );

    if (tickers) {
      const distinctTickers = [...new Set(tickers.map(({ id }) => id))];
      const formattedTickers = distinctTickers.map((id) => {
        const fundName = tickers
          .filter((t) => t.id === id)
          .map(({ fundname }) => fundname)
          .join('');
        return {
          id,
          name: id,
          fullName: `${id}${fundName ? ` - ${fundName}` : ''}`,
        };
      });
      setAssetsArray(formattedTickers);
    }
  };

  useEffect(() => {
    loadBookmarks();
    loadTickers();
  }, []);

  useEffect(() => {
    const getAssetName = (asset) => {
      if (asset.split('|')[0] === 'bookmark') {
        return bookmarksArray.filter(
          ({ id }) => `${id}` === asset.split('|')[1]
        )[0]['name'];
      } else if (asset.split('|')[0] === 'etf') {
        return assetsArray.filter(({ id }) => id === asset.split('|')[1])[0][
          'name'
        ];
      }
    };
    const getChartName = (left, right) => {
      let name = '';
      if (joinType === 'leftOnly') {
        name = `In ${left} not in ${right}`;
      } else if (joinType === 'rightOnly') {
        name = `In ${right} not in ${left}`;
      } else if (joinType === 'notShared') {
        name = `Not shared between ${left} and ${right}`;
      } else if (joinType === 'inBoth') {
        name = `Shared between ${left} and ${right}`;
      } else if (joinType === 'full') {
        name = `Combined ${left} and ${right}`;
      }

      return name;
    };
    if (bookmarksArray.length && assetsArray.length) {
      setChartTitle(
        getChartName(getAssetName(leftAsset), getAssetName(rightAsset))
      );
    } else {
      setChartTitle('Loading Chart Title');
    }
  }, [leftAsset, rightAsset, bookmarksArray, assetsArray, joinType]);

  const formDivClassName = 'form-group form-group--column';
  return (
    <ToolbarColumn isLoading={isLoading}>
      <div className="form-group form-group--column">
        <Button
          className="rounded col-md-8 d-flex justify-content-around"
          onClick={toggleMetrics}
          color="info"
          style={{ fontSize: '.80rem' }}
          size={'sm'}
        >
          <div className="col-md-8">Select Assets</div>
          <div className="col-md-4">
            <Icon name={isMetricsOpen ? 'chevron-down' : 'chevron-right'} />
          </div>
        </Button>
      </div>
      <Collapse isOpen={isMetricsOpen}>
        <div className={formDivClassName}>
          <label>
            <b>Left Asset</b>
          </label>
        </div>
        <AssetAnalysisPicker
          bookmarksArray={bookmarksArray}
          assetsArray={assetsArray}
          changeQuery={changeQuery}
          queryKey="leftAsset"
          asset={leftAsset}
        />
        <div className={formDivClassName}>
          <label>
            <b>Right Asset</b>
          </label>
        </div>
        <AssetAnalysisPicker
          bookmarksArray={bookmarksArray}
          assetsArray={assetsArray}
          changeQuery={changeQuery}
          queryKey="rightAsset"
          asset={rightAsset}
        />
        <div className={formDivClassName}>
          <label>
            <b>Compare Assets' Constituents</b>
          </label>
        </div>
        <div className="form-group mr-2">
          <select
            className="form-control form-control-sm"
            value={joinType}
            onChange={(e) => changeQuery({ joinType: e.target.value })}
          >
            {joinTypes.map(({ name, value }) => {
              return (
                <option key={value} value={value}>
                  {name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="form-group mr-2">
          <label>Run Date</label>
          <DatePickerAsset
            type={'etf'}
            universe={universe}
            date={asOfDate}
            onChange={(e) => changeQuery({ asOfDate: e.target.value })}
          />
        </div>
      </Collapse>
      <hr className="col-md-8" />
      <div className={formDivClassName}>
        <label>X Metric</label>
        <ConstituentMetricPicker
          metric={metric}
          onChange={(e) => changeQuery({ metric: e.target.value })}
        />
      </div>
      <div className={formDivClassName}>
        <label>Y Metric</label>
        <ConstituentMetricPicker
          metric={yMetric}
          onChange={(e) => changeQuery({ yMetric: e.target.value })}
        />
      </div>
      <div className={formDivClassName}>
        <label>Color Metric</label>
        <ConstituentColorMetricPicker
          metric={colorMetric}
          onChange={(e) => changeQuery({ colorMetric: e.target.value })}
        />
      </div>
      <div className={formDivClassName}>
        <label>Color By</label>
        <ConstituentColorByPicker
          metric={colorBy}
          onChange={(e) => changeQuery({ colorBy: e.target.value })}
        />
      </div>
      <div className={formDivClassName}>
        <label>Median</label>
        <MedianPickerV2
          median={medianMethod}
          onChange={(e) => changeQuery({ medianMethod: e.target.value })}
        />
      </div>
      <div className={formDivClassName}>
        <label>
          Sector
          {totalConstituents
            ? ` - Total Constituents: ${totalConstituents}`
            : ''}
        </label>
        <ConstituentClassificationsPicker
          universe={universe}
          keyName={'sector'}
          classification={sector}
          classifications={sectors}
          defaultClassifications={[]}
          onChange={(e) => changeQuery({ sector: e.target.value })}
        />
      </div>
      <div className={formDivClassName}>
        <label>Industry Group</label>
        <ConstituentClassificationsPicker
          universe={universe}
          keyName={'industry_group'}
          classification={industry_group}
          classifications={industryGroups}
          defaultClassifications={[]}
          onChange={(e) => changeQuery({ industry_group: e.target.value })}
        />
      </div>
      <div className={formDivClassName}>
        <label>Industry</label>
        <ConstituentClassificationsPicker
          universe={universe}
          keyName={'industry'}
          classification={industry}
          classifications={industries}
          defaultClassifications={[]}
          onChange={(e) => changeQuery({ industry: e.target.value })}
        />
      </div>
      <div className={formDivClassName}>
        <label>Subindustry</label>
        <ConstituentClassificationsPicker
          universe={universe}
          keyName={'subindustry'}
          classification={subindustry}
          classifications={subindustries}
          defaultClassifications={[]}
          onChange={(e) => changeQuery({ subindustry: e.target.value })}
        />
      </div>
    </ToolbarColumn>
  );
};

Form.propTypes = {
  universe: PropTypes.string.isRequired,
  sector: PropTypes.string.isRequired,
  metric: PropTypes.string.isRequired,
  yMetric: PropTypes.string.isRequired,
  changeQuery: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  sectors: PropTypes.any,
  industry: PropTypes.any,
  subindustry: PropTypes.any,
  industry_group: PropTypes.any,
  colorMetric: PropTypes.any,
  colorBy: PropTypes.string,
  medianMethod: PropTypes.any,
  totalConstituents: PropTypes.number,
  leftAsset: PropTypes.string.isRequired,
  rightAsset: PropTypes.string.isRequired,
  setChartTitle: PropTypes.func.isRequired,
  asOfDate: PropTypes.string.isRequired,
  joinType: PropTypes.string.isRequired,
};
