import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AccountContext } from '../hocs/account-context';
import { DatePickerRawV2 } from './date-picker-raw-v2';
import { fetchDates } from '../api';

// for ETF use asset and DatePickerRawV2
export const DatePickerAssetV2 = (props) => {
  const {
    assetType,
    type,
    universe,
    sector,
    date,
    onChange,
    asset,
    setParentDates,
  } = props;
  const [dates = [], setDates] = useState();
  const { token } = useContext(AccountContext);

  useEffect(() => {
    fetchDates({ universe, assetType, asset }, token).then((ds) => {
      setDates(ds);
      if (setParentDates) {
        setParentDates(ds);
      }
    });
  }, [type, universe, sector, assetType, asset, token]);

  return <DatePickerRawV2 date={date} dates={dates} onChange={onChange} />;
};

DatePickerAssetV2.propTypes = {
  type: PropTypes.string.isRequired,
  assetType: PropTypes.string,
  universe: PropTypes.string.isRequired,
  sector: PropTypes.string,
  asset: PropTypes.string,
  date: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  setParentDates: PropTypes.func,
};
