import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { AccountContext } from '../../hocs/account-context';
import { fetchStockAssets } from '../../api';
import { withQueryProps } from '../../hocs/with-query-props';
import { filterData } from '../../filter';

import { Chart } from './chart';
import { Form } from './form';

const defaultProps = {
  universe: 'global',
  sector: 'Communication Services',
  metric: 'ESG Percentile',
  date: moment()
    .subtract(1, 'month')
    .format('YYYY-MM-DD'),
  min: '0',
  max: '100',
};

const ContainerBase = props => {
  let { metric, universe, sector, date, min, max } = props;
  let [chartData, setChartData] = useState(Object.assign({}, props));
  const { token } = useContext(AccountContext);

  // fetch data on change
  useEffect(() => {
    fetchStockAssets(props, token).then(d => {
      let data = filterData(d, min, max);
      setChartData({
        metric,
        universe,
        sector,
        date,
        min,
        max,
        rawData: d,
        data,
      });
    });
  }, [metric, universe, sector, date]);

  // filter if min/max change
  useEffect(() => {
    let data = filterData(chartData.rawData, min, max);
    setChartData(Object.assign({}, chartData, { data }));
  }, [min, max]);

  return (
    <React.Fragment>
      <Form {...props} />
      <Chart data={chartData} />
    </React.Fragment>
  );
};

ContainerBase.propTypes = {
  metric: PropTypes.string,
  universe: PropTypes.string,
  sector: PropTypes.string,
  date: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  changeQuery: PropTypes.func,
};

export const Container = withQueryProps(ContainerBase, defaultProps);
