/* eslint-disable react/prop-types */
import * as React from 'react';
import { useState } from 'react';
import { Handles, Rail, Slider, Ticks, Tracks } from 'react-compound-slider';

// https://react-compound-slider.netlify.app/tooltips

const domain = [0, 100];
export const PercentileSlider = ({ min, max, onChange }) => {
  return (
    <Slider
      onChange={onChange}
      className="slider-2 slider-full"
      domain={domain}
      step={1}
      mode={2}
      values={[min, max]}
    >
      <Rail>
        {(
          { getRailProps } // adding the rail props sets up events on the rail
        ) => <div className="rail" {...getRailProps()} />}
      </Rail>
      <Handles>
        {({ handles, activeHandleID, getHandleProps }) => (
          <div className="slider-handles">
            {handles.map((handle) => (
              <Handle
                key={handle.id}
                handle={handle}
                domain={domain}
                isActive={handle.id === activeHandleID}
                getHandleProps={getHandleProps}
              />
            ))}
          </div>
        )}
      </Handles>
      <Tracks left={false} right={false}>
        {({ tracks, getTrackProps }) => (
          <div className="slider-tracks">
            {tracks.map(({ id, source, target }) => (
              <Track
                key={id}
                source={source}
                target={target}
                getTrackProps={getTrackProps}
              />
            ))}
          </div>
        )}
      </Tracks>

      <Ticks values={[0, 5, 25, 50, 75, 95, 100]}>
        {({ ticks }) => (
          <div className="slider-ticks">
            {ticks.map((tick) => (
              <Tick key={tick.id} tick={tick} count={ticks.length} />
            ))}
          </div>
        )}
      </Ticks>
    </Slider>
  );
};

function Handle({ handle: { id, value, percent }, isActive, getHandleProps }) {
  const [mouseOver, setMouseOver] = useState(false);
  const onMouseEnter = () => {
    setMouseOver(true);
  };

  const onMouseLeave = () => {
    setMouseOver(false);
  };

  return (
    <>
      {mouseOver || isActive ? (
        <div
          style={{
            left: `${percent}%`,
            position: 'absolute',
            marginLeft: '11px',
            marginTop: '-1.5rem',
          }}
        >
          <div className="tooltipV2">
            <span className="tooltiptextV2">Value: {value}</span>
          </div>
        </div>
      ) : null}
      <div
        className="handle"
        style={{ left: `${percent}%` }}
        {...getHandleProps(id, {
          onMouseEnter: onMouseEnter,
          onMouseLeave: onMouseLeave,
        })}
      ></div>
    </>
  );
}

function Track({ source, target, getTrackProps }) {
  return (
    <div
      className="track"
      style={{
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {...getTrackProps()}
    />
  );
}

function Tick({ tick, count }) {
  return (
    <div>
      <div
        className="tick-bar"
        style={{
          left: `${tick.percent}%`,
        }}
      />
      <div
        className="tick-label"
        style={{
          marginLeft: `${-(100 / count) / 2}%`,
          width: `${100 / count}%`,
          left: `${tick.percent}%`,
        }}
      >
        {tick.value == 5 ? '5th' : ''}
        {tick.value == 50 ? '50th' : ''}
        {tick.value == 95 ? '95th' : ''}
      </div>
    </div>
  );
}
