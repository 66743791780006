import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { iconize } from '../icon-util';

export const Loading = () => {
  return (
    <div className="loading">
      <FontAwesomeIcon
        // @ts-ignore
        icon={iconize('spinner')}
        pulse
        size="5x"
      />
    </div>
  );
};
