import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Loading } from '../../components/loading';
import HighchartsMore from 'highcharts/highcharts-more';
import { universeMapping } from '../../components/esg-analysis-universe-picker';

HighchartsMore(Highcharts);

export const Chart = ({ data, requestCSV, requestIndexOneCSV }) => {
  const { monthlyPercentReturn, benchmarkPercentReturn } = data;
  const monthly = monthlyPercentReturn ? monthlyPercentReturn.slice(0, -1) : [];
  // const quarterlyWeighted = quarterlyWeightedPercentReturn
  //   ? quarterlyWeightedPercentReturn.slice(0, -1)
  //   : [];
  const benchmark = benchmarkPercentReturn
    ? benchmarkPercentReturn.slice(0, -1)
    : [];

  const formatDataForChart = (objs) =>
    objs.map(({ date, cumulativeReturn, numConstituents, returnsTitle }) => ({
      x: new Date(date).valueOf(),
      y: cumulativeReturn * 100,
      numConstituents,
      returnsTitle,
    }));

  if (data.monthlyPercentReturn?.length === 0) {
    return <div className="field-required">No data available</div>;
  } else if (!data.monthlyPercentReturn || !data.monthlyPercentReturn?.length) {
    return (
      <div className="mt-5 w-100 justify-content-center align-items-center">
        <Loading />
      </div>
    );
  }

  // Custom CSV download function
  Highcharts.getOptions().exporting.menuItemDefinitions.downloadCSV = {
    textKey: 'downloadCSV',
    onclick: requestCSV,
  };

  const menuItems = ['downloadCSV', 'downloadIndexOneCSV'];

  let chartOptions = {
    title: {
      text: `${universeMapping[data.universe]} - ESG Labs`,
    },
    chart: {
      type: 'line',
      zoomType: 'xy',
      panning: {
        enabled: true,
        type: 'xy',
      },
      panKey: 'shift',
    },
    credits: {
      text: 'Confluence Analytics',
    },
    exporting: {
      enabled: true,
      menuItemDefinitions: {
        // Custom definition
        downloadIndexOneCSV: {
          onclick: () => requestIndexOneCSV(),
          text: 'Download Index-One CSV',
        },
      },
      buttons: {
        contextButton: {
          symbol: 'download',
          menuItems,
        },
      },
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
      },
    },
    tooltip: {
      shared: false,
      crosshairs: true,
      formatter: function () {
        const { point } = this;
        const percent = point.y.toFixed(2);
        const date = new Date(point.x).toLocaleDateString();
        return `
          <b>${point.series.name}</b><br>
          ${percent}% ${
          point.numConstituents ? `- ${point.numConstituents} Constituents` : ''
        }<br>
          ${date}<br>
        `;
      },
    },
    xAxis: {
      type: 'datetime',
    },
    yAxis: {
      title: {
        text: 'Percent Return (%)',
      },
      plotBands: [
        {
          color: '#ffc8c5',
          from: 0,
          to: -200,
        },
      ],
      // max: 100,
    },
    series: [
      {
        name: 'Monthy',
        data: formatDataForChart(monthly),
        color: '#005BBB',
      },
      // {
      //   name: 'Quarterly',
      //   data: formatDataForChart(quarterlyWeighted),
      //   color: '#00BB60',
      // },
      // {
      //   name: 'Yearly',
      //   data: formatDataForChart(yearly),
      //   color: '#bb6000',
      // },
      {
        name: benchmark[0].title || 'Benchmark',
        data: formatDataForChart(benchmark),
        color: '#FFD500',
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

Chart.propTypes = {
  data: PropTypes.object,
  requestCSV: PropTypes.any,
  requestIndexOneCSV: PropTypes.any,
};
