import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Loading } from '../../components/loading';
import HighchartsMore from 'highcharts/highcharts-more';
import { universeMapping } from '../../components/esg-analysis-universe-picker';

HighchartsMore(Highcharts);

export const Chart = ({ data, requestCSV }) => {
  const { filteredData: filtered, benchmarkData: benchmark } = data;
  const filteredData = filtered ? filtered.slice(0, -1) : [];
  const benchmarkData = benchmark ? benchmark.slice(0, -1) : [];

  const formatDataForChart = (objs) =>
    objs.map(
      ({
        date,
        cumulativeReturn,
        num_constituents: numOfConstituents,
        returnsTitle,
      }) => ({
        x: new Date(date).valueOf(),
        y: cumulativeReturn * 100,
        numOfConstituents,
        returnsTitle,
      })
    );

  if (data.filteredData?.length === 0) {
    return <div className="field-required">No data available</div>;
  } else if (
    !data.filteredData ||
    !data.filteredData?.length ||
    !data.benchmarkData ||
    !data.benchmarkData?.length
  ) {
    return (
      <div className="mt-5 w-100 justify-content-center align-items-center">
        <Loading />
      </div>
    );
  }

  // Custom CSV download function
  Highcharts.getOptions().exporting.menuItemDefinitions.downloadCSV = {
    textKey: 'downloadCSV',
    onclick: requestCSV,
  };

  let chartOptions = {
    title: {
      text: `${universeMapping[data.universe]} - ESG Labs`,
    },
    chart: {
      type: 'line',
      zoomType: 'xy',
      panning: {
        enabled: true,
        type: 'xy',
      },
      panKey: 'shift',
    },
    credits: {
      text: 'Confluence Analytics',
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          symbol: 'download',
          menuItems: ['downloadCSV'],
        },
      },
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
      },
    },
    tooltip: {
      shared: true,
      crosshairs: true,
      formatter: function () {
        if (this.points.length === 1) {
          const { point } = this.points[0];
          const percent = point.y.toFixed(2);
          return `
          <b>${point.series.name}</b><br>
          ${percent}% ${
            point.numOfConstituents
              ? `- ${point.numOfConstituents} Constituents`
              : ''
          }<br>
        `;
        }
        const { point } = this.points[0];
        const { point: benchmark } = this.points[1];
        const percent = point.y.toFixed(2);
        const percentBenchmark = benchmark.y.toFixed(2);
        const date = new Date(point.x).toLocaleDateString();
        return `
          <b>${point.series.name}</b><br>
          ${percent}% - Custom Portfolio<br>
          ${point.numOfConstituents} Constituents<br>
          ${percentBenchmark}% - ${benchmark.series.name} <br>
          ${date} <br>
        `;
      },
    },
    xAxis: {
      type: 'datetime',
    },
    yAxis: {
      title: {
        text: 'Percent Return (%)',
      },
      plotBands: [
        {
          color: '#ffc8c5',
          from: 0,
          to: -200,
        },
      ],
      // max: 100,
    },
    series: [
      {
        name: 'Percent Return',
        data: formatDataForChart(filteredData),
        color: '#005BBB',
      },
      {
        name: benchmarkData[0].title || 'Benchmark',
        data: formatDataForChart(benchmarkData),
        // dashStyle: 'LongDash',
        color: '#FFD500',
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

Chart.propTypes = {
  data: PropTypes.object,
  requestCSV: PropTypes.any,
};
