import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { iconize } from '../icon-util';

const LoadingArea = styled.div`
  width: 50px;
  display: flex;
  justify-content: center;
`;

export const ToolbarColumn = ({ isLoading, children }) => {
  return (
    <form className="chart-form mb-2 h-100">
      <LoadingArea>
        {isLoading && (
          <FontAwesomeIcon icon={iconize('spinner')} pulse size="2x" />
        )}
      </LoadingArea>
      {children}
    </form>
  );
};

ToolbarColumn.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.any,
};
