import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export const MiniChart = ({ data, chartTitle, yAxisTitle, yAxisKey }) => {
  const formatDataForChart = (objs) =>
    objs.map((point) => {
      const { date, num_constituents: numOfConstituents } = point;
      return {
        x: new Date(date).valueOf(),
        y: point[yAxisKey] * 100,
        numOfConstituents,
      };
    });

  if (data.filteredData?.length === 0) {
    return <div className="field-required">No data available</div>;
  } else if (
    !data.filteredData ||
    !data.filteredData?.length ||
    !data.benchmarkData ||
    !data.benchmarkData?.length
  ) {
    return null;
  }

  let chartOptions = {
    title: {
      text: null,
    },
    subtitle: {
      text: chartTitle,
    },
    chart: {
      type: 'line',
      zoomType: 'xy',
      panning: {
        enabled: true,
        type: 'xy',
      },
      panKey: 'shift',
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
      },
    },
    tooltip: {
      shared: true,
      crosshairs: true,
      formatter: function () {
        if (this.points.length === 1) {
          const { point } = this.points[0];
          const percent = point.y.toFixed(2);
          return `
          <b>${point.series.name}</b><br>
          ${percent}% - ${point.numOfConstituents} Constituents<br>
        `;
        }

        const { point } = this.points[0];
        const { point: benchmark } = this.points[1];
        const percent = point.y.toFixed(2);
        const percentBenchmark = benchmark.y.toFixed(2);
        const date = new Date(point.x).toLocaleDateString();
        return `
          <b>${point.series.name}</b><br>
          ${percent}% - Custom Portfolio<br>
          ${percentBenchmark}% - Benchmark <br>
          ${date} <br>
        `;
      },
    },
    xAxis: {
      type: 'datetime',
    },
    yAxis: {
      title: {
        text: yAxisTitle,
      },
    },
    series: [
      {
        name: yAxisTitle,
        data: formatDataForChart(data.filteredData),
        color: '#005BBB',
      },
      {
        name: `${data.universe.toUpperCase().replace('_', ' ')} Benchmark`,
        data: formatDataForChart(data.benchmarkData),
        color: '#FFD500',
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

MiniChart.propTypes = {
  data: PropTypes.object.isRequired,
  chartTitle: PropTypes.string.isRequired,
  yAxisTitle: PropTypes.string.isRequired,
  yAxisKey: PropTypes.string.isRequired,
};
