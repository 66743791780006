import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { fetchDates } from '../api';
import { AccountContext } from '../hocs/account-context';
import { DatePickerRaw } from './date-picker-raw';

export const DatePickerAsset = props => {
  const { assetType, type, universe, sector, date, onChange } = props;
  const [dates = [], setDates] = useState();
  const { token } = useContext(AccountContext);

  useEffect(() => {
    fetchDates({ ...props, assetType }, token).then(ds => setDates(ds));
  }, [type, universe, sector, assetType]);

  return <DatePickerRaw date={date} dates={dates} onChange={onChange} />;
};

DatePickerAsset.propTypes = {
  type: PropTypes.string.isRequired,
  assetType: PropTypes.string,
  universe: PropTypes.string.isRequired,
  sector: PropTypes.string,
  date: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
