import { Input } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Combobox } from './combobox';
import { dateToStringInESTWithDay } from '../formatters';

export const AssetAnalysisPicker = ({
  changeQuery,
  queryKey,
  bookmarksArray,
  assetsArray,
  asset,
}) => {
  const [useETF, setUseETF] = useState(false);
  const [selected, setSelected] = useState({ name: '' });
  const [filterBookmarksArray, setFilteredBookmarksArray] = useState([]);
  const [filteredAssets, setFilteredAssets] = useState([]);

  const formDivClassName = 'form-group form-group--column mb-3';

  useEffect(() => {
    if (asset.split('|')[0] === 'etf') {
      setUseETF(true);
    }
  }, []);

  useEffect(() => {
    setFilteredBookmarksArray(bookmarksArray || []);
    if (asset.split('|')[0] === 'bookmark' && bookmarksArray.length) {
      setSelected(
        bookmarksArray.filter(({ id }) => `${id}` === asset.split('|')[1])[0]
      );
    }
  }, [bookmarksArray]);

  useEffect(() => {
    setFilteredAssets(assetsArray || []);
    if (asset.split('|')[0] === 'etf' && assetsArray.length) {
      setSelected(
        assetsArray.filter(({ id }) => id === asset.split('|')[1])[0]
      );
    }
  }, [assetsArray]);

  const onTextChange = (inputFilterText) => {
    const reg = new RegExp(inputFilterText.trim(), 'ig');

    if (bookmarksArray.length > 0 && !useETF) {
      const filtered = bookmarksArray.filter(({ name }) => name.match(reg));
      setFilteredBookmarksArray(
        filtered.length === 0
          ? [
              {
                id: 'unselectable',
                name: 'No Values',
              },
            ]
          : filtered
      );
    } else if (assetsArray.length > 0 && useETF) {
      const filtered = assetsArray.filter(({ name }) => name.match(reg));
      setFilteredAssets(
        filtered.length === 0
          ? [
              {
                id: 'unselectable',
                name: 'No Values',
              },
            ]
          : filtered
      );
    }
  };

  const onSelect = (value) => {
    setSelected(value);
    const queryValue = `${useETF ? 'etf' : 'bookmark'}|${value.id}`;
    changeQuery({ [queryKey]: queryValue });
  };

  return (
    <>
      <div className={'form-check'}>
        <Input
          type="checkbox"
          name="tickers"
          className="mt-2"
          checked={useETF}
          onChange={(e) => setUseETF(e.target.checked)}
        />
        <label className="form-check-label">Select ETF</label>
      </div>
      {!useETF && (
        <>
          <div className={formDivClassName}>
            <label className="form-check-label">Select Bookmark to Load</label>
            <Combobox
              selected={selected.name}
              data={filterBookmarksArray}
              valueRenderer={({ name, created }) =>
                `${name} (${dateToStringInESTWithDay(created)})`
              }
              onTextChange={onTextChange}
              onSelect={onSelect}
            />
          </div>
        </>
      )}
      {useETF && (
        <>
          <div className={formDivClassName}>
            <label className="form-check-label">Select ETF to Load</label>
            <Combobox
              selected={selected.name}
              data={filteredAssets}
              valueRenderer={(asset) => `${asset.fullName}`}
              onTextChange={onTextChange}
              onSelect={onSelect}
            />
          </div>
        </>
      )}
    </>
  );
};

AssetAnalysisPicker.propTypes = {
  changeQuery: PropTypes.func.isRequired,
  queryKey: PropTypes.string.isRequired,
  bookmarksArray: PropTypes.array.isRequired,
  assetsArray: PropTypes.array.isRequired,
  asset: PropTypes.string.isRequired,
};
