/*
sectors
SQL QUERY:
select coalesce(sector, 'No Sector') sector
from (
        select distinct sector
        from reporting.etf_constituents_with_esg_core_global
        order by sector
) a
 */
export const sectors = [
  {
    sector: { name: 'Communication Services', title: 'Communication Services' },
  },
  {
    sector: { name: 'Consumer Discretionary', title: 'Consumer Discretionary' },
  },
  {
    sector: { name: 'Consumer Staples', title: 'Consumer Staples' },
  },
  {
    sector: { name: 'Energy', title: 'Energy' },
  },
  {
    sector: { name: 'Financials', title: 'Financials' },
  },
  {
    sector: { name: 'Health Care', title: 'Health Care' },
  },
  {
    sector: { name: 'Industrials', title: 'Industrials' },
  },
  {
    sector: { name: 'Information Technology', title: 'Information Technology' },
  },
  {
    sector: { name: 'Materials', title: 'Materials' },
  },
  {
    sector: { name: 'Real Estate', title: 'Real Estate' },
  },
  {
    sector: { name: 'Utilities', title: 'Utilities' },
  },
  // {
  //   sector: { name: 'No Sector', title: 'No Sector' },
  // },
];
