import React from 'react';
import PropTypes from 'prop-types';
import { decodeQueryString, encodeQueryString } from '../url-util';

/**
 * Wraps the component with props obtained from the query string
 * @param {any} WrappedComponent
 * @param {object} defaults
 */
export function withQueryProps(WrappedComponent, defaults) {
  const keys = Object.keys(defaults);

  class QueryPropsContainer extends React.Component {
    constructor() {
      super();

      this.state = {
        defaults,
        keys,
        changeQuery: this.changeQuery.bind(this),
      };

      for (let key of keys) {
        this.state[key] = defaults[key] || '';
      }
    }

    /**
     * Static function that maps props into state values. We pluck out the
     * search parameters from the query string and inject them as new state
     * here. This corresponds to the componentDidUpdate method which checks
     * for changes to the URL to perform new data fetching.
     */
    static getDerivedStateFromProps(props, state) {
      let vals = decodeQueryString(props.location.search);
      return Object.assign({}, state, vals);
    }

    /**
     * Changes the query string to include the corresponding values
     * interacts with the URL history API. Changes are picked up in the
     * lifecycle methods.
     * @param {object} values
     */
    changeQuery(values) {
      // construct key-value for all keys in querystring
      let params = {};
      for (let key of keys) {
        params[key] = this.state[key];
      }

      // set the latest value for the specific key
      params = Object.assign(params, values);

      // construct url
      let pathname = this.props.location.pathname;
      let search = encodeQueryString(params);
      let path = `${pathname}?${search}`;

      // push onto history instead of directly changing state
      this.props.history.push(path);
    }

    render() {
      return <WrappedComponent {...this.state} {...this.props} />;
    }
  }

  QueryPropsContainer.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  return QueryPropsContainer;
}
