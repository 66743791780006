/* eslint-disable react/prop-types */
import * as React from 'react';
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';

export const SizeSlider = ({ min, max, onChange }) => {
  return (
    <Slider
      onChange={onChange}
      className="slider"
      domain={[0, 100]}
      step={1}
      mode={2}
      values={[min, max]}
    >
      <Rail>
        {(
          { getRailProps } // adding the rail props sets up events on the rail
        ) => <div className="rail" {...getRailProps()} />}
      </Rail>
      <Handles>
        {({ handles, getHandleProps }) => (
          <div className="slider-handles">
            {handles.map(handle => (
              <Handle
                key={handle.id}
                handle={handle}
                getHandleProps={getHandleProps}
              />
            ))}
          </div>
        )}
      </Handles>
      <Tracks left={false} right={false}>
        {({ tracks, getTrackProps }) => (
          <div className="slider-tracks">
            {tracks.map(({ id, source, target }) => (
              <Track
                key={id}
                source={source}
                target={target}
                getTrackProps={getTrackProps}
              />
            ))}
          </div>
        )}
      </Tracks>

      <Ticks values={[0, 25, 50, 75, 100]}>
        {({ ticks }) => (
          <div className="slider-ticks">
            {ticks.map(tick => (
              <Tick key={tick.id} tick={tick} count={ticks.length} />
            ))}
          </div>
        )}
      </Ticks>
    </Slider>
  );
};

function Handle({ handle: { id, percent }, getHandleProps }) {
  return (
    <div
      className="handle"
      style={{ left: `${percent}%` }}
      {...getHandleProps(id)}
    ></div>
  );
}

function Track({ source, target, getTrackProps }) {
  return (
    <div
      className="track"
      style={{
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {...getTrackProps()}
    />
  );
}

function Tick({ tick, count }) {
  return (
    <div>
      <div
        className="tick-bar"
        style={{
          left: `${tick.percent}%`,
        }}
      />
      <div
        className="tick-label"
        style={{
          marginLeft: `${-(100 / count) / 2}%`,
          width: `${100 / count}%`,
          left: `${tick.percent}%`,
        }}
      >
        {tick.value == 0 ? 'S' : ''}
        {tick.value == 50 ? 'M' : ''}
        {tick.value == 100 ? 'L' : ''}
      </div>
    </div>
  );
}
