import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

export const PermissionsPicker = ({
  selectedPermissions,
  handleChangeSelectedPermissions,
  isInteractionEnabled = true,
}) => {
  // prettier-ignore
  const permissions = [
    {name: '', value: ''},
    {name: 'Administrator', value: 'administrator'},
    {name: 'Chart: ESG Ratings', value: 'chart:esg-ratings'},
    {name: 'Chart: Carbon Analysis', value: 'chart:carbon-analysis'},
    {name: 'Chart: ETF Constituent Quadrants', value: 'chart:etf-constituent-quadrants'},
    {name: 'Chart: Stock Sector Asset Treemap', value: 'chart:stock-sector-asset-treemap'},
    {name: 'Chart: Stock Sector Treemap', value: 'chart:stock-sector-treemap'},
    {name: 'Chart: ETF Constituents', value: 'chart:etf-constituents'},
    {name: 'Chart: ESG Analysis', value: 'chart:esg-analysis'},
    {name: 'Chart: Raw Data Export', value: 'chart:raw-data-export'},
    {name: 'Universe: Global', value: 'universe:global'},
    {name: 'Universe: US', value: 'universe:us'},
    {name: 'Universe: International', value: 'universe:intl'},
    {name: 'ESG Labs', value: 'feat:labs'},
    {name: 'Chart: ESG Analysis', value: 'feat:analysis'},
  ];

  return (
    <FormGroup>
      <Label for="permissions">Permissions</Label>
      <Input
        type="select"
        id="permissions"
        disabled={!isInteractionEnabled}
        onChange={handleChangeSelectedPermissions}
        value={selectedPermissions}
        style={{ height: '10rem' }}
        multiple
      >
        {permissions.map((p) => {
          return (
            <option key={p.value} value={p.value}>
              {p.name}
            </option>
          );
        })}
      </Input>
    </FormGroup>
  );
};
