import React from 'react';
import PropTypes from 'prop-types';
import { Toolbar } from '../../components/toolbar';
import { EsgAnalysisUniversePicker } from '../../components/esg-analysis-universe-picker';
import { EsgAnalysisMetricPicker } from '../../components/esg-analysis-metric-picker';
import { DatePickerAsset } from '../../components/date-picker-asset';

export const Form = ({
  universe,
  metric,
  date,
  changeQuery,
  isLoading,
  useRebalanced,
}) => {
  return (
    <Toolbar isLoading={isLoading}>
      <div className="form-group mr-2">
        <label>Metric</label>
        <EsgAnalysisMetricPicker
          metric={metric}
          onChange={(e) => changeQuery({ metric: e.target.value })}
        />
      </div>
      <div className="form-group mr-2">
        <label>Universe</label>
        <EsgAnalysisUniversePicker
          selected={universe}
          onChange={(e) => changeQuery({ universe: e.target.value })}
        />
      </div>
      {/* DatePickerAsset not implemented with useRebalanced */}
      {useRebalanced !== 'True' && (
        <div className="form-group mr-2">
          <label>Date</label>
          <DatePickerAsset
            type="stock"
            universe={universe}
            date={date}
            assetType="esg"
            onChange={(e) => changeQuery({ date: e.target.value })}
          />
        </div>
      )}
      <div className="form-group mr-2">
        <label>Use Labs</label>
        <select
          className="form-control form-control-sm"
          value={useRebalanced}
          onChange={(e) => changeQuery({ useRebalanced: e.target.value })}
        >
          {['False', 'True'].map((p) => (
            <option key={p} value={p}>
              {p}
            </option>
          ))}
        </select>
      </div>
    </Toolbar>
  );
};

Form.propTypes = {
  universe: PropTypes.string.isRequired,
  metric: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  changeQuery: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  useRebalanced: PropTypes.string,
};
