import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsTreemap from 'highcharts/modules/treemap';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import { toBillions } from '../../formatters';

HighchartsTreemap(Highcharts);
HighchartsHeatmap(Highcharts);

export class Chart extends React.PureComponent {
  render() {
    let { data } = this.props;
    let chartOptions = {
      title: {
        text: 'Stock Sectors',
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        buttons: {
          contextButton: {
            enabled: false,
          },
        },
      },
      colorAxis: {
        minColor: '#00467f',
        maxColor: '#FFFF00',
      },
      tooltip: {
        formatter() {
          let name = this.point.name;
          let marketCap = toBillions(this.point.value);
          return `
            <b>${name}</b>
            <br/>Market Cap: <b>${marketCap}</b>
          `;
        },
      },
      series: [
        {
          type: 'treemap',
          layoutAlgorithm: 'squarified',
          animation: {
            duration: 0,
          },
          data:
            data.data &&
            data.data.map((p, index) => ({
              name: p.sector,
              value: parseFloat(p.market_cap),
              colorValue: index,
            })),
        },
      ],
    };

    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        immutable={true}
      />
    );
  }
}

Chart.propTypes = {
  data: PropTypes.object,
};
