import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { saveAs } from 'file-saver';

import { fetchEsgChartData, fetchExportEsgAnalysisData } from '../../api';
import { AccountContext } from '../../hocs/account-context';
import { withQueryProps } from '../../hocs/with-query-props';
import { currentDatetimeAsFilename } from '../../formatters';
import { Form } from './form';
import { Chart } from './chart';

const defaultProps = {
  metric: 'Percentile ESG',
  universe: 'us_large',
  date: moment().format('YYYY-MM-DD'),
  useRebalanced: 'False',
};

const ContainerBase = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const { token } = useContext(AccountContext);
  let { metric, universe, date, useRebalanced } = props;

  useEffect(() => {
    let cancelled = false;
    setIsLoading(true);
    fetchEsgChartData(
      { type: 'esg-analysis', universe, metric, date, useRebalanced },
      token
    ).then((res) => {
      if (!cancelled) {
        setData(res);
        setIsLoading(false);
      }
    });
    return () => (cancelled = true);
  }, [metric, universe, date, useRebalanced]);

  const requestPDF = () => {
    fetchExportEsgAnalysisData(
      { universe, metric, date, format: 'pdf' },
      token
    ).then((res) => {
      saveAs(res, `esg_analysis_${currentDatetimeAsFilename()}.pdf`);
    });
  };

  const requestPNG = () => {};

  return (
    <React.Fragment>
      <Form {...props} isLoading={isLoading} />
      <Chart
        data={data}
        isLoading={isLoading}
        metric={metric}
        useRebalanced={useRebalanced}
        requestPDF={requestPDF}
        requestPNG={requestPNG}
      />
    </React.Fragment>
  );
};

ContainerBase.propTypes = {
  universe: PropTypes.string.isRequired,
  dataSet: PropTypes.string.isRequired,
  changeQuery: PropTypes.func.isRequired,
  metric: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  useRebalanced: PropTypes.string,
};

export const Container = withQueryProps(ContainerBase, defaultProps);
