import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// This is just removing that automatic date picker
export const DatePickerRawV2 = (props) => {
  const { date, dates, onChange } = props;

  return (
    <select
      className="form-control form-control-sm"
      value={date}
      onChange={onChange}
    >
      {dates.map((p) => {
        const val = moment.utc(p.date).format('YYYY-MM-DD');
        const display = moment.utc(p.date).format('MM/DD/YYYY');
        return (
          <option key={val} value={val}>
            {display}
          </option>
        );
      })}
    </select>
  );
};

DatePickerRawV2.propTypes = {
  date: PropTypes.string,
  dates: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  useFindClosest: PropTypes.bool,
};
