import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Col, Container as ReactStrapContainer, Row } from 'reactstrap';
import PropTypes from 'prop-types';

import { withQueryProps } from '../../hocs/with-query-props';
import { AccountContext } from '../../hocs/account-context';
import { LineChart } from '../labs-carbon-profile/line-chart';
import { CalculateEmissionsForm } from '../labs-carbon-profile/calculate-emissions-form';
import { Loading } from '../../components/loading';
import { fetchEtfMetrics, fetchExportEtfMetrics } from '../../api';
import { PieChart } from '../labs-carbon-profile/pie-chart';
import { currentDatetimeAsFilename, dateToStringInEST } from '../../formatters';
import { saveAs } from 'file-saver';
import { DatePickerAssetV2 } from '../../components/date-picker-asset-v2';
import { Toolbar } from '../../components/toolbar';

export const defaultProps = {
  universe: 'us',
  asset: 'IWB',
  startDate: '2018-01-31',
};

export const ContainerBase = (props) => {
  const { universe, asset, changeQuery, startDate } = props;
  const [lastProdMonth, setLastProdMonth] = useState('');
  const [runStartDate, setStartDate] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [lineChartData = { universe }, setLineChartData] = useState();
  const [pieChartData, setPieChartData] = useState();
  const { token } = useContext(AccountContext);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const etfLineChartParams = { ...props, type: 'emissions-line-chart' };
    const getEtfLineChartData = fetchEtfMetrics(etfLineChartParams, token);

    const etfPieChartParams = { ...props, type: 'emissions-pie-chart' };
    const getEtfPieChartData = fetchEtfMetrics(etfPieChartParams, token);

    const [etfLineCharData, etfPieChartData] = await Promise.all([
      getEtfLineChartData,
      getEtfPieChartData,
    ]);

    const { date } = etfLineCharData[etfLineCharData.length - 1];
    if (date) {
      setLastProdMonth(` - ${dateToStringInEST(date)}`);
      setStartDate(dateToStringInEST(etfLineCharData[0].date));
    }

    setPieChartData(etfPieChartData);
    setLineChartData({ filteredData: etfLineCharData, universe });
    setIsLoading(false);
  }, [props, token, universe, asset]);

  useEffect(() => {
    fetchData();
  }, [startDate]);

  const requestCSV = async () => {
    setIsLoading(true);
    const res = await fetchExportEtfMetrics(
      {
        universe,
        asset,
        type: 'climate',
        startDate,
      },
      token
    );
    const date = currentDatetimeAsFilename();
    saveAs(res, `${asset}_climate_metrics_${date}.csv`);
    setIsLoading(false);
  };

  return (
    <ReactStrapContainer fluid>
      <Row>
        <Col>
          <Toolbar isLoading={isLoading}>
            <div className="form-group mr-2 w-50 mx-auto">
              <label>Start Date</label>
              <DatePickerAssetV2
                type="etf-constituents"
                asset={asset}
                universe={universe}
                date={startDate}
                assetType="etf-constituents"
                onChange={(e) => changeQuery({ startDate: e.target.value })}
              />
            </div>
          </Toolbar>
          {isLoading ? (
            <div className="mt-5 w-100 justify-content-center align-items-center">
              <Loading />
            </div>
          ) : (
            <>
              <Row>
                <Col xs="12">
                  <h2 className="text-center mb-2 mt-2">
                    Total Emissions Analysis for {asset} since {runStartDate}
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col xs="8">
                  <LineChart
                    data={lineChartData}
                    chartTitle={
                      'Weighted Climate Emissions Intensity (tons of GHG/tCO2e per $MM Invested)'
                    }
                    yAxisTitle={'Weighted Climate Emissions Intensity'}
                    yAxisKey={'total_emissions_per_mkt_cap'}
                    metricsKey={'climate_total_emissions'}
                    requestCSV={requestCSV}
                  />
                </Col>
                <Col xs="4">
                  {!isLoading && (
                    <CalculateEmissionsForm
                      tonsPerDollar={
                        lineChartData?.filteredData[
                          lineChartData.filteredData.length - 1
                        ]['total_emissions_per_mkt_cap_avg']
                      }
                      title={'Total Emissions'}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <h2 className="text-center mb-2 mt-2">
                    Total Emissions {lastProdMonth}
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <PieChart
                    data={pieChartData}
                    chartTitle={'Percent Total Emissions Per Sector'}
                    yAxisTitle={'(Scope 1 and 2) Emissions'}
                    yAxisKey={'climate_total_emissions'}
                  />
                </Col>
                <Col xs="6">
                  <PieChart
                    data={pieChartData}
                    chartTitle={'Percent Allocation Per Sector'}
                    yAxisTitle={'Allocations'}
                    yAxisKey={'num_constituents_per_sector'}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <h2 className="text-center mb-2 mt-2">
                    Scope 3 Emissions Analysis for {asset} Since {runStartDate}
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col xs="8">
                  <LineChart
                    data={lineChartData}
                    chartTitle={
                      'Weighted Scope3 Climate Emissions Intensity (tons of GHG/tCO2e per $MM Invested)'
                    }
                    yAxisTitle={'Weighted Scope3 Climate Emissions Intensity'}
                    yAxisKey={'total_scope3_emissions_per_mkt_cap'}
                    metricsKey={'climate_scope3_emissions'}
                    requestCSV={requestCSV}
                  />
                </Col>
                <Col xs="4">
                  {!isLoading && (
                    <CalculateEmissionsForm
                      tonsPerDollar={
                        lineChartData?.filteredData[
                          lineChartData.filteredData.length - 1
                        ]['total_scope3_emissions_per_mkt_cap_avg']
                      }
                      title={'Scope3 Emissions'}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <h2 className="text-center mb-2 mt-2">
                    Scope 3 Emissions {lastProdMonth}
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <PieChart
                    data={pieChartData}
                    chartTitle={'Percent Scope 3 Emissions Per Sector'}
                    yAxisTitle={'(Scope 3) Emissions'}
                    yAxisKey={'climate_scope3_emissions'}
                  />
                </Col>
                <Col xs="6">
                  <PieChart
                    data={pieChartData}
                    chartTitle={'Percent Allocation Per Sector'}
                    yAxisTitle={'Allocations'}
                    yAxisKey={'num_constituents_per_sector'}
                  />
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </ReactStrapContainer>
  );
};

ContainerBase.propTypes = {
  universe: PropTypes.string.isRequired,
  asset: PropTypes.string.isRequired,
  changeQuery: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
};

export const Container = withQueryProps(ContainerBase, defaultProps);
