import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { fetchLastProductionMonth } from '../api';
import { AccountContext } from '../hocs/account-context';

export const Footer = ({}) => {
  const [lastProductionMonth, setLastProductionMonth] = useState(null);
  const { token, account } = useContext(AccountContext);

  useEffect(() => {
    let cancelled = false;
    if (account) {
      fetchLastProductionMonth(token).then(res => {
        if (res.success && !cancelled) {
          setLastProductionMonth(res.lastProductionMonth);
        }
      });
    }
    return () => (cancelled = true);
  }, []);

  return (
    <footer className="footer">
      <div>Copyright &copy; {new Date().getFullYear()}</div>
      {lastProductionMonth && (
        <div>Current production month: {lastProductionMonth}</div>
      )}
    </footer>
  );
};

Footer.propTypes = {};
