import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AccountContext } from '../../hocs/account-context';
import { Icon } from '../../components/icon';

export const HomeContainer = () => {
  const { account } = useContext(AccountContext);
  let sections = [
    {
      url: '/charts/etf/constituents',
      title: 'ETF Constituents',
      key: 'chart:etf-constituents',
      desc: 'Constituent assets of an ETF sized by weight and positioned by alpha and an ESG metric.',
      img: '/static/img/charts/etf-constituents.png',
    },
    {
      url: '/charts/esg-analysis',
      title: 'ESG Analysis',
      key: 'chart:esg-analysis',
      desc: 'Analyze ESG returns over set periods of time',
      img: '/static/img/charts/esg-analysis.png',
    },
    {
      url: '/charts/esg',
      title: 'ESG Ratings',
      key: 'chart:esg-ratings',
      desc: 'Environmental, Social, and Governance Ratings for Stocks and ETFs displaying each component and the Total ESG score over time.',
      img: '/static/img/charts/esg-ratings.png',
    },
    {
      url: '/charts/carbon-analysis',
      title: 'Carbon Analysis',
      key: 'chart:carbon-analysis',
      desc: 'Carbon stock analysis',
      img: '/static/img/charts/esg-ratings.png',
    },
    {
      url: '/charts/etf/constituent-quadrants',
      title: 'ETF Constituent Quadrants',
      key: 'chart:etf-constituent-quadrants',
      desc: 'Constituent assets of an ETF organized by performance quadrants.',
      img: '/static/img/charts/etf-constituent-quadrants.png',
    },
    {
      url: '/charts/stocks/asset-tree-map',
      title: 'Stock Sector Asset Tree Map',
      key: 'chart:stock-sector-asset-treemap',
      desc: 'Displays all stocks in a sector as a tree map where companies are sized by market cap.',
      img: '/static/img/charts/stock-assets.png',
    },
    {
      url: '/charts/stocks/sector-tree-map',
      title: 'Stock Sector Tree Map',
      key: 'chart:stock-sector-treemap',
      desc: 'Displays all stock sectors in the universe as a tree map where sectors are sized by market cap.',
      img: '/static/img/charts/stock-sectors.png',
    },
    {
      url: '/charts/data/export',
      title: 'Data Export',
      key: 'chart:raw-data-export',
      desc: 'Download raw ETF and ESG data',
      img: '/static/img/charts/data-export.png',
    },
  ];

  return (
    <div className="container mt-5">
      <div className="row mb-3">
        {account.permissions.includes('feat:labs') && (
          <>
            <div className="col-md-6 mb-5">
              <Link to="/charts/labs-plus" style={{ textDecoration: 'none' }}>
                <h4>
                  <Icon name="flask" color="#000000" />
                  &nbsp;&nbsp;Labs
                </h4>
              </Link>
            </div>
            {account.permissions.includes('feat:analysis') && (
              <>
                <div className="col-md-6 mb-5">
                  <Link
                    to="/charts/labs-analysis"
                    style={{ textDecoration: 'none' }}
                  >
                    <h4>
                      <Icon name="chart-pie" color="#000000" />
                      &nbsp;&nbsp;Labs Analysis
                    </h4>
                  </Link>
                </div>
                <div className="col-md-6 mb-5">
                  <Link
                    to="/charts/labs-rankings"
                    style={{ textDecoration: 'none' }}
                  >
                    <h4>
                      <Icon name="file-csv" color="#000000" />
                      &nbsp;&nbsp;Universe Metric Analysis
                    </h4>
                  </Link>
                </div>
              </>
            )}
            <hr className="col-md-8 mb-5" />
          </>
        )}
        {sections.map((section, i) => {
          if (!account.permissions.includes(section.key)) return null;
          return (
            <React.Fragment key={section.key}>
              {i % 4 === 0 && i !== 0 && <hr className="col-md-8 mb-5" />}
              <div key={section.key} className="col-md-6 mb-5">
                <Link to={section.url}>
                  <h4>{section.title}</h4>
                </Link>
                {section.img && (
                  <div>
                    <Link to={section.url}>
                      <img
                        className="img-thumbnail"
                        src={section.img}
                        alt={section.title}
                      />
                    </Link>
                  </div>
                )}
                <p className="mt-3 small text-muted">{section.desc}</p>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
