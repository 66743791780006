import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { HalfWidth } from '../chart';

export const PieChart = ({ pieChart }) => {
  const zerothQuartile = pieChart[0];
  const firstQuartile = pieChart[1];
  const secondQuartile = pieChart[2];
  const thirdQuartile = pieChart[3];

  let chartOptions = {
    title: {
      text: 'Market Cap %',
    },
    chart: {
      type: 'pie',
      plotShadow: false,
      plotBackgroundColor: null,
      plotBorderWidth: null,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
    tooltip: {
      shared: true,
      crosshairs: true,
      formatter: function () {
        let point = this.point;
        return `
          <div style="padding-bottom: 5px; width: 175px; white-space: normal">
            <div style="font-weight: bold;">${point.title} assets</div>
          </div>
          <br />
          <table>
            <tr>
              <th style="padding-right: 1.5rem;">Market Cap:</th>
              <td style="text-align: right">${point.name}</td>
            </tr>
          </table>
        `; // prettier-ignore
      },
    },
    xAxis: {
      categories: ['1 Month', '3 Months', '6 Months', 'Year to Date', '1 Year'],
    },
    series: [
      {
        name: '',
        innerSize: '50%',
        data: [
          {
            name: `${thirdQuartile}%`,
            title: 'Top 25%',
            color: '#4BAE56',
            y: thirdQuartile,
          },
          {
            name: `${secondQuartile}%`,
            title: '50-75%',
            color: '#B9CE4B',
            y: secondQuartile,
          },
          {
            name: `${firstQuartile}%`,
            title: '25-50%',
            color: '#F1A552',
            y: firstQuartile,
          },
          {
            name: `${zerothQuartile}%`,
            title: 'Bottom 25%',
            color: '#EB6969',
            y: zerothQuartile,
          },
        ],
      },
    ],
  };

  return (
    <HalfWidth>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </HalfWidth>
  );
};

PieChart.propTypes = {
  pieChart: PropTypes.array.isRequired,
};
