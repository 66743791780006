import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PercentileSlider } from '../../components/percentile-slider';
import {
  Button,
  Col,
  Collapse,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import { ConstituentClassificationsPicker } from '../../components/constituent-classifications-picker';
import { sectors } from '../../data/gics';
import { DatePickerAssetV2 } from '../../components/date-picker-asset-v2';
import { fetchConstituentMetrics } from '../../api';
import { AccountContext } from '../../hocs/account-context';
import { CoreMetricPicker } from '../../components/core-metric-picker';
import { ToolbarColumn } from '../../components/toolbar-column';
import classnames from 'classnames';
import { Icon } from '../../components/icon';
import { LabsUniversePicker } from '../../components/labs-universe-picker';
import { AssetPickerV2 } from '../../components/asset-picker-v2';
import { Bookmarks } from './bookmarks';

const min = (str) => str.split(',')[0];
const max = (str) => str.split(',')[1];

export const Form = ({
  universe,
  useEtf,
  asset,
  sectorCore,
  pEnv,
  pSoc,
  pGov,
  pDiv,
  pCarbon,
  cMktCap,
  s3MktCap,
  percentESG,
  perfESG,
  performanceESG,
  carbonAlphaESG,
  priceMo,
  priceMoST,
  priceMoLT,
  priceMoSector,
  priceMoSectorST,
  priceMoSectorLT,
  runDateStart,
  c1Metric,
  c2Metric,
  c3Metric,
  c4Metric,
  changeQuery,
  onClick,
  isLoading,
  hideMiniChartsForm,
  onlyIncludeTickers,
  includeTickers,
  excludeTickers,
  excludeSectors,
  selectedMetrics,
  combinedMetrics,
}) => {
  const [isMetricsOpen, setIsMetricsOpen] = useState(false);
  const toggleMetrics = () => setIsMetricsOpen(!isMetricsOpen);
  const [isBookmarksOpen, setIsBookmarksOpen] = useState(false);
  const toggleBookmarks = () => setIsBookmarksOpen(!isBookmarksOpen);
  const [tab, setTab] = useState('4');
  const { token } = useContext(AccountContext);
  const [metrics, setMetrics] = useState([]);
  const fetchMetrics = useCallback(async () => {
    const res = await fetchConstituentMetrics(
      { universe: universe.split('_')[0] },
      token
    );
    setMetrics(res);
  }, [token, universe]);

  useEffect(() => {
    fetchMetrics();
  }, [fetchMetrics]);

  selectedMetrics = selectedMetrics.split(',').filter((m) => !!m);
  const updateSelectedMetrics = (include, type) => {
    const metrics = selectedMetrics;
    if (include) {
      metrics.push(type);
    } else {
      const index = metrics.indexOf(type);
      if (index > -1) {
        metrics.splice(index, 1);
      }
    }
    changeQuery({ selectedMetrics: metrics.join(',') });
  };

  const formDivClassName = 'form-group form-group--column mb-3';
  const formCheckClassName = 'form-check mb-1';
  const formDivClassNameFirst = formDivClassName + ' mt-2';
  return (
    <ToolbarColumn isLoading={isLoading}>
      <div className={formDivClassName}>
        <h4>Starting Universe</h4>
      </div>
      <div className={formDivClassName}>
        <Button onClick={onClick} color="success" className="rounded">
          Generate
        </Button>
      </div>
      <div className={formDivClassName}>
        <label>Universe</label>
        <LabsUniversePicker
          selected={universe}
          onChange={(e) => changeQuery({ universe: e.target.value })}
        />
      </div>
      {useEtf === 'true' && (
        <div className={formDivClassName}>
          <label>Asset</label>
          <AssetPickerV2
            target="etf-constituents"
            type="ETF"
            universe={universe.split('_')[0]}
            selected={asset}
            onSelect={(asset) => changeQuery({ asset: asset.id })}
          />
        </div>
      )}
      <div className={formCheckClassName}>
        <Input
          type="checkbox"
          name="tickers"
          className="mt-2"
          checked={useEtf === 'true'}
          onChange={(e) => changeQuery({ useEtf: e.target.checked })}
        />
        <label className="form-check-label">Use ETF as Starting Universe</label>
      </div>
      <div className={formDivClassName}>
        <label>Sector</label>
        <ConstituentClassificationsPicker
          universe={universe}
          keyName={'sector'}
          classification={sectorCore}
          classifications={sectors}
          defaultClassifications={sectors}
          onChange={(e) => changeQuery({ sectorCore: e.target.value })}
        />
      </div>
      <div className={formDivClassName}>
        <label>Start Date</label>
        <DatePickerAssetV2
          type="etf-constituents"
          asset={''}
          universe={universe}
          date={runDateStart}
          assetType="esg"
          onChange={(e) => changeQuery({ runDateStart: e.target.value })}
        />
      </div>
      {!hideMiniChartsForm && (
        <div className="form-group form-group--column">
          <Button
            className="rounded col-md-8 d-flex justify-content-around"
            onClick={toggleMetrics}
            color="info"
            style={{ fontSize: '.80rem' }}
          >
            <div className="col-md-8">Set Chart Metrics</div>
            <div className="col-md-4">
              <Icon name={isMetricsOpen ? 'chevron-down' : 'chevron-right'} />
            </div>
          </Button>
        </div>
      )}
      <Collapse isOpen={isMetricsOpen}>
        <div className={formDivClassName}>
          <label>Metric Chart 1</label>
          <CoreMetricPicker
            metric={c1Metric}
            metrics={metrics}
            onChange={(e) => changeQuery({ c1Metric: e.target.value })}
          />
        </div>
        <div className={formDivClassName}>
          <label>Metric Chart 2</label>
          <CoreMetricPicker
            metric={c2Metric}
            metrics={metrics}
            onChange={(e) => changeQuery({ c2Metric: e.target.value })}
          />
        </div>
        <div className={formDivClassName}>
          <label>Metric Chart 3</label>
          <CoreMetricPicker
            metric={c3Metric}
            metrics={metrics}
            onChange={(e) => changeQuery({ c3Metric: e.target.value })}
          />
        </div>
        <div className={formDivClassName}>
          <label>Metric Chart 4</label>
          <CoreMetricPicker
            metric={c4Metric}
            metrics={metrics}
            onChange={(e) => changeQuery({ c4Metric: e.target.value })}
          />
        </div>
      </Collapse>

      <div className="form-group form-group--column">
        <hr className="col-md-8" />
        <Button
          className="rounded col-md-8 d-flex justify-content-around"
          onClick={toggleBookmarks}
          color="info"
          style={{ fontSize: '.80rem' }}
        >
          <div className="col-md-8">Bookmarks</div>
          <div className="col-md-4">
            <Icon name={isBookmarksOpen ? 'chevron-down' : 'chevron-right'} />
          </div>
        </Button>
      </div>

      <Bookmarks
        isOpen={isBookmarksOpen}
        changeQuery={changeQuery}
        generate={onClick}
        settings={{
          universe,
          useEtf,
          asset,
          sectorCore,
          pEnv,
          pSoc,
          pGov,
          pDiv,
          pCarbon,
          cMktCap,
          s3MktCap,
          percentESG,
          perfESG,
          performanceESG,
          carbonAlphaESG,
          priceMo,
          priceMoST,
          priceMoLT,
          priceMoSector,
          priceMoSectorST,
          priceMoSectorLT,
          runDateStart,
          onlyIncludeTickers,
          includeTickers,
          excludeTickers,
          excludeSectors,
          selectedMetrics,
          combinedMetrics,
        }}
        token={token}
      />

      <div className={formDivClassName}>
        <hr className="col-md-8" />
        <h4>Percentile Filters</h4>
      </div>
      <Nav tabs style={{ fontSize: '.75rem' }}>
        <NavItem>
          <NavLink
            className={classnames({ active: tab === '1' })}
            onClick={() => {
              setTab('1');
            }}
          >
            ESG
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: tab === '2' })}
            onClick={() => {
              setTab('2');
            }}
          >
            Carbon
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: tab === '3' })}
            onClick={() => {
              setTab('3');
            }}
          >
            Price MO
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: tab === '4' })}
            onClick={() => {
              setTab('4');
            }}
          >
            Avg Metrics
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={tab}>
        <TabPane tabId="1">
          <div className={formDivClassNameFirst}>
            <label>E Percentile</label>
            <PercentileSlider
              min={min(pEnv)}
              max={max(pEnv)}
              onChange={(v) => {
                const [vMin, vMax] = v;
                changeQuery({ pEnv: `${vMin},${vMax}` });
              }}
            />
          </div>
          <div className={formDivClassName}>
            <label>S Percentile</label>
            <PercentileSlider
              min={min(pSoc)}
              max={max(pSoc)}
              onChange={(v) => {
                const [vMin, vMax] = v;
                changeQuery({ pSoc: `${vMin},${vMax}` });
              }}
            />
          </div>
          <div className={formDivClassName}>
            <label>G Percentile</label>
            <PercentileSlider
              min={min(pGov)}
              max={max(pGov)}
              onChange={(v) => {
                const [vMin, vMax] = v;
                changeQuery({ pGov: `${vMin},${vMax}` });
              }}
            />
          </div>
          <div className={formDivClassName}>
            <label>Diversity Percentile</label>
            <PercentileSlider
              min={min(pDiv)}
              max={max(pDiv)}
              onChange={(v) => {
                const [vMin, vMax] = v;
                changeQuery({ pDiv: `${vMin},${vMax}` });
              }}
            />
          </div>
          <div className={formDivClassName}>
            <label>ESG Percentile </label>
            <PercentileSlider
              min={min(percentESG)}
              max={max(percentESG)}
              onChange={(v) => {
                const [vMin, vMax] = v;
                changeQuery({ percentESG: `${vMin},${vMax}` });
              }}
            />
          </div>
          <div className={formDivClassName}>
            <label>ESG Signal 1 Filter</label>
            <PercentileSlider
              min={min(perfESG)}
              max={max(perfESG)}
              onChange={(v) => {
                const [vMin, vMax] = v;
                changeQuery({ perfESG: `${vMin},${vMax}` });
              }}
            />
          </div>
          <div className={formDivClassName}>
            <label>ESG Signal 2 Filter</label>
            <PercentileSlider
              min={min(performanceESG)}
              max={max(performanceESG)}
              onChange={(v) => {
                const [vMin, vMax] = v;
                changeQuery({ performanceESG: `${vMin},${vMax}` });
              }}
            />
          </div>
          <div className={formDivClassName}>
            <label>Carbon Signal 3 Filter</label>
            <PercentileSlider
              min={min(carbonAlphaESG)}
              max={max(carbonAlphaESG)}
              onChange={(v) => {
                const [vMin, vMax] = v;
                changeQuery({ carbonAlphaESG: `${vMin},${vMax}` });
              }}
            />
          </div>
        </TabPane>
        <TabPane tabId="2">
          <div className={formDivClassNameFirst}>
            <label>Percent Carbon</label>
            <PercentileSlider
              min={min(pCarbon)}
              max={max(pCarbon)}
              onChange={(v) => {
                const [vMin, vMax] = v;
                changeQuery({ pCarbon: `${vMin},${vMax}` });
              }}
            />
          </div>
          <div className={formDivClassName}>
            <label>Carbon Market Cap Percentile</label>
            <PercentileSlider
              min={min(cMktCap)}
              max={max(cMktCap)}
              onChange={(v) => {
                const [vMin, vMax] = v;
                changeQuery({ cMktCap: `${vMin},${vMax}` });
              }}
            />
          </div>
          <div className={formDivClassName}>
            <label>% Scope 3 Market Cap</label>
            <PercentileSlider
              min={min(s3MktCap)}
              max={max(s3MktCap)}
              onChange={(v) => {
                const [vMin, vMax] = v;
                changeQuery({ s3MktCap: `${vMin},${vMax}` });
              }}
            />
          </div>
        </TabPane>
        <TabPane tabId="3">
          <div className={formDivClassNameFirst}>
            <label>Price Momentum</label>
            <PercentileSlider
              min={min(priceMo)}
              max={max(priceMo)}
              onChange={(v) => {
                const [vMin, vMax] = v;
                changeQuery({ priceMo: `${vMin},${vMax}` });
              }}
            />
          </div>
          <div className={formDivClassName}>
            <label>Price Momentum ST</label>
            <PercentileSlider
              min={min(priceMoST)}
              max={max(priceMoST)}
              onChange={(v) => {
                const [vMin, vMax] = v;
                changeQuery({ priceMoST: `${vMin},${vMax}` });
              }}
            />
          </div>
          <div className={formDivClassName}>
            <label>Price Momentum LT</label>
            <PercentileSlider
              min={min(priceMoLT)}
              max={max(priceMoLT)}
              onChange={(v) => {
                const [vMin, vMax] = v;
                changeQuery({ priceMoLT: `${vMin},${vMax}` });
              }}
            />
          </div>
          <div className={formDivClassName}>
            <label>Sector Price Momentum</label>
            <PercentileSlider
              min={min(priceMoSector)}
              max={max(priceMoSector)}
              onChange={(v) => {
                const [vMin, vMax] = v;
                changeQuery({ priceMoSector: `${vMin},${vMax}` });
              }}
            />
          </div>
          <div className={formDivClassName}>
            <label>Sector Price Momentum ST</label>
            <PercentileSlider
              min={min(priceMoSectorST)}
              max={max(priceMoSectorST)}
              onChange={(v) => {
                const [vMin, vMax] = v;
                changeQuery({ priceMoSectorST: `${vMin},${vMax}` });
              }}
            />
          </div>
          <div className={formDivClassName}>
            <label>Sector Price Momentum LT</label>
            <PercentileSlider
              min={min(priceMoSectorLT)}
              max={max(priceMoSectorLT)}
              onChange={(v) => {
                const [vMin, vMax] = v;
                changeQuery({ priceMoSectorLT: `${vMin},${vMax}` });
              }}
            />
          </div>
        </TabPane>
        <TabPane tabId="4">
          <div className={formDivClassName + ' mt-3 mb-3'}>
            <label>Average Metrics</label>
            <PercentileSlider
              min={min(combinedMetrics)}
              max={max(combinedMetrics)}
              onChange={(v) => {
                const [vMin, vMax] = v;
                changeQuery({ combinedMetrics: `${vMin},${vMax}` });
              }}
            />
          </div>
          <Row>
            <Col xs={6}>
              <div className={formCheckClassName}>
                <Input
                  type="checkbox"
                  name="tickers"
                  className="mt-2"
                  checked={selectedMetrics.includes('pEnv')}
                  onChange={(e) =>
                    updateSelectedMetrics(e.target.checked, 'pEnv')
                  }
                />
                <label className="form-check-label">E Percentile</label>
              </div>
              <div className={formCheckClassName}>
                <Input
                  type="checkbox"
                  name="tickers"
                  className="mt-2"
                  checked={selectedMetrics.includes('pSoc')}
                  onChange={(e) =>
                    updateSelectedMetrics(e.target.checked, 'pSoc')
                  }
                />
                <label className="form-check-label">S Percentile</label>
              </div>
              <div className={formCheckClassName}>
                <Input
                  type="checkbox"
                  name="tickers"
                  className="mt-2"
                  checked={selectedMetrics.includes('pGov')}
                  onChange={(e) =>
                    updateSelectedMetrics(e.target.checked, 'pGov')
                  }
                />
                <label className="form-check-label">G Percentile</label>
              </div>
              <div className={formCheckClassName}>
                <Input
                  type="checkbox"
                  name="tickers"
                  className="mt-2"
                  checked={selectedMetrics.includes('pDiv')}
                  onChange={(e) =>
                    updateSelectedMetrics(e.target.checked, 'pDiv')
                  }
                />
                <label className="form-check-label">Diversity Percentile</label>
              </div>
              <div className={formCheckClassName}>
                <Input
                  type="checkbox"
                  name="tickers"
                  className="mt-2"
                  checked={selectedMetrics.includes('percentESG')}
                  onChange={(e) =>
                    updateSelectedMetrics(e.target.checked, 'percentESG')
                  }
                />
                <label className="form-check-label">ESG Percentile</label>
              </div>
              <div className={formCheckClassName}>
                <Input
                  type="checkbox"
                  name="tickers"
                  className="mt-2"
                  checked={selectedMetrics.includes('perfESG')}
                  onChange={(e) =>
                    updateSelectedMetrics(e.target.checked, 'perfESG')
                  }
                />
                <label className="form-check-label">ESG Signal 1</label>
              </div>
              <div className={formCheckClassName}>
                <Input
                  type="checkbox"
                  name="tickers"
                  className="mt-2"
                  checked={selectedMetrics.includes('performanceESG')}
                  onChange={(e) =>
                    updateSelectedMetrics(e.target.checked, 'performanceESG')
                  }
                />
                <label className="form-check-label">ESG Signal 2 </label>
              </div>
              <div className={formCheckClassName}>
                <Input
                  type="checkbox"
                  name="tickers"
                  className="mt-2"
                  checked={selectedMetrics.includes('carbonAlphaESG')}
                  onChange={(e) =>
                    updateSelectedMetrics(e.target.checked, 'carbonAlphaESG')
                  }
                />
                <label className="form-check-label">Carbon Signal 3</label>
              </div>
            </Col>
            <Col xs={6}>
              <div className={formCheckClassName}>
                <Input
                  type="checkbox"
                  name="tickers"
                  className="mt-2"
                  checked={selectedMetrics.includes('pCarbon')}
                  onChange={(e) =>
                    updateSelectedMetrics(e.target.checked, 'pCarbon')
                  }
                />
                <label className="form-check-label">Percent Carbon</label>
              </div>
              <div className={formCheckClassName}>
                <Input
                  type="checkbox"
                  name="tickers"
                  className="mt-2"
                  checked={selectedMetrics.includes('cMktCap')}
                  onChange={(e) =>
                    updateSelectedMetrics(e.target.checked, 'cMktCap')
                  }
                />
                <label className="form-check-label">
                  Carbon Market Cap Percentile
                </label>
              </div>
              <div className={formCheckClassName}>
                <Input
                  type="checkbox"
                  name="tickers"
                  className="mt-2"
                  checked={selectedMetrics.includes('s3MktCap')}
                  onChange={(e) =>
                    updateSelectedMetrics(e.target.checked, 's3MktCap')
                  }
                />
                <label className="form-check-label">% Scope 3 Market Cap</label>
              </div>
              <div className={formCheckClassName}>
                <Input
                  type="checkbox"
                  name="tickers"
                  className="mt-2"
                  checked={selectedMetrics.includes('priceMo')}
                  onChange={(e) =>
                    updateSelectedMetrics(e.target.checked, 'priceMo')
                  }
                />
                <label className="form-check-label">Price Momentum</label>
              </div>
              <div className={formCheckClassName}>
                <Input
                  type="checkbox"
                  name="tickers"
                  className="mt-2"
                  checked={selectedMetrics.includes('priceMoST')}
                  onChange={(e) =>
                    updateSelectedMetrics(e.target.checked, 'priceMoST')
                  }
                />
                <label className="form-check-label">Price Momentum ST</label>
              </div>
              <div className={formCheckClassName}>
                <Input
                  type="checkbox"
                  name="tickers"
                  className="mt-2"
                  checked={selectedMetrics.includes('priceMoLT')}
                  onChange={(e) =>
                    updateSelectedMetrics(e.target.checked, 'priceMoLT')
                  }
                />
                <label className="form-check-label">Price Momentum LT</label>
              </div>
              <div className={formCheckClassName}>
                <Input
                  type="checkbox"
                  name="tickers"
                  className="mt-2"
                  checked={selectedMetrics.includes('priceMoSector')}
                  onChange={(e) =>
                    updateSelectedMetrics(e.target.checked, 'priceMoSector')
                  }
                />
                <label className="form-check-label">
                  Sector Price Momentum
                </label>
              </div>
              <div className={formCheckClassName}>
                <Input
                  type="checkbox"
                  name="tickers"
                  className="mt-2"
                  checked={selectedMetrics.includes('priceMoSectorST')}
                  onChange={(e) =>
                    updateSelectedMetrics(e.target.checked, 'priceMoSectorST')
                  }
                />
                <label className="form-check-label">
                  Sector Price Momentum ST
                </label>
              </div>
              <div className={formCheckClassName}>
                <Input
                  type="checkbox"
                  name="tickers"
                  className="mt-2"
                  checked={selectedMetrics.includes('priceMoSectorLT')}
                  onChange={(e) =>
                    updateSelectedMetrics(e.target.checked, 'priceMoSectorLT')
                  }
                />
                <label className="form-check-label">
                  Sector Price Momentum LT
                </label>
              </div>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </ToolbarColumn>
  );
};

Form.propTypes = {
  universe: PropTypes.string.isRequired,
  useEtf: PropTypes.string.isRequired,
  asset: PropTypes.string.isRequired,
  sectorCore: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  changeQuery: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pEnv: PropTypes.string.isRequired,
  pSoc: PropTypes.string.isRequired,
  pGov: PropTypes.string.isRequired,
  pDiv: PropTypes.string.isRequired,
  pCarbon: PropTypes.string.isRequired,
  cMktCap: PropTypes.string.isRequired,
  s3MktCap: PropTypes.string.isRequired,
  percentESG: PropTypes.string.isRequired,
  performanceESG: PropTypes.string.isRequired,
  perfESG: PropTypes.string.isRequired,
  carbonAlphaESG: PropTypes.string.isRequired,
  priceMo: PropTypes.string.isRequired,
  priceMoST: PropTypes.string.isRequired,
  priceMoLT: PropTypes.string.isRequired,
  priceMoSector: PropTypes.string.isRequired,
  priceMoSectorST: PropTypes.string.isRequired,
  priceMoSectorLT: PropTypes.string.isRequired,
  c1Metric: PropTypes.string.isRequired,
  c2Metric: PropTypes.string.isRequired,
  c3Metric: PropTypes.string.isRequired,
  c4Metric: PropTypes.string.isRequired,
  runDateStart: PropTypes.string.isRequired,
  onlyIncludeTickers: PropTypes.string.isRequired,
  includeTickers: PropTypes.string.isRequired,
  excludeTickers: PropTypes.string.isRequired,
  excludeSectors: PropTypes.string.isRequired,
  hideMiniChartsForm: PropTypes.bool.isRequired,
  selectedMetrics: PropTypes.string.isRequired,
  combinedMetrics: PropTypes.string.isRequired,
};
