import React from 'react';
import PropTypes from 'prop-types';

const ColorConstituentByOptions = [
  {
    title: 'Universe',
    value: 'color_universe',
  },
  {
    title: 'Asset',
    value: 'color_asset',
  },
  {
    title: 'Sector',
    value: 'color_sector',
  },
];

export const ConstituentColorByPicker = (props) => {
  let { metric, onChange } = props;

  return (
    <select
      className="form-control form-control-sm"
      value={metric}
      onChange={onChange}
    >
      {ColorConstituentByOptions.map(({ title, value }, i) => (
        <option key={i} value={value}>
          {title}
        </option>
      ))}
    </select>
  );
};

ConstituentColorByPicker.propTypes = {
  metric: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
