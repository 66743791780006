import React from 'react';
import PropTypes from 'prop-types';
import { UniversePicker } from '../../components/universe-picker';
import { DatePickerAsset } from '../../components/date-picker-asset';

export const Form = ({ universe, dataSet, date, changeQuery }) => {
  return (
    <form className="chart-form form-inline mb-2">
      <div className="form-group mr-2">
        <label>Universe</label>
        <UniversePicker
          selected={universe}
          onChange={e => changeQuery({ universe: e.target.value })}
        />
      </div>
      <div className="form-group mr-2">
        <label>Data Set</label>
        <select
          className="form-control form-control-sm"
          value={dataSet}
          onChange={e => changeQuery({ dataSet: e.target.value })}
        >
          <option value="esg">ESG Data</option>
          <option value="etf">ETF Ratings</option>
        </select>
      </div>
      <div className="form-group mr-2">
        <label>Date</label>
        <DatePickerAsset
          type="stock"
          universe={universe}
          date={date}
          assetType={dataSet}
          onChange={e => changeQuery({ date: e.target.value })}
        />
      </div>
    </form>
  );
};

Form.propTypes = {
  universe: PropTypes.string.isRequired,
  dataSet: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  changeQuery: PropTypes.func.isRequired,
};
