import React from 'react';
import { Form, Input, Label, FormGroup, Button, Alert } from 'reactstrap';
import { Loading } from '../../../components/loading';

const getSetting = (settings, key) => {
  return settings.find(s => s.key === key);
};

export const Administration = ({
  isLoading,
  error,
  message,
  settings,
  onSubmit,
  onUpdateSetting,
}) => {
  if (!settings.length || isLoading)
    return (
      <div className="container mt-5">
        <Loading />
      </div>
    );

  const defaultSchema = getSetting(settings, 'default_schema');
  const administratorSchema = getSetting(settings, 'administrator_schema');
  const lastProductionMonth = getSetting(settings, 'last_production_month');
  const administratorProductionMonth = getSetting(
    settings,
    'administrator_production_month'
  );

  return (
    <div className="container mt-5">
      {error && <Alert color="danger">{error}</Alert>}
      {message && <Alert color="primary">{message}</Alert>}
      <Form onSubmit={onSubmit}>
        <div className="row">
          <h2>General settings</h2>
        </div>
        {defaultSchema && (
          <div className="row">
            <FormGroup>
              <Label>
                Default Schema
                <Input
                  type="select"
                  onChange={onUpdateSetting}
                  value={defaultSchema.value}
                  name="default_schema"
                >
                  {defaultSchema.options.map(o => {
                    return (
                      <option value={o} key={o}>
                        {o}
                      </option>
                    );
                  })}
                </Input>
              </Label>
            </FormGroup>
          </div>
        )}
        {lastProductionMonth && (
          <div className="row">
            <FormGroup>
              <Label>
                Last Production Month
                <Input
                  type="select"
                  onChange={onUpdateSetting}
                  value={lastProductionMonth.value}
                  name="last_production_month"
                >
                  {lastProductionMonth.options.map(o => {
                    return (
                      <option key={o} value={o}>
                        {o}
                      </option>
                    );
                  })}
                </Input>
              </Label>
            </FormGroup>
          </div>
        )}

        <div className="row mt-5">
          <h2>Administrator specific settings</h2>
        </div>

        {/* Administrator settings */}
        {administratorSchema && (
          <div className="row">
            <FormGroup>
              <Label>
                Administrator Schema
                <Input
                  type="select"
                  onChange={onUpdateSetting}
                  value={administratorSchema.value}
                  name="administrator_schema"
                >
                  {administratorSchema.options.map(o => {
                    return (
                      <option value={o} key={o}>
                        {o}
                      </option>
                    );
                  })}
                </Input>
              </Label>
            </FormGroup>
          </div>
        )}
        {administratorProductionMonth && (
          <div className="row">
            <FormGroup>
              <Label>
                Administrator Production Month
                <Input
                  type="select"
                  onChange={onUpdateSetting}
                  value={administratorProductionMonth.value}
                  name="administrator_production_month"
                >
                  {administratorProductionMonth.options.map(o => {
                    return (
                      <option key={o} value={o}>
                        {o}
                      </option>
                    );
                  })}
                </Input>
              </Label>
            </FormGroup>
          </div>
        )}
        <Button type="submit">Submit</Button>
      </Form>
    </div>
  );
};
